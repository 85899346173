import { Col, Form, Row } from 'react-bootstrap'
import { BsArrowLeft, BsPen, BsTrash } from 'react-icons/bs'
import { Link, useParams } from 'react-router-dom'
import { DetailField, Dl } from '../../../components/detail/detail-list'
import { CenteredContentLayout } from '../../../components/layouts/centered-content-layout'
import { translate as t } from '../../../services/translation'
import React from 'react'
import { useUserQuery } from '../../../queries/user-queries'
import { booleanFmt } from '../../../util/formatters'

export const UserDetail = () => {
  const { username } = useParams()
  // Queries
  const { data } = useUserQuery(username)

  if (username && !data) {
    return null
  }

  return (
    <CenteredContentLayout>
      <h1>{t('show', t('user'))}</h1>
      <Dl translationPath="user">
        <DetailField name="username">{data?.username}</DetailField>
        <DetailField name="email">{data?.email}</DetailField>
        <DetailField name="staff">{booleanFmt(data?.staff)}</DetailField>
        <DetailField name="admin">{booleanFmt(data?.admin)}</DetailField>
        <DetailField name="enabled">{booleanFmt(data?.enabled)}</DetailField>
        <DetailField name="multiFactor">{booleanFmt(data?.multiFactor)}</DetailField>
      </Dl>
      <Form.Group as={Row} className="mb-3">
        <Col>
          <Link className="btn btn-primary me-2 icon-wrapper" to={`../${username}/edit`}>
            <BsPen />
            <span>{t('button.edit')}</span>
          </Link>
          <Link className="btn btn-danger me-2 icon-wrapper" to={`../${username}/delete`}>
            <BsTrash />
            <span>{t('button.delete')}</span>
          </Link>
          <Link className="btn btn-secondary icon-wrapper" to="..">
            <BsArrowLeft />
            <span>{t('button.back')}</span>
          </Link>
        </Col>
      </Form.Group>
    </CenteredContentLayout>
  )
}
