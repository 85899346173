import { translate as t } from '../../services/translation'
import { FormField } from '../form'
import { Periodicity } from '../../generated'

export const OVLContractForm = (_) => {
  return (
    <>
      <FormField name="policyHolder" inputProps={{ maxLength: '100' }} />
      <FormField name="insured" inputProps={{ maxLength: '100' }} />
      <FormField name="beneficiaryDeath" inputProps={{ maxLength: '100' }} />
      <FormField
        name="deathInsuredCapital"
        inputProps={{ type: 'number', min: 0, step: 0.01 }}
        registerOptions={{ min: 0, valueAsNumber: true }}
      />
      <FormField
        name="annualPremium"
        inputProps={{ type: 'number', min: 0, step: 0.01 }}
        registerOptions={{ min: 0, valueAsNumber: true }}
      />
      <FormField
        name="periodicity"
        inputProps={{
          type: 'select',
          options: ['', ...Object.values(Periodicity)].map((p) => ({ label: t(p), value: p })),
        }}
      />
      <FormField
        name="premiumPaymentEndDate"
        inputProps={{ type: 'date' }}
        registerOptions={{ valueAsDate: true }}
      />
      <FormField
        name="mainDueDate"
        inputProps={{ maxLength: '100' }}
        registerOptions={{
          pattern: { value: /[0-3][0-9]\/[0-1][0-9]/, message: t('asset.mainDueDate.error') },
        }}
      />
      <FormField name="remarks" inputProps={{ as: 'textarea', rows: 5, maxLength: 512 }} />
    </>
  )
}
