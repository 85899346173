import { translate as t } from '../../services/translation'
import { FormField } from '../form'
import { Card } from 'react-bootstrap'
import { Periodicity } from '../../generated'

export const LifeFiscalContractForm = (_) => {
  return (
    <>
      <FormField name="policyHolder" inputProps={{ maxLength: '100' }} />
      <FormField name="insured" inputProps={{ maxLength: '100' }} />
      <FormField name="beneficiaryLife" inputProps={{ maxLength: '100' }} />
      <FormField name="beneficiaryDeath" inputProps={{ maxLength: '100' }} />
      <FormField
        name="contractState"
        inputProps={{
          type: 'select',
          options: ['', 'actief', 'premievrij'],
        }}
      />
      <Card>
        <Card.Body>
          <Card.Title>{t('asset.pensionStructure')}</Card.Title>
          <FormField
            name="pensionAnnualPremium"
            inputProps={{ type: 'number', min: 0, step: 0.01 }}
            registerOptions={{ min: 0, valueAsNumber: true }}
          />
          <FormField
            name="pensionPeriodicity"
            inputProps={{
              type: 'select',
              options: ['', ...Object.values(Periodicity)].map((p) => ({ label: t(p), value: p })),
            }}
          />
          <FormField
            name="pensionEntryCost"
            inputProps={{ type: 'number', min: 0, step: 0.01 }}
            registerOptions={{ min: 0, valueAsNumber: true }}
          />
          <FormField name="pensionIndexation" inputProps={{ maxLength: 100 }} />
        </Card.Body>
      </Card>
      <Card>
        <Card.Body>
          <Card.Title>{t('asset.deathInsuranceCoverage')}</Card.Title>
          <FormField
            name="deathInsuredCapital"
            inputProps={{ type: 'number', min: 0, step: 0.01 }}
            registerOptions={{ min: 0, valueAsNumber: true }}
          />
          <FormField
            name="deathAnnualPremium"
            inputProps={{ type: 'number', min: 0, step: 0.01 }}
            registerOptions={{ min: 0, valueAsNumber: true }}
          />
          <FormField
            name="deathPeriodicity"
            inputProps={{
              type: 'select',
              options: ['', ...Object.values(Periodicity)].map((p) => ({ label: t(p), value: p })),
            }}
          />
        </Card.Body>
      </Card>
      <Card>
        <Card.Body>
          <Card.Title>{t('asset.disability')}</Card.Title>
          <FormField
            name="invalidityMonthlyInterest"
            inputProps={{ type: 'number', min: 0, step: 0.01 }}
            registerOptions={{ min: 0, valueAsNumber: true }}
          />
          <FormField
            name="invalidityAnnualPremium"
            inputProps={{ type: 'number', min: 0, step: 0.01 }}
            registerOptions={{ min: 0, valueAsNumber: true }}
          />
          <FormField
            name="invalidityPeriodicity"
            inputProps={{
              type: 'select',
              options: ['', ...Object.values(Periodicity)].map((p) => ({ label: t(p), value: p })),
            }}
          />
          <FormField
            name="invalidityWait"
            inputProps={{ type: 'number', min: 0, step: 0.01 }}
            registerOptions={{ min: 0, valueAsNumber: true }}
          />
          <FormField
            name="invalidityCoverageType"
            inputProps={{
              type: 'select',
              options: ['', 'economisch', 'fysiologisch', 'econ + fys'],
            }}
          />
          <FormField name="invalidityIndexation" inputProps={{ maxLength: '100' }} />
        </Card.Body>
      </Card>
      <Card>
        <Card.Body>
          <Card.Title>{t('asset.premiumWaiver')}</Card.Title>
          <FormField
            name="premiumWaiverAnnualPremium"
            inputProps={{ type: 'number', min: 0, step: 0.01 }}
            registerOptions={{ min: 0, valueAsNumber: true }}
          />
          <FormField
            name="premiumWaiverPeriodicity"
            inputProps={{
              type: 'select',
              options: ['', ...Object.values(Periodicity)].map((p) => ({ label: t(p), value: p })),
            }}
          />
        </Card.Body>
      </Card>
      <FormField
        name="mainDueDate"
        inputProps={{ maxLength: '100' }}
        registerOptions={{
          pattern: { value: /[0-3][0-9]\/[0-1][0-9]/, message: t('asset.mainDueDate.error') },
        }}
      />
      <FormField
        name="premiumPaymentEndDate"
        inputProps={{ type: 'date' }}
        registerOptions={{ valueAsDate: true }}
      />
      <FormField name="insuranceType" inputProps={{ maxLength: 100 }} />
      <FormField
        name="advanceTaken"
        inputProps={{ type: 'number', min: 0, step: 0.01 }}
        registerOptions={{ min: 0, valueAsNumber: true }}
      />

      <FormField name="remarks" inputProps={{ as: 'textarea', rows: 5, maxLength: 512 }} />
    </>
  )
}
