import { Button, Col, Form, Row } from 'react-bootstrap'
import { BsSave, BsSlashCircle } from 'react-icons/bs'
import { useQueryClient } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'
import { FormContainer, FormField } from '../../../components/form'
import { CenteredContentLayout } from '../../../components/layouts/centered-content-layout'
import { AssetManager } from '../../../generated'
import { assetManagerQueryKeys, useAssetManagerQuery } from '../../../queries/asset-manager-queries'
import { api } from '../../../services/api'
import { translate as t } from '../../../services/translation'

const emptyFormData = {
  version: 0, // hidden form field used for optimistic locking
  name: '',
  url: '',
  archived: false,
}

const mapToAssetManagerApi = (data: typeof emptyFormData): AssetManager => ({
  version: data.version || undefined, // hidden form field used for optimistic locking
  name: data.name,
  url: data.url || undefined,
  archived: data.archived || undefined,
})

const AssetManagerEditForm = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const queryClient = useQueryClient()

  const onCancel = () => navigate(-1)
  const onSubmit = async (data) => {
    if (id) {
      await api.assetManagersUpdate(id, mapToAssetManagerApi(data))
    } else {
      await api.assetManagersSave(mapToAssetManagerApi(data))
    }
    await queryClient.invalidateQueries(assetManagerQueryKeys.list)
    navigate(-1)
  }

  // Queries
  const { data } = useAssetManagerQuery(id)

  if (id && !data) {
    return null
  }

  return (
    <FormContainer
      defaultValues={emptyFormData}
      values={data ?? emptyFormData}
      onSubmit={onSubmit}
      translationPath="assetManager"
    >
      <FormField name="name" registerOptions={{ required: true }} />
      <FormField name="url" />
      <FormField name="archived" inputProps={{ type: 'checkbox' }} />

      <Form.Group as={Row} className="mb-3">
        <Col md={{ span: 9, offset: 3 }}>
          <Button type="submit" className="me-2 icon-wrapper">
            <BsSave />
            <span>{t('button.save')}</span>
          </Button>
          <Button variant="secondary" className="icon-wrapper" onClick={onCancel}>
            <BsSlashCircle />
            <span>{t('button.cancel')}</span>
          </Button>
        </Col>
      </Form.Group>
    </FormContainer>
  )
}

export const AssetManagerEdit = () => {
  const { id } = useParams()
  return (
    <CenteredContentLayout>
      <h1>{t(id ? 'edit' : 'create', t('assetManager'))}</h1>
      <AssetManagerEditForm />
    </CenteredContentLayout>
  )
}
