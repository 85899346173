import { RouteObject } from 'react-router-dom'
import { routes as productsRoutes } from './products'
import { routes as assetManagersRoutes } from './asset-managers'
import { routes as institutionsRoutes } from './insitutions'

export const routes: RouteObject[] = [
  {
    path: 'master-data',
    children: [...productsRoutes, ...assetManagersRoutes, ...institutionsRoutes],
  },
]
