import { translate as t } from '../../services/translation'
import AccordionItem from 'react-bootstrap/AccordionItem'
import AccordionHeader from 'react-bootstrap/AccordionHeader'
import AccordionBody from 'react-bootstrap/AccordionBody'
import { DetailField, Dl } from '../detail/detail-list'
import { moneyFmt } from '../../util/formatters'

export const T26ContractDetails = ({ asset }) => {
  return (
    <AccordionItem eventKey="contract">
      <AccordionHeader>
        {t('asset.policy')} {asset.reference}
      </AccordionHeader>
      <AccordionBody>
        <Dl translationPath="asset">
          <DetailField name="policyHolder">{asset.policyHolder}</DetailField>
          {!!asset.entryCost && (
            <DetailField name="entryCost">{moneyFmt(asset.entryCost)}</DetailField>
          )}
          {!!asset.guaranteedReturns && asset.type !== 'T23' && (
            <DetailField name="guaranteedReturns">
              <span className="pre">{asset.guaranteedReturns}</span>
            </DetailField>
          )}
          {!!asset.remarks && (
            <DetailField name="remarks">
              <span className="pre">{asset.remarks}</span>
            </DetailField>
          )}
        </Dl>
      </AccordionBody>
    </AccordionItem>
  )
}
