import { Button, Modal, Table } from 'react-bootstrap'
import React, { useState } from 'react'
import { translate as t } from '../../services/translation'
import { useIsStaff } from '../security/authorisation'
import { dateFmt, percentageFmt, totalFmt } from '../../util/formatters'
import { getGroupSummary } from '../../business-logic/asset'
import { todayIso } from '../../util/date-util'

export const PolicyModal = ({ assetGroup, visible, onClose }) => {
  const groupSummary = getGroupSummary(assetGroup, todayIso())
  return (
    <>
      <Modal show={visible} onHide={onClose} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>
            {t('policy')}
            <span className="text-primary ms-5">
              {assetGroup.institution.name} / {assetGroup.reference}
            </span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table responsive>
            <thead>
              <tr>
                <th className="text-end">{t('year')}</th>
                <th className="text-end">{t('policy.value')}</th>
                <th className="text-end">{t('policy.combinedDeposits')}</th>
                <th className="text-end">{t('policy.deposits')}</th>
                <th className="text-end">{t('policy.yield')}</th>
                <th className="text-end">{t('policy.combinedYield')}</th>
              </tr>
            </thead>
            <tbody>
              {groupSummary.yearSummaries.map((yearSummary, index) => (
                <tr key={index}>
                  <td className="text-end">{yearSummary.year}</td>
                  <td className="text-end">{totalFmt(yearSummary.value)}</td>
                  <td className="text-end">{totalFmt(yearSummary.combinedDeposits)}</td>
                  <td className="text-end">{totalFmt(yearSummary.yearDeposits)}</td>
                  <td className={yearSummary.yearYield < 0 ? 'text-danger text-end' : 'text-end'}>
                    {totalFmt(yearSummary.yearYield)}
                  </td>
                  <td
                    className={yearSummary.combinedYield < 0 ? 'text-danger text-end' : 'text-end'}
                  >
                    {totalFmt(yearSummary.combinedYield)}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          {!!groupSummary.annualInterestRate && (
            <p className="text-end">
              <strong>{t('averageAnnualRate')}</strong> {dateFmt(groupSummary.startDate)} -{' '}
              {dateFmt(groupSummary.endDate)}:{' '}
              <strong className={groupSummary.annualInterestRate < 0 ? 'text-danger' : ''}>
                {percentageFmt(groupSummary.annualInterestRate)}
              </strong>
            </p>
          )}
        </Modal.Body>
      </Modal>
    </>
  )
}

export const PolicyLink = ({ assetGroup }) => {
  const [visible, setVisible] = useState(false)
  const isStaff = useIsStaff()
  return (
    <>
      {isStaff ? (
        <Button variant="link" onClick={() => setVisible(true)} className="icon-link py-0">
          {assetGroup.reference}
        </Button>
      ) : (
        assetGroup.reference
      )}
      {visible && (
        <PolicyModal assetGroup={assetGroup} visible={visible} onClose={() => setVisible(false)} />
      )}
    </>
  )
}
