import { redirect, RouteObject } from 'react-router-dom'
import { DeleteDialog } from '../../../components/form/delete-dialog'
import { assetManagerQueryKeys } from '../../../queries/asset-manager-queries'
import { queryClient } from '../../../queries/react-query-client'
import { api } from '../../../services/api'
import { AssetManagerDetail } from './asset-manager-detail'
import { AssetManagerEdit } from './asset-manager-edit'
import { AssetManagers } from './asset-managers'

export const routes: RouteObject[] = [
  {
    path: 'asset-managers',
    children: [
      { index: true, element: <AssetManagers /> },
      { path: 'new', element: <AssetManagerEdit /> },
      {
        path: ':id',
        children: [
          { index: true, element: <AssetManagerDetail /> },
          { path: 'edit', element: <AssetManagerEdit /> },
          {
            path: 'delete',
            element: (
              <>
                <AssetManagerDetail />
                <DeleteDialog action="archive" entity="assetManager" initialShow={true} />
              </>
            ),
            action: async ({ params }) => {
              await api.assetManagersDelete(params.id!)
              await queryClient.invalidateQueries(assetManagerQueryKeys.list)
              return redirect('../..')
            },
          },
        ],
      },
    ],
  },
]
