import { booleanFmt, dateFmt } from '../../util/formatters'
import { DetailField, Dl } from '../detail/detail-list'
import { InstitutionLink, ProductLink } from '../links'
import { AssetValues } from './asset-values'

export const AssetData = ({ asset }) => {
  return (
    <Dl translationPath="asset">
      <DetailField name="reference">{asset.reference || '-'}</DetailField>
      <DetailField name="product">
        <ProductLink entity={asset.product} />
      </DetailField>
      <DetailField name="institution">
        <InstitutionLink entity={asset.institution} />
      </DetailField>
      <DetailField name="startDate">{dateFmt(asset.startDate)}</DetailField>
      <DetailField name="endDate">{dateFmt(asset.endDate)}</DetailField>
      <DetailField name="type">{asset.type}</DetailField>
      <DetailField name="managedByFeax">{booleanFmt(asset.managedByFeax)}</DetailField>
      <DetailField name="value">
        <AssetValues asset={asset} />
      </DetailField>
    </Dl>
  )
}
