import { translate as t } from '../../services/translation'
import AccordionItem from 'react-bootstrap/AccordionItem'
import AccordionHeader from 'react-bootstrap/AccordionHeader'
import AccordionBody from 'react-bootstrap/AccordionBody'
import { DetailField, Dl } from '../detail/detail-list'
import { booleanFmt, moneyFmt } from '../../util/formatters'
import { AssetTypeEnum } from '../../generated'

export const LifeNonFiscalContractDetails = ({ asset }) => {
  return (
    <AccordionItem eventKey="contract">
      <AccordionHeader>
        {t('asset.policy')} {asset.reference}
      </AccordionHeader>
      <AccordionBody>
        <Dl translationPath="asset">
          <DetailField name="insured">{asset.insured}</DetailField>
          <DetailField name="beneficiaryLife">{asset.beneficiaryLife}</DetailField>
          {asset.deathInsuranceCoverage130 && (
            <DetailField name="deathInsuranceCoverage130">
              {booleanFmt(asset.deathInsuranceCoverage130)}
            </DetailField>
          )}
          <DetailField name="beneficiaryDeath">{asset.beneficiaryDeath}</DetailField>
          {asset.acceptingBeneficiary && (
            <DetailField name="acceptingBeneficiary">{asset.acceptingBeneficiary}</DetailField>
          )}
          {asset.gift && (
            <>
              <DetailField name="gift">{booleanFmt(asset.gift)}</DetailField>
              <DetailField name="giftConditions">{asset.giftConditions}</DetailField>
            </>
          )}
          {!!asset.entryCost && (
            <DetailField name="entryCost">{moneyFmt(asset.entryCost)}%</DetailField>
          )}
          {!!asset.premiumTax && (
            <>
              <DetailField name="premiumTax">{moneyFmt(asset.premiumTax)}%</DetailField>
              <DetailField name="premiumTaxType">{asset.premiumTaxType}</DetailField>
            </>
          )}
          {!!asset.guaranteedReturns && asset.type !== AssetTypeEnum.T23 && (
            <DetailField name="guaranteedReturns">{asset.guaranteedReturns}</DetailField>
          )}
          {!!asset.remarks && (
            <DetailField name="remarks">
              <span className="pre">{asset.remarks}</span>
            </DetailField>
          )}
        </Dl>
      </AccordionBody>
    </AccordionItem>
  )
}
