import { translate as t } from '../../services/translation'
import {
  CASH,
  DEFENSIVE,
  DYNAMIC,
  getCategoryAnalysis,
  NEUTRAL,
} from '../../business-logic/risk-analysis'
import { percentageFmt, totalFmt } from '../../util/formatters'
import { CategoryTable } from './category-table'
import { PercentageBar } from './percentage-bar'
import { ManualPageBreak } from './manual-page-break'
import { Col, Row, Table } from 'react-bootstrap'

export const ReportSummary = ({ date, wallets }) => {
  const description = wallets.map((w) => w.name).join(' / ')
  const { total, categoriesMap, institutions } = getCategoryAnalysis(wallets, date)

  return (
    <>
      <h2>
        {t('profile')} <small>{description}</small>
      </h2>
      <Table responsive>
        <thead>
          <tr>
            <th>{t('profile')}</th>
            <th className="text-end">{t('amount')}</th>
            <th className="text-end">%</th>
            <th></th>
          </tr>
        </thead>
        <tbody className="table-group-divider">
          {[DYNAMIC, NEUTRAL, DEFENSIVE, CASH].map((category) => (
            <tr key={category}>
              <td className="col-sm-3">{t(category)}</td>
              <td className="col-sm-1 text-end">{totalFmt(categoriesMap[category].total)}</td>
              <td className="col-sm-1 text-end">
                {percentageFmt(categoriesMap[category].percentage)}
              </td>
              <td className="col-sm-7">
                <PercentageBar
                  percentage={categoriesMap[category].percentage}
                  category={category}
                />
              </td>
            </tr>
          ))}
          <tr className="total">
            <th>{t('total')}</th>
            <th className="text-end">{totalFmt(total)}</th>
            <th className="text-end">100,0%</th>
            <th></th>
          </tr>
        </tbody>
      </Table>
      <Row>
        <Col sm={12} md={6}>
          <CategoryTable category="CASH" data={categoriesMap[CASH]} />
        </Col>
        <Col sm={12} md={6}>
          <CategoryTable category="DEFENSIVE" data={categoriesMap[DEFENSIVE]} />
        </Col>
        <ManualPageBreak />
      </Row>
      <Row className="page-break-after">
        <Col sm={12} md={6}>
          <CategoryTable category="NEUTRAL" data={categoriesMap[NEUTRAL]} />
        </Col>
        <Col sm={12} md={6}>
          <CategoryTable category="DYNAMIC" data={categoriesMap[DYNAMIC]} />
        </Col>
      </Row>
      <h2>
        {t('dossier.partitionByInstitutionAndManager')} <small>{description}</small>
      </h2>
      <Table responsive className="page-break-after">
        <thead>
          <tr>
            <th>
              {t('institution')} / {t('assetManager')}
            </th>
            <th className="text-end">Bedrag</th>
            <th className="text-end">%</th>
            <th></th>
          </tr>
        </thead>
        <tbody className="table-group-divider">
          {institutions
            .sort((a, b) => b.total! - a.total!)
            .map(
              (institution) =>
                !!institution['total'] && (
                  <tr key={institution.name}>
                    <td className="col-sm-3">{institution.name}</td>
                    <td className="col-sm-1 text-end">{totalFmt(institution.total)}</td>
                    <td className="col-sm-1 text-end">{percentageFmt(institution.percentage)}</td>
                    <td className="col-sm-7">
                      <PercentageBar percentage={institution.percentage} />
                    </td>
                  </tr>
                )
            )}
          <tr className="total">
            <th>{t('total')}</th>
            <th className="text-end">{totalFmt(total)}</th>
            <th className="text-end">100,0%</th>
            <th></th>
          </tr>
        </tbody>
      </Table>
      <div className="page-break-after">
        {[CASH, DEFENSIVE, NEUTRAL, DYNAMIC].map((category) => (
          <div key={category}>
            <h2 className="mt-2">
              {t('profile')} {t(category)} <small>{description}</small>
            </h2>
            <Table responsive className="group">
              <thead>
                <tr>
                  <th>
                    {t('institution')} / {t('assetManager')}
                  </th>
                  <th className="text-end">Bedrag</th>
                  <th className="text-end">%</th>
                  <th></th>
                </tr>
              </thead>
              <tbody className="table-group-divider">
                {categoriesMap[category]['institutions']
                  .sort((a, b) => b.total! - a.total!)
                  .map(
                    (institution) =>
                      !!institution.total && (
                        <tr key={institution.name}>
                          <td className="col-sm-3">{institution.name}</td>
                          <td className="col-sm-1 text-end">{totalFmt(institution.total)}</td>
                          <td className="col-sm-1 text-end">
                            {percentageFmt(institution.percentage)}
                          </td>
                          <td className="col-sm-7">
                            <PercentageBar
                              percentage={institution.percentage}
                              category={category}
                            />
                          </td>
                        </tr>
                      )
                  )}
                <tr className="total">
                  <th>Totaal</th>
                  <th className="text-end">{totalFmt(categoriesMap[category].total)}</th>
                  <th className="text-end">100,0%</th>
                  <th></th>
                </tr>
              </tbody>
            </Table>
            <ManualPageBreak />
          </div>
        ))}
      </div>
    </>
  )
}
