import { translate as t } from '../../services/translation'
import { Card, Row } from 'react-bootstrap'
import AccordionItem from 'react-bootstrap/AccordionItem'
import AccordionHeader from 'react-bootstrap/AccordionHeader'
import AccordionBody from 'react-bootstrap/AccordionBody'
import { DetailField, Dl } from '../detail/detail-list'
import { moneyFmt } from '../../util/formatters'

export const BCPSContractDetails = ({ asset }) => {
  return (
    <AccordionItem eventKey="contract">
      <AccordionHeader>
        {t('asset.policy')} {asset.reference}
      </AccordionHeader>
      <AccordionBody>
        <Dl translationPath="asset">
          <DetailField name="contractState">{asset.contractState}</DetailField>
          <Card>
            <Card.Body>
              <Card.Title>{t('asset.pensionStructure')}</Card.Title>
              {!!asset.pensionAnnualPremium && (
                <Row>
                  <DetailField name="pensionAnnualPremium">
                    {moneyFmt(asset.pensionAnnualPremium)}
                  </DetailField>
                </Row>
              )}
              {!!asset.pensionPeriodicity && (
                <Row>
                  <DetailField name="pensionPeriodicity">{t(asset.pensionPeriodicity)}</DetailField>
                </Row>
              )}
              {!!asset.pensionEntryCost && (
                <Row>
                  <DetailField name="pensionEntryCost">
                    {moneyFmt(asset.pensionEntryCost)}
                  </DetailField>
                </Row>
              )}
              {!!asset.pensionIndexation && (
                <Row>
                  <DetailField name="pensionIndexation">{asset.pensionIndexation}</DetailField>
                </Row>
              )}
            </Card.Body>
          </Card>
          {!!asset.remarks && (
            <DetailField name="remarks">
              <span className="pre">{asset.remarks}</span>
            </DetailField>
          )}
        </Dl>
      </AccordionBody>
    </AccordionItem>
  )
}
