import { useQuery } from 'react-query'
import { type Institution } from '../generated'
import { api } from '../services/api'
import { type QueryOptions } from './query-utils'

export const institutionQueryKeys = {
  list: ['institutions'],
  institution: (id?: string) => [...institutionQueryKeys.list, id],
}

export const useAllInstitutionQuery = (options: QueryOptions<Institution[]> = {}) =>
  useQuery({
    queryKey: institutionQueryKeys.list,
    queryFn: () =>
      api.institutionsList().then((r) => r.data.sort((a, b) => a.name.localeCompare(b.name))),
    ...options,
  })

export const useInstitutionQuery = (id?: string, options: QueryOptions<Institution> = {}) =>
  useQuery({
    queryKey: institutionQueryKeys.institution(id),
    queryFn: ({ queryKey: [_key, id] }) => {
      return api.institutionsGet(id!).then((r) => r.data)
    },
    enabled: !!id,
    refetchOnWindowFocus: false,
    ...options,
  })
