import { useAuthenticator } from '@aws-amplify/ui-react'
import { fetchUserAttributes } from 'aws-amplify/auth'
import { useEffect, useState } from 'react'
import { Button, InputGroup } from 'react-bootstrap'
import { CenteredContentLayout } from '../components/layouts/centered-content-layout'
import { BsSave } from 'react-icons/bs'
import { translate as t } from '../services/translation'
import { MfaControl } from '../components/security/mfa-control'
import { ChangePassword } from '../components/security/change-password'
import { api } from '../services/api'

export function Profile() {
  const { user } = useAuthenticator((context) => [context.user])
  const [email, setEmail] = useState<string>('')
  useEffect(() => {
    const run = async () => {
      if (user) {
        const attributes = await fetchUserAttributes()
        attributes.email && setEmail(attributes.email)
      }
    }
    run().catch(console.error)
  }, [user])

  const saveEmail = async () => {
    const resp = await api.usersSetEmail(user.username, { email })
    if (resp.data.verificationSent) {
      alert(t('user.verificationSent'))
    }
  }

  return (
    <CenteredContentLayout>
      <h2>{user.username}</h2>
      <InputGroup className="mb-3">
        <div className="input-group-text">{t('user.email')}</div>
        <input className="form-control" value={email} onChange={(e) => setEmail(e.target.value)} />
        <Button className="me-2 icon-wrapper" onClick={saveEmail}>
          <BsSave />
          <span>{t('button.save')}</span>
        </Button>
      </InputGroup>
      <MfaControl user={user} />
      <ChangePassword />
    </CenteredContentLayout>
  )
}
