import { useQuery } from 'react-query'
import { type AssetManager } from '../generated'
import { api } from '../services/api'
import { type QueryOptions } from './query-utils'

export const assetManagerQueryKeys = {
  list: ['assetManagers'],
  assetManager: (id?: string) => [...assetManagerQueryKeys.list, id],
}

export const useAllAssetManagerQuery = (options: QueryOptions<AssetManager[]> = {}) =>
  useQuery({
    queryKey: assetManagerQueryKeys.list,
    queryFn: () =>
      api.assetManagersList().then((r) => r.data.sort((a, b) => a.name.localeCompare(b.name))),
    ...options,
  })

export const useAssetManagerQuery = (id?: string, options: QueryOptions<AssetManager> = {}) =>
  useQuery({
    queryKey: assetManagerQueryKeys.assetManager(id),
    queryFn: ({ queryKey: [_key, id] }) => {
      return api.assetManagersGet(id!).then((r) => r.data)
    },
    enabled: !!id,
    refetchOnWindowFocus: false,
    ...options,
  })
