import { signOut } from 'aws-amplify/auth'

const AUTO_SIGN_OUT_TIMEOUT = 30 * 60 * 1000 // 30 minutes
let autoSignOutTimer: ReturnType<typeof setTimeout> | undefined

export function resetAutoSignOutTimer() {
  clearTimeout(autoSignOutTimer)
  autoSignOutTimer = setTimeout(() => {
    console.log('auto sign out')
    autoSignOutTimer = undefined
    signOut().catch((error) => {
      console.error('auto sign out error', error)
    })
  }, AUTO_SIGN_OUT_TIMEOUT)
}
