import { Accordion, Col, Container, Row } from 'react-bootstrap'
import AccordionItem from 'react-bootstrap/AccordionItem'
import AccordionHeader from 'react-bootstrap/AccordionHeader'
import AccordionBody from 'react-bootstrap/AccordionBody'
import { AssetEvolution } from './asset-evolution'
import { AssetData } from './asset-data'
import { assetTypes } from '../../business-logic/contract-properties'
import { moneyFmt } from '../../util/formatters'

export const AssetAccordion = ({ asset }) => {
  return (
    <Accordion alwaysOpen={true} defaultActiveKey={assetTypes[asset.type].defaultOpen}>
      <AccordionItem eventKey="evolution">
        <AccordionHeader>
          <Container>
            <Row>
              <Col sm="4">{asset.product?.name}</Col>
              <Col sm="4">
                {asset.institution?.name} {asset.managedByFeax && <span>(feax)</span>}
              </Col>
              <Col sm="4">
                <i>{moneyFmt(asset)}</i>
              </Col>
            </Row>
          </Container>
        </AccordionHeader>
        <AccordionBody>
          <AssetEvolution asset={asset} />
        </AccordionBody>
      </AccordionItem>
      <AccordionItem eventKey="details">
        <AccordionHeader>Details</AccordionHeader>
        <AccordionBody>
          <AssetData asset={asset} />
        </AccordionBody>
      </AccordionItem>
      {assetTypes[asset.type]?.details(asset)}
    </Accordion>
  )
}
