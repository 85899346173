import { Link, useParams } from 'react-router-dom'
import { useDossierQuery } from '../../queries/dossier-queries'
import { translate as t } from '../../services/translation'
import { Wallet } from '../../generated'
import { todayIso } from '../../util/date-util'
import { useState } from 'react'
import { WalletDetails } from '../../components/report/wallet-details'
import { Form, Nav } from 'react-bootstrap'
import { BsPenFill } from 'react-icons/bs'

export const WalletTab = () => {
  const { id, walletKey } = useParams()
  const { data } = useDossierQuery(id, { refetchOnMount: false })
  const wallet: Wallet = data!.wallets!.find((w) => w.key === walletKey)!
  const date = todayIso()

  const [showAll, setShowAll] = useState(false)

  return (
    <>
      <WalletDetails wallet={wallet} date={date} showAll={showAll} />
      <Nav>
        <Nav.Item>
          <Form.Check
            className="pt-2 text-primary"
            type="switch"
            onChange={(e) => setShowAll(e.target.checked)}
            label={t('wallet.showEmptyLines')}
          />
        </Nav.Item>
        <Nav.Item>
          <Link className="btn icon-wrapper link-primary" to={'edit'}>
            <BsPenFill /> {t('wallet.editValues')}
          </Link>
        </Nav.Item>
      </Nav>
    </>
  )
}
