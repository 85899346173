import { type CellContext, createColumnHelper, filterFns, Table } from '@tanstack/react-table'
import clsx from 'clsx'
import { useMemo } from 'react'
import { ButtonGroup, Card, Row } from 'react-bootstrap'
import { BsArchive, BsEye, BsPen, BsPlus, BsTrash } from 'react-icons/bs'
import { Link } from 'react-router-dom'
import { FormContainer, InlineFormField } from '../../components/form'
import { useFilterStateInURL, useSortingStateInURL } from '../../components/table/filter-sorting'
import { CenteredContentLayout } from '../../components/layouts/centered-content-layout'
import { useOpinionatedReactTable } from '../../components/table/react-table'
import { TableLayout } from '../../components/table/table-layout'
import { type DossierSummary } from '../../generated'
import { useAllDossiersQuery } from '../../queries/dossier-queries'
import { EMPTY_ARRAY } from '../../util/object-utils'
import { translate as t } from '../../services/translation'
import { IfStaff } from '../../components/security/authorisation'

const dossierFiltersDefaultValues = {
  name: '',
  profile: '',
  description: '',
  archived: false,
}

const DossierFilters = ({ setColumnFilters }: Table<DossierSummary>) => {
  const { filterState, onFilterStateChanged } = useFilterStateInURL({
    defaultFilters: dossierFiltersDefaultValues,
    setColumnFilters,
  })

  return (
    <Card className="mb-3">
      <Card.Header>Filter</Card.Header>
      <Card.Body>
        <FormContainer
          defaultValues={dossierFiltersDefaultValues}
          values={filterState}
          submitOnChange={true}
          onSubmit={onFilterStateChanged}
          translationPath="dossier"
        >
          <Row>
            <InlineFormField name="name" />
            <InlineFormField name="profile" />
            <InlineFormField name="description" />
          </Row>
          <Row>
            <InlineFormField name="archived" inputProps={{ type: 'switch' }} />
          </Row>
        </FormContainer>
      </Card.Body>
    </Card>
  )
}

const DossierRowActions = (props: CellContext<DossierSummary, unknown>) => (
  <div className="d-flex">
    <ButtonGroup className="ms-auto">
      <Link className="btn btn-info icon-wrapper" to={`${props.row.original.id}/report`}>
        <BsEye />
      </Link>
      <IfStaff>
        <Link className="btn btn-primary icon-wrapper" to={`${props.row.original.id}/edit`}>
          <BsPen />
        </Link>
        <Link
          className={clsx('btn', 'btn-danger', 'icon-wrapper')}
          to={`${props.row.original.id}/delete`}
        >
          <BsTrash />
        </Link>
      </IfStaff>
    </ButtonGroup>
  </div>
)

const columnHelper = createColumnHelper<DossierSummary>()

const columns = [
  columnHelper.accessor('archived', {
    filterFn: (row, columnId, filterValue, _) => {
      return filterValue || !row.getValue(columnId)
    },
  }),
  columnHelper.accessor('name', {
    header: () => t('dossier.name'),
    cell: ({ getValue, row }) => {
      const id = row.original.id
      const archived = row.original.archived
      const name = getValue()
      return (
        <>
          {archived && <BsArchive className="text-danger me-1" />}
          <Link
            className="link-dark link-offset-2 link-underline-opacity-10 link-underline-opacity-100-hover"
            to={`${id}/report`}
          >
            {name}
          </Link>
        </>
      )
    },
    filterFn: filterFns.includesString,
  }),
  columnHelper.accessor('profile', {
    header: () => t('dossier.profile'),
    filterFn: filterFns.includesString,
  }),
  columnHelper.accessor('description', {
    header: () => t('dossier.description'),
    filterFn: filterFns.includesString,
  }),
  columnHelper.display({
    id: 'actions',
    cell: (props) => <DossierRowActions {...props} />,
    footer: (_) => null,
    meta: {
      width: '1%', // this is a trick to set minimal width
    },
  }),
]

export const Dossiers = () => {
  // Queries
  const dossiersQuery = useAllDossiersQuery()

  const data = useMemo(() => {
    if (dossiersQuery.isSuccess) {
      return dossiersQuery.data
    }
    return EMPTY_ARRAY
  }, [dossiersQuery.isSuccess, dossiersQuery.data])

  const tableInstance = useOpinionatedReactTable({
    columns,
    data,
    state: {
      columnVisibility: {
        archived: false,
      },
    },
    meta: {
      getRowProps: (row) => ({
        className: clsx({ 'bg-warning bg-opacity-25': row.original.archived }),
      }),
    },
  })
  useSortingStateInURL(tableInstance)

  return (
    <CenteredContentLayout>
      <h1>{t('list', t('dossier'))}</h1>
      <IfStaff>
        <div className="d-flex justify-content-end mb-3">
          <Link className="btn btn-primary icon-wrapper" to="new">
            <BsPlus size="1.5em" />
            <span>{t('create', t('dossier'))}</span>
          </Link>
        </div>
        <DossierFilters {...tableInstance} />
      </IfStaff>
      <TableLayout {...tableInstance} />
    </CenteredContentLayout>
  )
}
