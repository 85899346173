export const PercentageBar = ({ percentage, category = '' }) => {
  let className = 'percentage-bar '
  if (category) {
    className += category
  }
  return (
    <div className={className} style={{ width: percentage! * 100 + '%' }}>
      &nbsp;
    </div>
  )
}
