import { fetchAuthSession, signOut } from 'aws-amplify/auth'
import { AxiosInstance } from 'axios'
import { toast } from 'react-toastify'
import { translate as t } from '../services/translation'
import { resetAutoSignOutTimer } from '../components/security/auto-sign-out'

function setSpinner(on) {
  const spinner = document.getElementById('spinner')
  if (spinner) {
    spinner.className = on ? '' : 'd-none'
  }
}

export const setupAxiosCognitoInterceptors = (axiosInstance: AxiosInstance): AxiosInstance => {
  const onRequestSuccess = async (config) => {
    try {
      setSpinner(true)
      // this should renew token if possible
      const session = await fetchAuthSession()
      const idToken = session?.tokens?.idToken?.toString()

      if (idToken) {
        config.headers.Authorization = `Bearer ${idToken}`
      }
      resetAutoSignOutTimer()
    } catch (err) {
      console.warn('axios request interceptor:', err)
    }
    return config
  }

  axiosInstance.interceptors.request.use(onRequestSuccess)
  axiosInstance.interceptors.response.use(
    (response) => {
      setSpinner(false)
      return response
    },
    async (error) => {
      console.error('axios response error:', error)
      setSpinner(false)
      switch (error.response.status) {
        case 400:
          toast.error(t('badRequest.error', error.response?.data?.detail ?? ''))
          break
        case 401:
        case 403:
          // force cognito state to reset and trigger the redirect to login
          await signOut()
          break
        case 409:
          toast.error(t('conflict.error'))
          break
        default:
          toast.error(t('unknown.error'))
      }
      throw error
    }
  )

  return axiosInstance
}
