import {
  ColumnFiltersState,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  type SortingState,
  TableOptions,
  useReactTable,
} from '@tanstack/react-table'
import { useState } from 'react'

export type OpinionatedTableOptions<TData extends unknown> = Partial<TableOptions<TData>> &
  Pick<TableOptions<TData>, 'columns' | 'data'> // -> these fields are required and not opinionated

export const useOpinionatedReactTable = <TData extends unknown>({
  state,
  ...otherOptions
}: OpinionatedTableOptions<TData>) => {
  const [sorting, setSorting] = useState<SortingState>([])
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])
  const [globalFilter, setGlobalFilter] = useState('')

  return useReactTable({
    state: {
      sorting,
      columnFilters,
      globalFilter,
      ...state,
    },
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    enableColumnFilters: true,
    enableGlobalFilter: true,
    enableSorting: true,
    enableSortingRemoval: false,
    ...otherOptions,
  })
}
