import React from 'react'
import { Dropdown, Nav, NavDropdown as BsNavDropdown, type NavDropdownProps } from 'react-bootstrap'
import { DropdownMenuProps } from 'react-bootstrap/esm/DropdownMenu'

/**
 * Redefine NavDropdown to re-design the toggle button with an icon
 * @param props
 * @returns
 */
export type UncontrolledNavDropdownProps = Omit<NavDropdownProps, 'title' | 'onSelect'> & {
  icon?: React.ReactNode
  title?: React.ReactNode
  menuAlign?: DropdownMenuProps['align']
}

const UncontrolledNavDropdown = ({
  icon = undefined,
  title = undefined,
  menuAlign = undefined,
  menuVariant = undefined,
  children,
  ...navDropdownProps
}: UncontrolledNavDropdownProps) => (
  <Nav.Item as={Dropdown} {...navDropdownProps}>
    <Dropdown.Toggle className="icon-wrapper" as={Nav.Link}>
      {icon ?? null}
      {title && <span>{title}</span>}
    </Dropdown.Toggle>
    <Dropdown.Menu align={menuAlign} variant={menuVariant}>
      {children ?? null}
    </Dropdown.Menu>
  </Nav.Item>
)

UncontrolledNavDropdown.Divider = BsNavDropdown.Divider
UncontrolledNavDropdown.Header = BsNavDropdown.Header
UncontrolledNavDropdown.ItemText = BsNavDropdown.ItemText
UncontrolledNavDropdown.Item = BsNavDropdown.Item

export const NavDropdown = UncontrolledNavDropdown
