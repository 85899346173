import { Table } from 'react-bootstrap'
import { translate as t } from '../../services/translation'
import { percentageFmt, totalFmt } from '../../util/formatters'

export const CategoryTable = ({ category, data }) => (
  <Table className="category-table mt-2" responsive>
    <tbody>
      <tr className={category}>
        <th className="col-sm-3">{t(category)}</th>
        <th className="col-sm-1 text-end">Bedrag</th>
        <th className="col-sm-1 text-end">%</th>
      </tr>
      {data.institutions
        .sort((a, b) => b.total - a.total)
        .map(
          (institution) =>
            !!institution.total && (
              <tr key={institution.name}>
                <td className="col-sm-3">{institution.name}</td>
                <td className="col-sm-1 text-end">{totalFmt(institution.total)}</td>
                <td className="col-sm-1 text-end">{percentageFmt(institution.percentage)}</td>
              </tr>
            )
        )}
      <tr className="group success">
        <th className="col-sm-3">Totaal</th>
        <th className="col-sm-1 text-end">{totalFmt(data.total)}</th>
        <th className="col-sm-1 text-end">100,0%</th>
      </tr>
    </tbody>
  </Table>
)
