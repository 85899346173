import { Link, useParams } from 'react-router-dom'
import {
  dossierQueryKeys,
  useAllDossiersQuery,
  useDossierQuery,
} from '../../queries/dossier-queries'
import { translate as t } from '../../services/translation'
import { Dossier, DossierInfo, Wallet } from '../../generated'
import { AssetLink } from '../../components/asset/asset-modal'
import { Button, Col, Container, Nav, Row } from 'react-bootstrap'
import { BsArrowLeft, BsSave } from 'react-icons/bs'
import React, { useMemo, useState } from 'react'
import { Typeahead } from 'react-bootstrap-typeahead'
import { EMPTY_ARRAY } from '../../util/object-utils'
import { api } from '../../services/api'
import { queryClient } from '../../queries/react-query-client'

export const WalletMoveAssets = () => {
  const { id, walletKey } = useParams()
  const { data: dossier } = useDossierQuery(id, { refetchOnMount: false })
  const wallet: Wallet | undefined = dossier!.wallets!.find((w) => w.key === walletKey)!
  const [state, setState] = useState(
    (wallet.assets ?? []).map((asset) => ({ asset, destDossier: dossier, destWallet: wallet }))
  )

  const dossiersQuery = useAllDossiersQuery()
  const dossiers = useMemo(() => {
    if (dossiersQuery.isSuccess) {
      return dossiersQuery.data
    }
    return EMPTY_ARRAY
  }, [dossiersQuery.isSuccess, dossiersQuery.data])

  const setDossier = async (index: number, dossierInfo: DossierInfo) => {
    let destDossier: Dossier | undefined = undefined
    let destWallet: Wallet | undefined = undefined
    if (dossierInfo) {
      const resp = await api.dossiersGet(dossierInfo.id!)
      destDossier = resp.data
      destWallet = destDossier.wallets?.length ? destDossier.wallets[0] : undefined
    }
    // @ts-ignore
    state[index] = { ...state[index], destDossier: destDossier, destWallet: destWallet }
    setState([...state])
  }
  const setWallet = async (index: number, walletKey: string) => {
    if (walletKey) {
      state[index].destWallet = state[index].destDossier?.wallets?.find((w) => w.key === walletKey)!
      setState([...state])
    }
  }

  const move = async (index) => {
    const asset = state[index].asset
    if (state[index].destWallet?.key && asset.walletKey !== state[index].destWallet.key) {
      await api.assetsSave(state[index]?.destDossier?.id!, {
        ...asset,
        id: state[index]?.destDossier?.id,
        key: undefined,
        version: undefined,
        walletKey: state[index].destWallet.key,
      })
      await api.assetsDelete(asset.id!, asset.key!)
      setState(state.filter((_, i) => i !== index))
      await queryClient.invalidateQueries(dossierQueryKeys.dossier(id))
    }
  }

  return (
    <>
      <Nav>
        <Nav.Item>
          <Link className="nav-link icon-wrapper" to="../edit">
            <BsArrowLeft />
            <span>{t('button.back')}</span>
          </Link>
        </Nav.Item>
      </Nav>

      <Container className="table">
        <Row className="fw-bold">
          <Col>
            {t('asset.reference')} / {t('asset.type')}
          </Col>
          <Col>{t('product')}</Col>
          <Col md="4">{t('dossier')}</Col>
          <Col>{t('wallet')}</Col>
          <Col> </Col>
        </Row>
        {state.map((entry, index) => (
          <Row key={index}>
            <Col>
              <AssetLink wallet={wallet} asset={entry.asset} />
            </Col>
            <Col>{entry.asset.product.name}</Col>
            <Col md="4">
              {/* this is not using TypeaheadFormField, that is why it behaves differently */}
              <Typeahead
                id="dossiers"
                options={dossiers}
                labelKey="name"
                // @ts-ignore
                selected={state[index]?.destDossier ? [state[index].destDossier] : []}
                onChange={(selected) => setDossier(index, selected[0] as DossierInfo)}
              />
            </Col>
            <Col md="2">
              <select
                value={state[index]?.destWallet?.key}
                onChange={(e) => setWallet(index, e.target.value)}
              >
                {entry.destDossier?.wallets?.map((w) => (
                  <option key={w.key} value={w.key}>
                    {w.name}
                  </option>
                ))}
              </select>
            </Col>
            <Col>
              <Button
                className="icon-wrapper"
                onClick={() => {
                  move(index)
                }}
              >
                <BsSave />
              </Button>
            </Col>
          </Row>
        ))}
      </Container>
      <Nav>
        <Nav.Item>
          <Link className="nav-link icon-wrapper" to="../edit">
            <BsArrowLeft />
            <span>{t('button.back')}</span>
          </Link>
        </Nav.Item>
      </Nav>
    </>
  )
}
