import { FormContainer, FormField } from '../../../components/form'
import { Button, Col, Form, Row, Table } from 'react-bootstrap'
import { BsDownload, BsSave } from 'react-icons/bs'
import { translate as t } from '../../../services/translation'
import { api } from '../../../services/api'
import { CenteredContentLayout } from '../../../components/layouts/centered-content-layout'
import { useState } from 'react'
import { dateFmt, moneyFmt, percentageFmt } from '../../../util/formatters'
import { toast } from 'react-toastify'

interface BaloiseAsset {
  assetReference?: string
  validFrom?: string
  value?: number
  productName?: string
  productReference?: string
  currency?: string
}

interface BaloiseAssetStatus {
  baloiseAsset?: BaloiseAsset
  assetId?: string
  assetKey?: string
  currentValue?: number
  currentValidFrom?: string
  status?: string
  diff?: number
  isLargeDiff?: boolean
}

interface SyncResult {
  changed: BaloiseAssetStatus[]
  unChanged: BaloiseAssetStatus[]
  errors: BaloiseAssetStatus[]
  importing: boolean
}

export const CsvUpload = () => {
  const [syncResult, setSyncResult] = useState<SyncResult>({
    changed: [],
    unChanged: [],
    errors: [],
    importing: false,
  })
  const onSubmit = async (data) => {
    const resp = await api.batchUpdateBaloiseSync(data.separator, data.csv[0])
    const statuses = resp.data
    const changed = statuses
      .filter((s) => s.status === 'CHANGED')
      .map((status) => ({
        ...status,
        diff:
          ((status.baloiseAsset?.value || 0) - (status.currentValue || 0)) /
          (status.currentValue || 1),
        isLargeDiff:
          (status.baloiseAsset?.value || 0) / (status.currentValue || 1) < 0.95 ||
          (status.baloiseAsset?.value || 0) / (status.currentValue || 1) > 1.05,
      }))
    const unChanged = statuses.filter((s) => s.status === 'UNCHANGED')
    const errors = statuses.filter((s) => s.status !== 'UNCHANGED' && s.status !== 'CHANGED')
    setSyncResult({ changed, unChanged, errors, importing: false })
  }

  const importAssets = async () => {
    const newValues = syncResult.changed.map((s) => ({
      id: s.assetId!,
      key: s.assetKey!,
      value: { validFrom: s.baloiseAsset?.validFrom!, value: s.baloiseAsset?.value! },
    }))
    await api.batchUpdateUpdateAssetValues(newValues)
    toast.info(t('baloise.imported'))
  }

  return (
    <CenteredContentLayout>
      <h1>Baloise CSV upload</h1>
      <FormContainer values={{}} onSubmit={onSubmit} translationPath="baloise">
        <FormField name="csv" inputProps={{ type: 'file' }} registerOptions={{ required: true }} />
        <FormField
          name="separator"
          inputProps={{
            type: 'select',
            options: [',', ';'],
          }}
        />
        <Form.Group as={Row} className="mb-3">
          <Col md={{ span: 9, offset: 3 }}>
            <Button type="submit" className="me-2 icon-wrapper">
              <BsSave />
              <span>{t('admin.upload')}</span>
            </Button>
          </Col>
        </Form.Group>
      </FormContainer>

      <h1>{t('baloise.updates')}</h1>
      <Table responsive striped bordered hover>
        <thead>
          <tr>
            <th>{t('asset.policy')}</th>
            <th>{t('product')}</th>
            <th colSpan={2}>{t('baloise.currentValue')}</th>
            <th colSpan={2}>{t('baloise.newValue')}</th>
            <th>{t('difference')}</th>
          </tr>
        </thead>
        <tbody>
          {syncResult.changed.map((status, index) => (
            <tr key={index}>
              <td>{status.baloiseAsset?.assetReference}</td>
              <td>
                {status.baloiseAsset?.productName} - {status.baloiseAsset?.productReference}
              </td>
              <td>{dateFmt(status.currentValidFrom)}</td>
              <td className="text-end">{moneyFmt(status.currentValue)}</td>
              <td>{dateFmt(status.baloiseAsset?.validFrom)}</td>
              <td className="text-end">{moneyFmt(status.baloiseAsset?.value)}</td>
              <td className={status.isLargeDiff ? 'text-danger' : ''}>
                {percentageFmt(status.diff)}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      {syncResult.changed && (
        <Button
          variant="primary"
          className="icon-wrapper"
          onClick={importAssets}
          disabled={syncResult.importing}
        >
          {t('admin.import')} <BsDownload />
        </Button>
      )}

      <h3>{t('baloise.unchanged')}</h3>
      <Table responsive striped bordered hover>
        <thead>
          <tr>
            <th>{t('asset.policy')}</th>
            <th>{t('product')}</th>
            <th colSpan={2}>{t('baloise.currentValue')}</th>
            <th colSpan={2}>{t('baloise.newValue')}</th>
          </tr>
        </thead>
        <tbody>
          {syncResult.unChanged.map((status, index) => (
            <tr key={index}>
              <td>{status.baloiseAsset?.assetReference}</td>
              <td>
                {status.baloiseAsset?.productName} - {status.baloiseAsset?.productReference}
              </td>
              <td>{dateFmt(status.currentValidFrom)}</td>
              <td className="text-end">{moneyFmt(status.currentValue)}</td>
              <td>{dateFmt(status.baloiseAsset?.validFrom)}</td>
              <td className="text-end">{moneyFmt(status.baloiseAsset?.value)}</td>
            </tr>
          ))}
        </tbody>
      </Table>

      <h3>{t('baloise.errors')}</h3>
      <Table responsive striped bordered hover>
        <thead>
          <tr>
            <th>{t('asset.policy')}</th>
            <th>{t('product')}</th>
            <th colSpan={2}>{t('baloise.currentValue')}</th>
            <th colSpan={2}>{t('baloise.newValue')}</th>
            <th>{t('baloise.error')}</th>
          </tr>
        </thead>
        <tbody>
          {syncResult.errors.map((status, index) => (
            <tr key={index}>
              <td>{status.baloiseAsset?.assetReference}</td>
              <td>
                {status.baloiseAsset?.productName} - {status.baloiseAsset?.productReference}
              </td>
              <td>{dateFmt(status.currentValidFrom)}</td>
              <td className="text-end">{moneyFmt(status.currentValue)}</td>
              <td>{dateFmt(status.baloiseAsset?.validFrom)}</td>
              <td className="text-end">{moneyFmt(status.baloiseAsset?.value)}</td>
              <td>{t(status.status ?? 'unknown')}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </CenteredContentLayout>
  )
}
