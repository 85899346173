import { translate as t } from '../../services/translation'
import { Badge, Button, ListGroup, ListGroupItem, Modal } from 'react-bootstrap'
import { dateFmt, moneyFmt } from '../../util/formatters'
import React, { useState } from 'react'
import { BsList, BsX } from 'react-icons/bs'
import { api } from '../../services/api'
import { queryClient } from '../../queries/react-query-client'
import { dossierQueryKeys } from '../../queries/dossier-queries'
import { IfStaff } from '../security/authorisation'

export const AssetValues = ({ asset, edit = false }) => {
  const [showAll, setShowAll] = useState(edit)
  const deleteValue = async (index) => {
    asset.values.splice(index, 1)
    await api.assetsUpdate(asset.id, asset.key, asset)
    await queryClient.invalidateQueries(dossierQueryKeys.dossier(asset.id))
  }

  return (
    <ListGroup>
      {asset.values?.map(
        (value, index) =>
          (showAll || index < 4) && (
            <ListGroupItem key={index} className={!index ? 'active' : ''}>
              {edit && asset.values.length > 1 && (
                <IfStaff>
                  <Button variant="link" onClick={() => deleteValue(index)}>
                    <BsX className="text-danger fw-bold" />
                  </Button>
                </IfStaff>
              )}
              {dateFmt(value.validFrom)} : {moneyFmt(value.value)}
              {!!value.amountChange && (
                <span className={value.amountChange ? 'badge' : ''}>
                  {value.amountChange > 0 ? '+' : ''}
                  {moneyFmt(value.amountChange)}
                </span>
              )}
              {!!value.amountChange && (
                <Badge>
                  {t('asset.value.amountChange')}: {value.amountChange > 0 ? '+' : ''}
                  {moneyFmt(value.amountChange)}
                </Badge>
              )}{' '}
              {!!value.entryCost && (
                <Badge>
                  {t('asset.value.entryCost')}: {moneyFmt(value.entryCost)}
                </Badge>
              )}
            </ListGroupItem>
          )
      )}
      {asset.values.length > 4 && !edit && (
        <ListGroupItem className="list-group-item">
          <Button variant="link" onClick={() => setShowAll((prev) => !prev)}>
            {t(showAll ? 'show.less' : 'show.more')}
          </Button>
        </ListGroupItem>
      )}
    </ListGroup>
  )
}

export const AssetValuesModal = ({ asset, edit = false }) => {
  const [visible, setVisible] = useState(false)
  const toggleVisible = () => setVisible(!visible)
  return (
    <>
      <Button title={t('asset.value.history')} className="icon-wrapper" onClick={toggleVisible}>
        <BsList />
      </Button>
      <Modal show={visible} onHide={toggleVisible} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>{t('asset.value')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AssetValues asset={asset} edit={edit} />
        </Modal.Body>
      </Modal>
    </>
  )
}
