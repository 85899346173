import { createColumnHelper, filterFns, Table } from '@tanstack/react-table'
import clsx from 'clsx'
import { Card, Row } from 'react-bootstrap'
import { BsArchive, BsPlus } from 'react-icons/bs'
import { Link } from 'react-router-dom'
import { InlineFormField } from '../../../components/form'
import { FormContainer } from '../../../components/form/form-container'
import { CenteredContentLayout } from '../../../components/layouts/centered-content-layout'
import { CrudTableRowActions } from '../../../components/table/CrudTableRowActions'
import { useFilterStateInURL, useSortingStateInURL } from '../../../components/table/filter-sorting'
import { useOpinionatedReactTable } from '../../../components/table/react-table'
import { TableLayout } from '../../../components/table/table-layout'
import { type Institution } from '../../../generated'
import { useAllInstitutionQuery } from '../../../queries/institution-queries'
import { EMPTY_ARRAY } from '../../../util/object-utils'
import { translate as t } from '../../../services/translation'

const institutionFiltersDefaultValues = {
  name: '',
  archived: false,
}

const InstitutionFilters = ({ setColumnFilters }: Table<Institution>) => {
  const { filterState, onFilterStateChanged } = useFilterStateInURL({
    defaultFilters: institutionFiltersDefaultValues,
    setColumnFilters,
  })

  return (
    <Card className="mb-3">
      <Card.Header>Filter</Card.Header>
      <Card.Body>
        <FormContainer
          defaultValues={institutionFiltersDefaultValues}
          values={filterState}
          submitOnChange={true}
          onSubmit={onFilterStateChanged}
          translationPath="institution"
        >
          <Row>
            <InlineFormField name="name" />
            <InlineFormField name="archived" inputProps={{ type: 'switch' }} />
          </Row>
        </FormContainer>
      </Card.Body>
    </Card>
  )
}

const columnHelper = createColumnHelper<Institution>()

const columns = [
  columnHelper.accessor('archived', {
    filterFn: (row, columnId, filterValue, _) => {
      return filterValue || !row.getValue(columnId)
    },
  }),
  columnHelper.accessor('name', {
    header: () => t('institution.name'),
    cell: ({ getValue, row }) => {
      const id = row.original.id
      const archived = row.original.archived
      const name = getValue()
      return (
        <>
          {archived && <BsArchive className="text-danger me-1" />}
          <Link
            className="link-dark link-offset-2 link-underline-opacity-10 link-underline-opacity-100-hover"
            to={`${id}`}
          >
            {name}
          </Link>
        </>
      )
    },
    filterFn: filterFns.includesString,
  }),
  columnHelper.accessor('url', {
    header: () => t('institution.url'),
    cell: ({ getValue }) => {
      const url = getValue()
      if (url) {
        return (
          <Link
            className="link-dark link-offset-2 link-underline-opacity-10 link-underline-opacity-100-hover"
            to={url}
          >
            {url}
          </Link>
        )
      }
    },
  }),
  columnHelper.display({
    id: 'actions',
    cell: (props) => <CrudTableRowActions row={props.row.original} />,
    footer: (_) => null,
    meta: {
      width: '1%', // this is a trick to set minimal width
    },
  }),
]

export const Institutions = () => {
  // Queries
  const { data = EMPTY_ARRAY } = useAllInstitutionQuery({ refetchOnWindowFocus: false })

  const tableInstance = useOpinionatedReactTable({
    columns,
    data,
    state: {
      columnVisibility: {
        archived: false,
      },
    },
    meta: {
      getRowProps: (row) => ({
        className: clsx({ 'bg-warning bg-opacity-25': row.original.archived }),
      }),
    },
  })
  useSortingStateInURL(tableInstance)

  return (
    <CenteredContentLayout>
      <h1>{t('list', t('institution'))}</h1>
      <div className="d-flex justify-content-end mb-3">
        <Link className="btn btn-primary icon-wrapper" to="new">
          <BsPlus size="1.5em" />
          <span>{t('create', t('institution'))}</span>
        </Link>
      </div>
      <InstitutionFilters {...tableInstance} />
      <TableLayout {...tableInstance} />
    </CenteredContentLayout>
  )
}
