import { useQuery } from 'react-query'
import { type Product } from '../generated'
import { api } from '../services/api'
import { type QueryOptions } from './query-utils'

export const productQueryKeys = {
  list: ['products'],
  product: (id?: string) => [...productQueryKeys.list, id],
}

export const useAllProductsQuery = (options: QueryOptions<Product[]> = {}) =>
  useQuery({
    queryKey: productQueryKeys.list,
    queryFn: () =>
      api.productsList().then((r) => r.data.sort((a, b) => a.name.localeCompare(b.name))),
    ...options,
  })

export const useProductQuery = (id?: string, options: QueryOptions<Product> = {}) =>
  useQuery({
    queryKey: productQueryKeys.product(id),
    queryFn: ({ queryKey: [_key, id] }) => {
      return api.productsGet(id!).then((r) => r.data)
    },
    enabled: !!id,
    refetchOnWindowFocus: false,
    ...options,
  })
