import { FormField } from '../form'

export const RealEstateContractForm = (_) => {
  return (
    <>
      <FormField
        name="annualRentalIncome"
        inputProps={{ type: 'number', min: 0, step: 0.01 }}
        registerOptions={{ min: 0, valueAsNumber: true }}
      />
      <FormField name="remarks" inputProps={{ as: 'textarea', rows: 5, maxLength: 512 }} />
    </>
  )
}
