import type { RowData, SortingState, Table } from '@tanstack/react-table'
import { debounce } from 'lodash'
import { useCallback, useEffect, useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'
import { EMPTY_ARRAY } from '../../util/object-utils'

export const SORT_KEY = 'sort'

const omitKey = (obj: Record<string, any>, key: string): Record<string, any> => {
  const { [key]: removedKey, ...rest } = obj
  return rest
}

const mapStringToSortingState = (sortStr: string | null | undefined): SortingState => {
  if (!sortStr) return EMPTY_ARRAY

  return sortStr?.split(',').map((s) => ({
    id: s.startsWith('-') ? s.slice(1) : s,
    desc: s.startsWith('-'),
  }))
}

const mapSortingStateToString = (state: SortingState): string =>
  state.map(({ id, desc }) => `${desc ? '-' : ''}${id}`).join(',')

export const useSortingStateInURL = <TData extends RowData = unknown>({
  getState,
  setSorting,
}: Pick<Table<TData>, 'getState' | 'setSorting'>) => {
  const [searchParams, setSearchParams] = useSearchParams()
  /**
   * Update sorting on table from searchParams
   */
  const sortStr = searchParams.get(SORT_KEY)
  const sortingState: SortingState = useMemo(() => mapStringToSortingState(sortStr), [sortStr])

  useEffect(() => setSorting(sortingState), [sortingState, setSorting])

  /**
   * Update searchParams when sorting changed after user input
   */
  const tableSortingState = getState().sorting
  useEffect(() => {
    const sortStr = mapSortingStateToString(tableSortingState)
    // make sure we don't update in an infinite loop!
    if (tableSortingState.length && searchParams.get(SORT_KEY) !== sortStr) {
      const params = {
        ...Object.fromEntries(searchParams.entries()),
        [SORT_KEY]: sortStr,
      }
      setSearchParams(params, { replace: true })
    }
  }, [tableSortingState, searchParams, setSearchParams])

  return {
    sortingState,
  }
}

const mapURLSearchParamsToFilter = (
  params: URLSearchParams,
  defaultFilters: Record<string, any>
): Record<string, any> =>
  Array.from(params.entries()).reduce((acc, [k, v]) => ({ ...acc, [k]: v }), defaultFilters)

export type UseFilterStateInURLOptions<TData extends RowData = unknown> = Pick<
  Table<TData>,
  'setColumnFilters'
> & {
  defaultFilters?: Record<string, any>
  mapFilter?: (k: string, v: any) => unknown
}
const defaultMapFilter = (_, v) => v

export const useFilterStateInURL = <TData extends RowData = unknown>({
  defaultFilters = {},
  setColumnFilters,
  mapFilter = defaultMapFilter,
}: UseFilterStateInURLOptions<TData>) => {
  const [searchParams, setSearchParams] = useSearchParams()

  const filterParams = new URLSearchParams(searchParams)
  filterParams.delete(SORT_KEY)

  const filterState = useMemo(
    () => mapURLSearchParamsToFilter(filterParams, defaultFilters),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filterParams.toString(), defaultFilters]
  )

  useEffect(() => {
    setColumnFilters(
      Object.entries(filterState).map(([k, v]) => {
        const value = mapFilter(k, v) || v
        return { id: k, value }
      })
    )
  }, [filterState, setColumnFilters, mapFilter])

  /**
   * Update searchParams when filter changed after user input
   */
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onFilterStateChanged = useCallback(
    debounce((data: Record<string, any>) => {
      const params = Object.entries(data).reduce(
        (acc, [k, v]) => (v ? { ...acc, [k]: v } : omitKey(acc, k)),
        Object.fromEntries(searchParams.entries())
      )

      setSearchParams(params, { replace: true })
    }, 500),
    [searchParams, setSearchParams]
  )

  return {
    filterState,
    onFilterStateChanged,
  }
}
