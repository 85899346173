import { translate as t } from '../../services/translation'
import AccordionItem from 'react-bootstrap/AccordionItem'
import AccordionHeader from 'react-bootstrap/AccordionHeader'
import AccordionBody from 'react-bootstrap/AccordionBody'
import { DetailField, Dl } from '../detail/detail-list'
import { moneyFmt } from '../../util/formatters'

export const BPBCContractDetails = ({ asset }) => {
  return (
    <AccordionItem eventKey="contract">
      <AccordionHeader>
        {t('asset.contract')} {asset.reference}
      </AccordionHeader>
      <AccordionBody>
        <Dl translationPath="asset">
          {!!asset.monthlyAmount && (
            <DetailField name="monthlyAmount">{moneyFmt(asset.monthlyAmount)}</DetailField>
          )}
          {!!asset.entryCost && (
            <DetailField name="entryCost">{moneyFmt(asset.entryCost)}</DetailField>
          )}
          {!!asset.remarks && (
            <DetailField name="remarks">
              <span className="pre">{asset.remarks}</span>
            </DetailField>
          )}
        </Dl>
      </AccordionBody>
    </AccordionItem>
  )
}
