import { Button, Col, Form, Modal, Row } from 'react-bootstrap'
import React, { useState } from 'react'
import { translate as t } from '../../services/translation'
import { AssetAccordion } from './asset-accordion'
import { DeleteDialog } from '../form/delete-dialog'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { BsList, BsPen, BsPlus } from 'react-icons/bs'
import { api } from '../../services/api'
import { queryClient } from '../../queries/react-query-client'
import { dossierQueryKeys } from '../../queries/dossier-queries'
import { Asset, Wallet } from '../../generated'
import { AssetForm } from './asset-form'
import { IfStaff } from '../security/authorisation'
import { AssetValues } from './asset-values'

export enum Mode {
  VIEW,
  EDIT,
  CREATE,
  EDIT_VALUES,
}

export interface AssetModalProps {
  wallet: Wallet
  asset?: Asset
  initialMode: Mode
  visible: boolean
  onClose: () => void
}

export const AssetModal = ({ wallet, asset, initialMode, visible, onClose }: AssetModalProps) => {
  const navigate = useNavigate()
  const [mode, setMode] = useState(initialMode)

  const showAsset = () => {
    setMode(Mode.VIEW)
  }

  const onDelete = async () => {
    await api.assetsDelete(wallet.id!, asset?.key!)
    await queryClient.invalidateQueries(dossierQueryKeys.dossier(wallet.id))
    return navigate(`/dossiers/${wallet.id!}/wallets/${asset?.walletKey}`)
  }

  return (
    <>
      <Modal show={visible} onHide={onClose} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>{t('show', t('asset'))}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {mode === Mode.VIEW && (
            <>
              <AssetAccordion asset={asset} />
              <IfStaff>
                <Form.Group as={Row} className="mt-3">
                  <Col>
                    <Button
                      variant="primary"
                      className="me-2 icon-wrapper"
                      onClick={() => setMode(Mode.EDIT)}
                    >
                      <BsPen />
                      <span>{t('button.edit')}</span>
                    </Button>
                    <Button
                      variant="secondary"
                      className="me-2 icon-wrapper"
                      onClick={() => setMode(Mode.EDIT_VALUES)}
                    >
                      <BsList />
                      <span>{t('asset.manageValues')}</span>
                    </Button>
                    <DeleteDialog action="delete" entity="asset" onDelete={onDelete} />
                  </Col>
                </Form.Group>
              </IfStaff>
            </>
          )}
          {mode === Mode.EDIT && <AssetForm asset={asset} wallet={wallet} onClose={showAsset} />}
          {mode === Mode.CREATE && (
            <AssetForm
              asset={{ id: wallet.id, walletKey: wallet.key, index: wallet.assets?.length || 0 }}
              wallet={wallet}
              onClose={onClose}
            />
          )}
          {mode === Mode.EDIT_VALUES && <AssetValues asset={asset} edit={true} />}
        </Modal.Body>
      </Modal>
    </>
  )
}

export const AssetLink = ({ wallet, asset, strikeThrough = false }) => {
  const [searchParams, setSearchParams] = useSearchParams()

  const _setVisible = (isVisible: boolean) => {
    setSearchParams((prev) => ({
      ...prev,
      assetKey: isVisible ? asset.key : undefined,
    }))
  }
  // calculate modal visibility from path
  const visible = searchParams.get('assetKey') === asset?.key

  return (
    <>
      <Button variant="link" onClick={() => _setVisible(true)} className="icon-link py-0">
        <span className={strikeThrough ? 'text-decoration-line-through' : ''}>
          {asset?.reference || '-'} / {asset?.type}
        </span>
      </Button>
      {visible && (
        <AssetModal
          wallet={wallet}
          asset={asset}
          initialMode={Mode.VIEW}
          visible={visible}
          onClose={() => _setVisible(false)}
        />
      )}
    </>
  )
}
export const CreateAssetLink = ({ wallet }) => {
  const [visible, setVisible] = useState(false)

  return (
    <>
      <Button variant="link" onClick={() => setVisible(true)} className="nav-link icon-wrapper">
        <BsPlus />
        {t('asset.create')}
      </Button>
      {visible && (
        <AssetModal
          wallet={wallet}
          initialMode={Mode.CREATE}
          visible={visible}
          onClose={() => setVisible(false)}
        />
      )}
    </>
  )
}
