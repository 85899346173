import { translate as t } from '../../services/translation'
import AccordionItem from 'react-bootstrap/AccordionItem'
import AccordionHeader from 'react-bootstrap/AccordionHeader'
import AccordionBody from 'react-bootstrap/AccordionBody'
import { DetailField, Dl } from '../detail/detail-list'
import { moneyFmt } from '../../util/formatters'

export const RevenueContractDetails = ({ asset }) => {
  return (
    <AccordionItem eventKey="contract">
      <AccordionHeader>
        {t('asset.policy')} {asset.reference}
      </AccordionHeader>
      <AccordionBody>
        <Dl translationPath="asset">
          <DetailField name="policyHolder">{asset.policyHolder}</DetailField>
          <DetailField name="insured">{asset.insured}</DetailField>
          {!!asset.insuredMonthlyInterest && (
            <DetailField name="insuredMonthlyInterest">
              {moneyFmt(asset.insuredMonthlyInterest)}
            </DetailField>
          )}
          {!!asset.annualPremium && (
            <DetailField name="annualPremium">{moneyFmt(asset.annualPremium)}</DetailField>
          )}
          <DetailField name="periodicity">{t(asset.periodicity)}</DetailField>
          <DetailField name="indexation">{asset.indexation}</DetailField>
          <DetailField name="mainDueDate">{asset.mainDueDate}</DetailField>
          {!!asset.remarks && (
            <DetailField name="remarks">
              <span className="pre">{asset.remarks}</span>
            </DetailField>
          )}
        </Dl>
      </AccordionBody>
    </AccordionItem>
  )
}
