import { type FormHTMLAttributes, type ReactNode, useEffect, useMemo } from 'react'
import { Form } from 'react-bootstrap'
import {
  type FieldValues,
  FormProvider,
  type SubmitHandler,
  useForm,
  type UseFormProps,
  type UseFormReturn,
} from 'react-hook-form'
import {
  TranslationProvider,
  type TranslationProviderProps,
} from '../translation/translation-provider'

export type FormContainerRenderFunc<T extends FieldValues = FieldValues> = (
  props: UseFormReturn<T, any>
) => ReactNode

export type FormContainerProps<T extends FieldValues = FieldValues> = UseFormProps<T> &
  Omit<TranslationProviderProps, 'children'> & {
    onSubmit?: SubmitHandler<T>
    formProps?: FormHTMLAttributes<HTMLFormElement>
    children: ReactNode | FormContainerRenderFunc<T>
    submitOnChange?: boolean
  }

export const FormContainer = <TFieldValues extends FieldValues = FieldValues>({
  children,
  formProps,
  onSubmit,
  submitOnChange = false,
  mode,
  defaultValues,
  translationPath,
  ...useFormProps
}: FormContainerProps<TFieldValues>) => {
  const formInstance = useForm<TFieldValues>({
    defaultValues,
    mode: submitOnChange ? 'onChange' : mode,
    criteriaMode: 'all',
    ...useFormProps,
  })
  const { watch, reset } = formInstance

  // handle rendering function
  let renderedChildren: ReactNode = null
  if (typeof children === 'function') {
    renderedChildren = children(formInstance)
  } else {
    renderedChildren = children
  }

  const _onSubmit = useMemo(
    () => onSubmit ?? ((data) => console.log("submit handler 'onSubmit' is missing", data)),
    [onSubmit]
  )

  useEffect(() => {
    if (submitOnChange) {
      const subscription = watch((value, { name, type }) => {
        if (type === 'change') {
          _onSubmit(value as TFieldValues)
        }
      })
      return () => subscription.unsubscribe()
    }
  }, [submitOnChange, watch, _onSubmit])

  return (
    <TranslationProvider translationPath={translationPath}>
      <FormProvider {...formInstance}>
        <Form
          onSubmit={formInstance.handleSubmit(_onSubmit)}
          noValidate
          onReset={() => reset()}
          {...formProps}
        >
          {renderedChildren ?? null}
        </Form>
      </FormProvider>
    </TranslationProvider>
  )
}
