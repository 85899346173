import { Outlet, RouteObject } from 'react-router-dom'
import { CenteredContentLayout } from '../components/layouts/centered-content-layout'
import { RequireAuth } from '../components/security/authorisation'
import { About } from './about'
import { routes as dossierRoutes } from './dossier'
import { routes as adminRoutes } from './admin'
import { routes as masterDataRoutes } from './master-data'
import { Profile } from './profile'
import { Login } from '../components/security/login'
import { EmailVerification } from './email-verification'
import { Row } from 'react-bootstrap'

export const Home = () => (
  <CenteredContentLayout>
    <Row>
      <div className="col-sm-8 col-md-6 col-lg-4">
        <a href="http://www.feax.be/">
          <img src="../../feax.svg" alt="Feax" style={{ width: '100%' }} />
        </a>
      </div>
    </Row>
  </CenteredContentLayout>
)

export const routes: RouteObject[] = [
  { path: 'login', element: <Login /> },
  { path: 'email-verification', element: <EmailVerification /> },
  {
    // everything else requires auth
    path: '/',
    element: (
      <RequireAuth>
        <Outlet />
      </RequireAuth>
    ),
    children: [
      { index: true, element: <Home /> },
      { path: 'about', element: <About /> },
      { path: 'profile', element: <Profile /> },
      ...dossierRoutes,
      ...masterDataRoutes,
      ...adminRoutes,
    ],
  },
]
