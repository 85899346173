import { Asset, Value } from '../generated'
import { getAssetValue, getLiquidValue } from '../business-logic/asset'
import { translate as t } from '../services/translation'

export function moneyFmt(
  value: Asset | Value | number | undefined,
  date: string | undefined = undefined,
  liquid = false
): string {
  if (!value) {
    return '0.00'
  }
  let amount: number
  if (typeof value === 'number') {
    amount = value
  } else if ('value' in value) {
    amount = value.value || 0
  } else {
    amount = (liquid ? getLiquidValue(value, date!)?.value : getAssetValue(value, date)?.value) || 0
  }

  return amount.toLocaleString('nl', {
    useGrouping: true,
    maximumFractionDigits: liquid ? 0 : 2,
    minimumFractionDigits: liquid ? 0 : 2,
  })
}

export function dateFmt(isoDate: string | undefined) {
  return isoDate
    ? `${isoDate.substring(8, 10)}/${isoDate.substring(5, 7)}/${isoDate.substring(0, 4)}`
    : ''
}

export function validFromFmt(
  value: Asset | Value | undefined,
  date: string | undefined = undefined
): string {
  if (!value) {
    return ''
  }
  let d: string | undefined
  if ('validFrom' in value) {
    d = value.validFrom
  } else {
    d = getAssetValue(value, date)?.validFrom
  }

  return dateFmt(d)
}

export function numberFmt(value: number | undefined, fractionDigits = 0) {
  if (value === undefined) {
    return '-'
  }
  return value.toLocaleString('nl', {
    maximumFractionDigits: fractionDigits,
    minimumFractionDigits: fractionDigits,
  })
}

export function percentageFmt(value: number | undefined, sign = false) {
  if (value === undefined) {
    return '-'
  }
  const percentage = numberFmt(value * 100, 1)
  if (sign) {
    return (value && value < 0 ? '' : '+') + percentage + '%'
  }
  return percentage === '-0,0' ? '0.0%' : percentage + '%'
}

export function diffFmt(value: number | undefined) {
  if (value === undefined) {
    return '-'
  }
  return (
    (value * 100).toLocaleString('nl', {
      maximumFractionDigits: 1,
      minimumFractionDigits: 1,
      signDisplay: 'always',
    }) + '%'
  )
}

export function totalFmt(value: number | undefined) {
  return numberFmt(value)
}

export function booleanFmt(value) {
  return t(!value ? 'boolean.false' : 'boolean.true')
}
