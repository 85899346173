import { Asset, AssetTypeEnum, InstitutionData, Value } from '../generated'
import { addYears, findNearestWithinYear, getYear, todayIso } from '../util/date-util'
import { dateFmt } from '../util/formatters'
import { translate as t } from '../services/translation'
import { zip } from 'lodash'
import { calculateCombinedAnnualInterestRate } from './interest-rate'

/**
 * Get an asset's Value that is valid at the given date
 * @param asset
 * @param date
 */
export function getAssetValue(asset: Asset, date?: string): Value | undefined {
  const values = asset.values || []
  if (date) {
    for (let value of values) {
      if (value.validFrom <= date) {
        return value
      }
    }
    return undefined
  }
  return values[0]
}

export function getNearestValidFrom(asset: Asset, yearsOffset: number): string | undefined {
  const values = asset.values || []
  const now = todayIso()
  let currentValidFrom: string | undefined
  let targetDate: string | undefined
  let nearestBefore: string | undefined
  let nearestAfter: string | undefined
  for (let value of values) {
    if (!currentValidFrom && value.validFrom <= now) {
      currentValidFrom = value.validFrom
      targetDate = addYears(currentValidFrom, yearsOffset)
    }
    if (targetDate && value.validFrom >= targetDate) {
      nearestBefore = value.validFrom
    }
    if (targetDate && value.validFrom <= targetDate) {
      nearestAfter = value.validFrom
      break
    }
  }
  return targetDate ? findNearestWithinYear(targetDate, nearestBefore, nearestAfter) : undefined
}

export function getMinimumDuration(asset: Asset): number {
  if (
    asset.type === AssetTypeEnum.T21 &&
    (!asset.deathInsuranceCoverage130 || !asset.product.minimumDuration)
  ) {
    return 97
  }
  return asset.product?.minimumDuration || 0
}

export function isPastMinimumDuration(asset: Asset, date: string): boolean {
  if (asset.startDate) {
    const minimumDuration = getMinimumDuration(asset)
    if (minimumDuration) {
      const minDate = new Date(asset.startDate)
      minDate.setMonth(minDate.getMonth() + minimumDuration)
      return minDate < new Date(date)
    }
  }
  return false
}

/**
 * Get the liquid value (the amount that is immediately available) of an asset at the given date.
 * @param asset
 * @param date
 */
export function getLiquidValue(asset: Asset, date: string): Value | undefined {
  if (asset.frozen) {
    return { validFrom: date, value: 0 }
  }
  if (
    (asset.endDate && asset.endDate <= date) ||
    (asset.type === AssetTypeEnum.Bc && asset.product.riskProfile === 0)
  ) {
    return getAssetValue(asset, date) || { validFrom: date, value: 0 }
  }
  if (asset.type === AssetTypeEnum.T21 || asset.type === AssetTypeEnum.T26) {
    if (isPastMinimumDuration(asset, date)) {
      return getAssetValue(asset, date) || { validFrom: date, value: 0 }
    }
    if (
      (asset.product.maxEarlyWithdrawalPercentage || asset.product.maxEarlyWithdrawal) &&
      (asset.type !== AssetTypeEnum.T21 || asset.deathInsuranceCoverage130)
    ) {
      let maxAmount = getAssetValue(asset, date)?.value || 0
      if (asset.product.maxEarlyWithdrawalPercentage) {
        maxAmount = (maxAmount * asset.product.maxEarlyWithdrawalPercentage) / 100
      }
      if (asset.product.maxEarlyWithdrawal) {
        maxAmount = Math.min(maxAmount, asset.product.maxEarlyWithdrawal)
      }
      return { validFrom: date, value: maxAmount }
    }
  }
  return { validFrom: date, value: 0 }
}

/**
 * Assets with the same reference or institution
 */
export class AssetGroup {
  assets: Asset[] = []
  reference?: string
  institution?: InstitutionData
  startDate?: string
  show?: boolean
  total = 0
}

/**
 * Group assets based on reference or institution.
 * Grouping on reference has higher priority than grouping on institution.
 * @param assets
 * @param date the date used for calculating the groups total value
 * @param liquidValue whether to use the asset's amount at the given date, or the liquid (immediately available) amount */
export function groupAssets(assets: Asset[], date: string, liquidValue = false): AssetGroup[] {
  const groups: AssetGroup[] = []
  assets = [...assets].sort((a, b) => a.index - b.index)
  assets.forEach((a) => addAsset(groups, a, date, liquidValue))
  groups.sort((a, b) => a.assets[0].index - b.assets[0].index)
  for (let group of groups) {
    if (group.reference) {
      group.startDate = group.assets
        .map((a) => a.startDate)
        .filter((d) => d)
        .sort()[0]
    }
    group.show = group.assets.some((a) =>
      liquidValue ? getLiquidValue(a, date)?.value : getAssetValue(a, date)?.value
    )
  }
  return groups
}

/**
 * Add an asset to the asset group. This can result in a new group to be created if there is already an asset
 * with the same reference or institution as the new asset.
 * Grouping on reference has higher priority than grouping on institution.
 * @param groups
 * @param asset
 * @param date the date used for calculating the groups total value
 * @param liquidValue whether to use the asset's amount at the given date, or the liquid (immediately available) amount
 */
export function addAsset(
  groups: AssetGroup[],
  asset: Asset,
  date: string,
  liquidValue = false
): AssetGroup[] {
  let destination = groups.find(
    (group) =>
      group.reference &&
      group.institution?.id === asset.institution.id &&
      group.reference === asset.reference
  )
  if (!destination) {
    destination = groups.find(
      (group) => !group.reference && group.institution?.id === asset.institution.id
    )
  }
  if (destination) {
    if (!destination.reference) {
      const assetWithSameReference = destination.assets.find(
        (a) => asset.reference && asset.reference === a.reference
      )
      if (assetWithSameReference) {
        if (destination.assets.length > 1) {
          destination.assets = destination.assets.filter((it) => it !== assetWithSameReference)
          const sameRefValue = liquidValue
            ? getLiquidValue(assetWithSameReference, date)
            : getAssetValue(assetWithSameReference, date)
          destination.total -= sameRefValue?.value || 0

          // create new destination with the asset with the same reference
          destination = {
            assets: [assetWithSameReference],
            institution: assetWithSameReference.institution,
            reference: assetWithSameReference.reference,
            total: sameRefValue?.value || 0,
          }
          groups.push(destination)
        } else {
          destination.reference = assetWithSameReference.reference
        }
      }
    }
  } else {
    destination = {
      assets: [],
      institution: asset.institution,
      total: 0,
    }
    groups.push(destination)
  }
  destination.assets.push(asset)
  const assetValue = liquidValue ? getLiquidValue(asset, date) : getAssetValue(asset, date)
  destination.total += assetValue?.value || 0
  return groups
}

export function getNetDeposit(asset: Asset, toDate: string, fromDate?: string): number {
  let values = asset.values || []
  if (toDate) {
    values = values?.filter((value) => value.validFrom <= toDate)
  }
  if (fromDate) {
    values = values?.filter((value) => value.validFrom >= fromDate)
  }
  return values.reduce(
    (total, value) => (value.amountChange ? total + value.amountChange : total),
    0
  )
}

export function getAssetSummary(asset: Asset, date: string, maxResults = -1): Value[] {
  if (!asset.values?.length) {
    return []
  }

  const result: Value[] = []
  let index = 0
  while (index < asset.values.length && (maxResults < 0 || result.length < maxResults)) {
    const value = asset.values[index]
    if (value.validFrom > date) {
      index++
      continue
    }
    if (!result.length) {
      result.push({ ...value })
    } else {
      let currentValue = result[result.length - 1]
      if (getYear(currentValue.validFrom) === getYear(value.validFrom)) {
        currentValue.amountChange = (currentValue.amountChange ?? 0) + (value.amountChange ?? 0)
      } else {
        result.push({ ...value })
      }
    }
    index++
  }
  return result
}

function cleanUpAmountChange(asset: Asset, assetValue: Value) {
  if (asset.values?.length) {
    const oldestValue = asset.values.at(-1)!
    if (
      assetValue.validFrom < oldestValue.validFrom &&
      oldestValue.value === oldestValue.amountChange
    ) {
      const message = t('asset.value.confirmEntryCost', oldestValue.value, oldestValue.validFrom)
      if (window.confirm(message)) {
        oldestValue.amountChange = 0
      }
    }
  }
}

export function confirmedAddOrUpdateValue(asset: Asset, assetValue: Value) {
  const assetValues = asset.values ?? []
  const originalAssetValue = assetValues.find((value) => value.validFrom === assetValue.validFrom)

  if (originalAssetValue) {
    if (
      originalAssetValue.value !== assetValue.value ||
      originalAssetValue.amountChange !== assetValue.amountChange ||
      originalAssetValue.entryCost !== assetValue.entryCost
    ) {
      const message = t(
        'asset.value.confirmOverwrite',
        asset.reference || '-',
        asset.type,
        dateFmt(assetValue.validFrom)
      )
      if (window.confirm(message)) {
        originalAssetValue.value = assetValue.value
        originalAssetValue.amountChange = assetValue.amountChange
        originalAssetValue.entryCost = assetValue.entryCost
        return true
      } else {
        return false
      }
    }
  } else {
    if (assetValues.length) {
      const previousValue = getAssetValue(asset, assetValue.validFrom)
      if (previousValue?.validFrom) {
        const changePercentage =
          assetValue.value / (previousValue.value + (assetValue.amountChange || 0))
        if (changePercentage > 1.05 || changePercentage < 0.95) {
          const message = t('asset.value.bigChange')
          if (!window.confirm(message)) {
            return false
          }
        }
      }
    }
    cleanUpAmountChange(asset, assetValue)
    assetValues.push({
      value: assetValue.value,
      validFrom: assetValue.validFrom,
      amountChange: assetValue.amountChange,
      entryCost: assetValue.entryCost,
    })
    assetValues.sort((a, b) => (a.validFrom < b.validFrom ? 1 : -1))
    asset.values = assetValues
  }
  return true
}

export class YearSummary {
  year: number = 0
  value: number = 0
  combinedDeposits: number = 0
  yearDeposits: number = 0
  yearYield: number = 0
  combinedYield: number = 0
}

export function getGroupSummary(group: AssetGroup, date: string) {
  if (!group.assets.length) {
    return {
      startDate: undefined,
      endDate: undefined,
      annualInterestRate: undefined,
      yearSummaries: [],
    }
  }
  const startDate = group.assets.map((asset) => asset.values?.at(-1)?.validFrom ?? date).sort()[0]
  const endDate = group.assets
    .map((asset) => getAssetValue(asset, date)?.validFrom ?? startDate)
    .sort()
    .at(-1)!
  const annualInterestRate = calculateCombinedAnnualInterestRate(group.assets, endDate!, startDate)
  const minYear = getYear(startDate)
  const maxYear = getYear(endDate)
  const assetSummaries = group.assets.map((asset) =>
    fillGaps((getAssetSummary(asset, date) ?? []).reverse(), minYear, maxYear)
  )
  const zippedAssetSummaries = zip(...assetSummaries)

  const yearSummaries: YearSummary[] = zippedAssetSummaries.map((values) => ({
    year: getYear(values[0]!.validFrom),
    value: values.reduce((acc, summary) => acc + summary!.value, 0),
    yearDeposits: values.reduce((acc, summary) => acc + (summary!.amountChange ?? 0), 0),
    combinedDeposits: 0,
    yearYield: 0,
    combinedYield: 0,
  }))

  yearSummaries.forEach((yearSummary, index) => {
    yearSummary.combinedDeposits = yearSummary.yearDeposits
    yearSummary.yearYield = yearSummary.value - yearSummary.yearDeposits
    yearSummary.combinedYield = yearSummary.yearYield
    if (index > 0) {
      yearSummary.yearYield = yearSummary.yearYield - yearSummaries[index - 1].value
      yearSummary.combinedDeposits =
        yearSummaries[index - 1].combinedDeposits + yearSummary.yearDeposits
      yearSummary.combinedYield = yearSummary.value - yearSummary.combinedDeposits
    }
  })
  return { startDate, endDate, annualInterestRate, yearSummaries }
}

function fillGaps(values: Value[], minYear: number, maxYear: number): Value[] {
  const result: Value[] = []
  let index = 0
  let prevValue = 0
  for (let year = minYear; year <= maxYear; year++) {
    const value = values[index]
    if (value && getYear(value.validFrom) === year) {
      result.push(value)
      prevValue = value.value
      index++
    } else {
      result.push({ validFrom: `${year}-01-01`, value: prevValue })
    }
  }
  return result
}
