import { useQuery } from 'react-query'
import { type User } from '../generated'
import { api } from '../services/api'
import { type QueryOptions } from './query-utils'

export const userQueryKeys = {
  list: ['users'],
  user: (userId?: string) => [...userQueryKeys.list, userId],
  dossierUsers: (dossierId?: string) => [...userQueryKeys.list, dossierId],
}

export const useAllUsersQuery = (options: QueryOptions<User[]> = {}) =>
  useQuery({
    queryKey: userQueryKeys.list,
    queryFn: () => api.usersList().then((r) => r.data),
    ...options,
  })

export const useUserQuery = (userName?: string, options: QueryOptions<User> = {}) =>
  useQuery({
    queryKey: userQueryKeys.user(userName),
    queryFn: async ({ queryKey: [_key, userName] }) => api.usersGet(userName!).then((r) => r.data),
    enabled: !!userName,
    refetchOnWindowFocus: false,
    ...options,
  })

export const useListDossierUsersQuery = (dossierId?: string, options: QueryOptions<User[]> = {}) =>
  useQuery({
    queryKey: userQueryKeys.dossierUsers(dossierId),
    queryFn: async ({ queryKey: [_key, dossierId] }) => {
      return api.usersListDossierUsers(dossierId!).then((r) => r.data)
    },
    enabled: !!dossierId,
    ...options,
  })
