import { DateSelect } from '../../components/report/date-select'
import { translate as t } from '../../services/translation'
import { WalletSelect } from '../../components/report/wallet-select'
import { useParams } from 'react-router-dom'
import { useDossierQuery } from '../../queries/dossier-queries'
import { useState } from 'react'
import { previousYearEnd, tenYearsAgo, todayIso } from '../../util/date-util'
import { api } from '../../services/api'
import { getAssetValue } from '../../business-logic/asset'
import { dateFmt, diffFmt, percentageFmt, totalFmt } from '../../util/formatters'
import { Bar } from 'react-chartjs-2'
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from 'chart.js'
import { IfStaff } from '../../components/security/authorisation'
import { Col, Row, Table } from 'react-bootstrap'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

interface DataPoint {
  date: string
  total: number
  year: string
  diff?: number
}

const YEARS_TO_SHOW = 10

function getYearEndsAfter(startDate) {
  const dates = [todayIso()]
  while (dates.length < YEARS_TO_SHOW && previousYearEnd(dates[dates.length - 1]) >= startDate) {
    dates.push(previousYearEnd(dates[dates.length - 1]))
  }
  return dates.reverse()
}

export const Evolution = () => {
  const { id } = useParams()
  const { data: dossier } = useDossierQuery(id, { refetchOnMount: false })
  const dossierWallets = dossier?.wallets || []
  const [evolutionStartDate, setEvolutionStartDate] = useState(
    dossier?.evolutionStartDate || tenYearsAgo
  )
  const [wallets, setWallets] = useState(dossierWallets)

  async function updateStartDate(date) {
    dossier!.evolutionStartDate = date
    const response = await api.dossierInfosUpdate(dossier!.id!, dossier!)
    Object.assign(dossier!, response.data)
    setEvolutionStartDate(date)
  }

  const data: DataPoint[] = []
  for (let date of getYearEndsAfter(evolutionStartDate)) {
    let total = 0
    for (let wallet of wallets) {
      for (let asset of wallet.assets || []) {
        total += getAssetValue(asset, date)?.value || 0
      }
    }
    data.push({ date, total, year: date.substring(0, 4) })
  }
  for (let i = 1; i < data.length; i++) {
    let previousTotal = data[i - 1].total
    data[i].diff = (data[i].total - previousTotal) / previousTotal
  }

  return (
    <>
      <div className="row row-cols-auto align-items-center mb-3">
        <IfStaff>
          <DateSelect
            date={evolutionStartDate}
            setDate={updateStartDate}
            label="dossier.evolutionStart"
          />
        </IfStaff>
        <WalletSelect dossier={dossier} selectedWallets={wallets} setWallets={setWallets} />
      </div>
      <h2>{t('dossier.evolution')}</h2>
      <Table responsive className="evolution">
        <thead>
          <tr>
            <th>{t('date')}</th>
            <th className="text-end">{t('amount')}</th>
            <th className="text-end">{t('difference')}</th>
          </tr>
        </thead>
        <tbody>
          {data
            .slice()
            .reverse()
            .map((dataPoint, index) => (
              <tr key={index}>
                <td>{dateFmt(dataPoint.date)}</td>
                <td className="text-end">{totalFmt(dataPoint.total)}</td>
                <td className="text-end">
                  {index < data.length - 1 && percentageFmt(dataPoint.diff, true)}
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
      <Row>
        <Col lg={8} xl={8} xxl={8}>
          <Bar
            data={{
              labels: data.map((d) => d.year),
              datasets: [
                { data: data.map((d) => Math.round(d.total)), backgroundColor: 'steelblue' },
              ],
            }}
            options={{
              plugins: {
                legend: { display: false },
                tooltip: {
                  callbacks: {
                    footer: (items) => diffFmt(data[items[0].dataIndex].diff),
                  },
                },
              },
            }}
          />
        </Col>
      </Row>
    </>
  )
}
