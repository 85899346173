import { Container } from 'react-bootstrap'
import { HeaderNavbar } from '../navs/header-navbar'

export const MainLayout = ({ children }) => (
  <div>
    <HeaderNavbar />
    <Container fluid className="py-4" as="main">
      {children}
    </Container>
  </div>
)
