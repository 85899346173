import { getAssetValue } from './asset'

export const CASH = 'CASH'
export const DEFENSIVE = 'DEFENSIVE'
export const NEUTRAL = 'NEUTRAL'
export const DYNAMIC = 'DYNAMIC'

export function riskCategory(riskProfile: number): string {
  if (riskProfile < 1) {
    return CASH
  }
  if (riskProfile < 4) {
    return DEFENSIVE
  }
  if (riskProfile < 6) {
    return NEUTRAL
  }
  return DYNAMIC
}

const maxProfileRisk = {
  CONSERVATIVE: 3,
  DEFENSIVE: 5,
  BALANCED: 6,
  DYNAMIC: 7,
  HIGHLY_DYNAMIC: 7,
}
const maxWeightedProfileRisk = {
  CONSERVATIVE: 3,
  DEFENSIVE: 4,
  BALANCED: 5,
  DYNAMIC: 6,
  HIGHLY_DYNAMIC: 7,
}

export function getRiskAnalysis(dossier, wallets, date) {
  let grandTotal = 0
  let weightedTotal = 0
  const maxRiskProfile = maxProfileRisk[dossier.profile]
  const riskProfiles = [
    { risk: 7, total: 0, color: '#B33434', percentage: 0, height: 0, padding: 0, risky: false },
    { risk: 6, total: 0, color: '#ff8080', percentage: 0, height: 0, padding: 0, risky: false },
    { risk: 5, total: 0, color: '#2A882A', percentage: 0, height: 0, padding: 0, risky: false },
    { risk: 4, total: 0, color: '#90ee90', percentage: 0, height: 0, padding: 0, risky: false },
    { risk: 3, total: 0, color: '#0073B3', percentage: 0, height: 0, padding: 0, risky: false },
    { risk: 2, total: 0, color: '#00bfff', percentage: 0, height: 0, padding: 0, risky: false },
    { risk: 1, total: 0, color: '#1AD9FF', percentage: 0, height: 0, padding: 0, risky: false },
  ]

  for (let wallet of wallets!) {
    for (let asset of wallet.assets ?? []) {
      const assetValue = getAssetValue(asset, date)?.value || 0
      const riskProfile = riskProfiles[7 - Math.max(1, asset.product.riskProfile!)]
      riskProfile.total += assetValue
      grandTotal += assetValue
    }
  }

  for (let riskProfile of riskProfiles) {
    weightedTotal = weightedTotal + riskProfile.total * riskProfile.risk
    riskProfile.percentage = riskProfile.total / grandTotal
    riskProfile.height = Math.max(2, Math.ceil(riskProfile.percentage * 7 * 70)) // 7 blocks * 70px
    riskProfile.padding = Math.max(0, Math.floor(riskProfile.height / 2 - 14))
    riskProfile.risky = riskProfile.risk > maxRiskProfile
  }

  const weightedRiskProfile = weightedTotal / grandTotal
  const risky = weightedRiskProfile > maxWeightedProfileRisk[dossier!.profile]
  return { weightedRiskProfile, risky, total: grandTotal, riskProfiles }
}

interface NamedResult {
  name: string
  total?: number
  percentage?: number
}

interface ResultGroup {
  groups: { [name: string]: NamedResult }
  total?: number
  percentage?: number
}

export function getCategoryAnalysis(wallets, date) {
  let grandTotal = 0
  const institutionsMap: ResultGroup = { groups: {} }
  const categoriesMap: { [name: string]: ResultGroup } = {
    CASH: { groups: {}, total: 0 },
    DEFENSIVE: { groups: {}, total: 0 },
    NEUTRAL: { groups: {}, total: 0 },
    DYNAMIC: { groups: {}, total: 0 },
  }

  for (let wallet of wallets) {
    for (let asset of wallet.assets || []) {
      const assetValue = getAssetValue(asset, date)?.value || 0
      const institution = asset.product.assetManager?.name || asset.institution.name!
      const institutionTotal = institutionsMap[institution]?.total || 0
      institutionsMap[institution] = { name: institution, total: institutionTotal + assetValue }

      const categoryEntry = categoriesMap[riskCategory(asset.product.riskProfile!)]
      const categoryInstitutions = categoryEntry.groups
      const categoryInstitutionTotal = categoryInstitutions[institution]?.total || 0
      categoryInstitutions[institution] = {
        name: institution,
        total: categoryInstitutionTotal + assetValue,
      }
      categoryEntry.total = (categoryEntry.total || 0) + assetValue

      grandTotal = grandTotal + assetValue
    }
  }

  const institutions: NamedResult[] = Object.values(institutionsMap)
  for (let institution of institutions) {
    institution.percentage = institution.total! / grandTotal
  }

  for (let categoryEntry of Object.values(categoriesMap)) {
    categoryEntry.percentage = categoryEntry.total! / grandTotal
    for (let institution of Object.values(categoryEntry.groups)) {
      institution.percentage = institution.total! / categoryEntry.total!
    }
    categoryEntry['institutions'] = Object.values(categoryEntry.groups)
  }
  return { total: grandTotal, categoriesMap, institutions }
}
