import { Authenticator as AwsAuthenticator, useAuthenticator } from '@aws-amplify/ui-react'
import { AuthTokens, fetchAuthSession } from 'aws-amplify/auth'
import { createContext, useContext, useEffect, useState } from 'react'

const AuthTokensContext = createContext<AuthTokens | null>(null)

const AuthTokensProvider = ({ children }) => {
  const [tokens, setTokens] = useState<AuthTokens | null>(null)

  const { authStatus } = useAuthenticator((s) => [s.authStatus])

  useEffect(() => {
    const updateTokens = async () => {
      switch (authStatus) {
        case 'authenticated':
          const session = await fetchAuthSession()
          if (session.tokens) {
            setTokens((prevState) => {
              // update only if one token has changed
              if (
                prevState?.accessToken.toString() !== session.tokens?.accessToken.toString() ||
                prevState?.idToken?.toString() !== session.tokens?.idToken?.toString()
              ) {
                return session.tokens!
              }
              return prevState
            })
          }
          break
        case 'unauthenticated':
          setTokens(null)
          break
        default:
          console.log('Configuring aws amplify...')
      }
    }
    updateTokens()
  }, [authStatus])

  return <AuthTokensContext.Provider value={tokens}>{children}</AuthTokensContext.Provider>
}

export const AuthenticatorProvider = ({ children }) => (
  <AwsAuthenticator.Provider>
    <AuthTokensProvider>{children}</AuthTokensProvider>
  </AwsAuthenticator.Provider>
)

export const useAuthTokens = () => useContext(AuthTokensContext)
