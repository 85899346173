import { FormField } from '../form'

export const CreditContractForm = (_) => {
  return (
    <>
      <FormField name="contractId" inputProps={{ maxLength: '100' }} />
      <FormField name="lender" inputProps={{ maxLength: '100' }} />
      <FormField
        name="interestRate"
        inputProps={{ type: 'number', min: 0, step: 0.01 }}
        registerOptions={{ min: 0, valueAsNumber: true }}
      />
      <FormField name="variability" inputProps={{ maxLength: '100' }} />
      <FormField
        name="monthlyAmount"
        inputProps={{ type: 'number', min: 0, step: 0.01 }}
        registerOptions={{ min: 0, valueAsNumber: true }}
      />
      <FormField
        name="startAmount"
        inputProps={{ type: 'number', min: 0, step: 0.01 }}
        registerOptions={{ min: 0, valueAsNumber: true }}
      />
      <FormField name="remarks" inputProps={{ as: 'textarea', rows: 5, maxLength: 512 }} />
    </>
  )
}
