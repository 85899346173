import { I18n } from 'aws-amplify/utils'
import vocabularies from '../generated/vocabularies.json'

export function translate(msgKey: string, ...args): string {
  let msg: string = I18n.get(msgKey)
  for (let i = 0; i < args.length; i++) {
    msg = msg.replace('{' + i + '}', args[i])
  }
  return msg
}

export function containsTranslation(msgKey: string) {
  return !!vocabularies.nl[msgKey]
}

export type MakeTranslationKeyArgs = {
  name: string
  translationKey?: string
  translationPath?: string | null
}

export function makeTranslationKey({
  translationKey,
  translationPath,
  name,
}: MakeTranslationKeyArgs): string {
  let _translationKey
  if (translationKey) {
    _translationKey = translationKey
  } else if (translationPath) {
    _translationKey = `${translationPath}.${name}`
  } else {
    _translationKey = name
  }
  return _translationKey
}
