import { useParams } from 'react-router-dom'
import { useDossierQuery } from '../../queries/dossier-queries'
import { todayIso } from '../../util/date-util'
import { DateSelect } from '../../components/report/date-select'
import { useState } from 'react'
import { WalletSelect } from '../../components/report/wallet-select'
import { RiskProfile } from '../../components/report/risk-profile'

export const RiskProfileTab = () => {
  const { id } = useParams()
  const { data: dossier } = useDossierQuery(id, { refetchOnMount: false })

  const [date, setDate] = useState(todayIso())
  const [wallets, setWallets] = useState(dossier?.wallets || [])

  return (
    <>
      <div className="row row-cols-auto align-items-center mb-3">
        <DateSelect date={date} setDate={setDate} />
        <WalletSelect dossier={dossier} selectedWallets={wallets} setWallets={setWallets} />
      </div>
      <RiskProfile dossier={dossier} wallets={wallets} date={date} />
    </>
  )
}
