import GitInfo from 'react-git-info/macro'
import { api } from '../services/api'
import { useEffect, useState } from 'react'
import { CenteredContentLayout } from '../components/layouts/centered-content-layout'
import { IfAdmin } from '../components/security/authorisation'

export function About() {
  const gitInfo = GitInfo()

  const initial: any = {}
  const [apiInfo, setApiInfo] = useState(initial)

  useEffect(() => {
    api.info().then((value) => setApiInfo(value.data))
  }, [])

  return (
    <CenteredContentLayout>
      <h1>About</h1>
      <h2>Web Application</h2>
      <ul className="about-list">
        <li>
          <strong>Version:</strong> {process.env.REACT_APP_VERSION}
        </li>
        <li>
          <strong>Git date:</strong> {gitInfo.commit.date}
        </li>
        <IfAdmin>
          <li>
            <strong>Application:</strong> {process.env.REACT_APP_NAME}
          </li>
          <li>
            <strong>Git branch:</strong> {gitInfo.branch}
          </li>
          <li>
            <strong>Git revision:</strong> {gitInfo.commit.hash}
          </li>
        </IfAdmin>
      </ul>
      <h2>API</h2>
      <ul className="about-list">
        <li>
          <strong>Environment:</strong> {apiInfo['environment']}
        </li>
        <li>
          <strong>Built date:</strong> {apiInfo['Built-Date']}
        </li>
        <IfAdmin>
          <li>
            <strong>Git branch:</strong> {apiInfo['Git-Branch']}
          </li>
          <li>
            <strong>Git revision:</strong> {apiInfo['Git-Commit']}
          </li>
        </IfAdmin>
      </ul>
    </CenteredContentLayout>
  )
}
