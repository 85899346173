import { Authenticator, useAuthenticator } from '@aws-amplify/ui-react'
import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

/**
 * Example inspired from https://ui.docs.amplify.aws/react/guides/auth-protected#try-it-out
 */
export const Login = () => {
  const { route } = useAuthenticator((context) => [context.route])
  const location = useLocation()
  const navigate = useNavigate()
  let from = location.state?.from
  if (!from || from.pathname.endsWith('/login')) {
    from = '/'
  }

  useEffect(() => {
    if (route === 'authenticated') {
      navigate(from, { replace: true })
    }
  }, [route, navigate, from])

  return <Authenticator hideSignUp={true} />
}
