import { getAssetValue } from './asset'
import { Asset } from '../generated'
import { MILLIS_IN_YEAR } from '../util/date-util'

const PRECISION = 0.0004

/**
 * An amount deposited on a (fictive) savings account and how long it was eligible for interest (in years)
 */
class Saving {
  amount: number = 0
  duration: number = 0
}

/**
 * An interval on a mathematical graph (couple of x/y values)
 */
class Interval {
  x0: number = 0
  y0: number = 0
  x1: number = 0
  y1: number = 0
}

type Calculation = (x: number) => number

/**
 * Calculate the annual interest rate of a savings account that would result in the same end amount,
 * given the deposits of the asset.
 * @param asset
 * @param toDate the date of the end amount
 * @param fromDate optional start date for the calculation
 */
export function calculateAnnualInterestRate(
  asset: Asset,
  toDate: string,
  fromDate?: string
): number | undefined {
  if (
    asset.values &&
    asset.values.length > 1 &&
    (!fromDate || fromDate >= asset.values.at(-1)!.validFrom)
  ) {
    const newestValue = getAssetValue(asset, toDate)
    if (newestValue) {
      if (!fromDate) {
        fromDate = asset.values.at(-1)!.validFrom
      }
      if (
        toDate > fromDate &&
        new Date(toDate).getTime() - new Date(fromDate).getTime() >= MILLIS_IN_YEAR
      ) {
        const savings = getSavings(asset, fromDate, toDate)
        if (savings.length) {
          return calculateInterestRate(savings, newestValue.value)
        }
      }
    }
  }
  return undefined
}

/**
 * Calculate the equivalent annual interest rate for the combined assets.
 * @param assets
 * @param toDate the date of the end amount
 * @param fromDate optional start date for the calculation
 */
export function calculateCombinedAnnualInterestRate(
  assets: Asset[],
  toDate: string,
  fromDate: string
): number | undefined {
  if (
    toDate > fromDate &&
    new Date(toDate).getTime() - new Date(fromDate).getTime() >= MILLIS_IN_YEAR
  ) {
    const savings = assets.flatMap((asset) => getSavings(asset, fromDate, toDate))
    const endAmount = assets
      .map((asset) => getAssetValue(asset, toDate)?.value || 0)
      .reduce((total, value) => total + value, 0)
    if (savings.length) {
      return calculateInterestRate(savings, endAmount)
    }
  }
  return undefined
}

/**
 * Get the (fictive) savings (deposits + eligible interest periods) for an asset.
 * @param asset
 * @param from
 * @param to
 */
function getSavings(asset: Asset, from: string, to: string): Saving[] {
  if (asset.values?.length) {
    const toTime = new Date(to).getTime()
    const values = asset.values?.filter(
      (value) => value.amountChange && value.validFrom >= from && value.validFrom <= to
    )!
    const firstAssetValue = asset.values.at(-1)!
    if (firstAssetValue.validFrom >= from) {
      // if the amountChange of the first asset value is not set, and within the calculation period
      // we need to add its value as amountChange
      if (!firstAssetValue.amountChange) {
        values.push({
          validFrom: from,
          amountChange: firstAssetValue.value,
          value: firstAssetValue.value,
        })
      }
    } else {
      // use the value at the start of the calculation period as amountChange
      if (values.length && values.at(-1)!.validFrom === from) {
        // remove the first asset value if it is exactly at the start of the calculation period
        values.pop()
      }
      // add the value at the start of the calculation period as amountChange
      values.push({ validFrom: from, amountChange: getAssetValue(asset, from)?.value, value: 0 })
    }

    return values.map((value) => ({
      amount: value.amountChange!,
      duration: (toTime - new Date(value.validFrom).getTime()) / MILLIS_IN_YEAR,
    }))
  }
  return []
}

function calculateInterestRate(savings: Saving[], actualEndAmount: number): number {
  const actualMinusCalculatedEndAmountFn = (interestRate: number): number => {
    return actualEndAmount - accumulatedInterests(savings, interestRate)
  }
  let interval = getIntervalWithZeroPoint(actualMinusCalculatedEndAmountFn)

  let maxIterations = 20
  while (Math.abs(interval.x0 - interval.x1) > PRECISION && maxIterations) {
    interval = reduceZeroPointInterval(interval, actualMinusCalculatedEndAmountFn)
    maxIterations--
  }
  return maxIterations ? interval.x0 : 0
}

function accumulateInterest(saving: Saving, interestRate: number): number {
  return saving.amount * Math.pow(1 + interestRate, saving.duration)
}

function accumulatedInterests(savings: Saving[], r: number): number {
  return savings.reduce((total, saving) => total + accumulateInterest(saving, r), 0)
}

/**
 * An interval a function graph: y0 = f(x0), y1 = f(x1)
 */

/**
 *  Get an interval that contains the zero point of a decreasing mathematical function
 * @param fn decreasing mathematical function
 */
function getIntervalWithZeroPoint(fn: Calculation): Interval {
  let interval
  let maxIterations = 20
  let yZero = fn(0)
  if (yZero > 0) {
    // zero point is at positive x
    const startX1 = 0.2
    interval = { x0: 0, y0: yZero, x1: startX1, y1: fn(startX1) }
    while (interval.y1 > 0 && maxIterations) {
      // move interval to the right
      const newX1: number = interval.x1 * 2
      interval = { x0: interval.x1, y0: interval.y1, x1: newX1, y1: fn(newX1) }
      maxIterations--
    }
  } else {
    // zero point is at negative x
    const startX0 = -0.2
    interval = { x0: startX0, y0: fn(startX0), x1: 0, y1: yZero }
    while (interval.y0 < 0 && maxIterations) {
      // move interval to the left
      const newX0: number = interval.x0 * 2
      interval = { x0: newX0, y0: fn(newX0), x1: interval.x0, y1: interval.y0 }
      maxIterations--
    }
  }
  return interval
}

/**
 * Reduce the interval that contains the zero point
 * @param interval
 * @param fn decreasing mathematical function
 */
function reduceZeroPointInterval(interval: Interval, fn: Calculation): Interval {
  const middleX = (interval.x0 + interval.x1) / 2
  const middleY = fn(middleX)
  return middleY < 0
    ? { x0: interval.x0, y0: interval.y0, x1: middleX, y1: middleY }
    : { x0: middleX, y0: middleY, x1: interval.x1, y1: interval.y1 }
}
