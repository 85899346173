import { Button, Modal } from 'react-bootstrap'
import { BsSlashCircle, BsTrash } from 'react-icons/bs'
import { useNavigate, useSubmit } from 'react-router-dom'
import { translate as t } from '../../services/translation'
import { useState } from 'react'

export type DeleteDialogProps = {
  action: 'archive' | 'delete'
  entity: string
  onDelete?: () => Promise<any>
  showLabel?: boolean
  initialShow?: boolean
}

export const DeleteDialog = ({
  action,
  entity,
  onDelete,
  showLabel = true,
  initialShow = false,
}: DeleteDialogProps) => {
  const navigate = useNavigate()
  const submit = useSubmit()
  const [show, setShow] = useState(initialShow)

  const handleDelete = async () => {
    if (!!onDelete) {
      handleClose()
      await onDelete()
    } else {
      submit(null, {
        method: 'delete',
      })
    }
  }

  const handleClose = () => {
    if (!!onDelete) {
      setShow(false)
    } else {
      navigate('..')
    }
  }

  return (
    <>
      {!!onDelete && (
        <Button
          title={t('button.delete')}
          variant="danger"
          className="icon-wrapper"
          onClick={() => setShow(true)}
        >
          <BsTrash />
          {showLabel && <span>{t('button.delete')}</span>}
        </Button>
      )}
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>{t(`button.${action}`)}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{t(`${action}.confirm.message`, t(entity))}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" className="icon-wrapper" onClick={handleClose}>
            <BsSlashCircle />
            <span>{t('button.cancel')}</span>
          </Button>
          <Button variant="danger" className="icon-wrapper" onClick={handleDelete}>
            <BsTrash />
            <span>{t(`button.${action}`)}</span>
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
