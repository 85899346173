import {
  fetchMFAPreference,
  setUpTOTP,
  updateMFAPreference,
  verifyTOTPSetup,
} from 'aws-amplify/auth'
import { QRCodeCanvas } from 'qrcode.react'
import { useEffect, useState } from 'react'
import { Button, Form, InputGroup, Modal } from 'react-bootstrap'
import { BsShieldLock } from 'react-icons/bs'
import { translate as t } from '../../services/translation'

const NO_MFA = 'DISABLED'

export function MfaControl({ user }) {
  const [preferredMFA, setPreferredMFA] = useState(NO_MFA)
  const [mfaCode, setMfaCode] = useState('')
  const [token, setToken] = useState('')
  const [valid, setValid] = useState(true)

  useEffect(() => {
    if (!mfaCode) {
      fetchMFAPreference().then((mfaPrefs) => {
        const value = mfaPrefs.preferred?.toString() ?? NO_MFA
        if (preferredMFA !== value) {
          setPreferredMFA(value)
        }
      })
    }
  }, [preferredMFA, mfaCode])

  const toggleMfa = async () => {
    if (preferredMFA === NO_MFA) {
      const code = await setUpTOTP()
      setMfaCode(code.sharedSecret)
    } else {
      await updateMFAPreference({ totp: NO_MFA, sms: NO_MFA })
      setPreferredMFA(NO_MFA)
    }
  }

  const validateToken = async () => {
    try {
      await verifyTOTPSetup({ code: token })
      await updateMFAPreference({ totp: 'PREFERRED', sms: NO_MFA })
      setMfaCode('')
      setToken('')
    } catch (e) {
      setValid(false)
    }
  }

  return (
    <>
      <InputGroup className="mb-3">
        <Form.Check
          type="switch"
          label={t('user.multiFactorTitle')}
          checked={preferredMFA !== NO_MFA}
          onChange={toggleMfa}
        />
      </InputGroup>
      <Modal centered show={!!mfaCode} onHide={() => setMfaCode('')} size="lg">
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">{t('user.multiFactorTitle')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ol>
            <li>
              <p>Installeer een app die authenticatie in twee stappen ondersteunt.</p>
              <p>
                Voor Android:
                <a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2">
                  Google Authenticator
                </a>
                of{' '}
                <a href="https://play.google.com/store/apps/details?id=org.fedorahosted.freeotp">
                  FreeOTP Authenticator
                </a>
              </p>
              <p>
                Voor iPhone, iPod touch of iPad:{' '}
                <a href="https://itunes.apple.com/nl/app/google-authenticator/id388497605?mt=8">
                  Google Authenticator
                </a>
                of{' '}
                <a href="https://itunes.apple.com/us/app/freeotp/id872559395">
                  FreeOTP Authenticator
                </a>
              </p>
            </li>
            <li>
              <p>Scan de QR-code met de geïnstalleerde app of geef de code manueel in:</p>
              <p>
                <QRCodeCanvas
                  value={`otpauth://totp/AWSCognito:${user.username}?secret=${mfaCode}&issuer=Vermogensoverzicht`}
                />
              </p>
              <p>Code: {mfaCode}</p>
            </li>
            <li>
              <p>Vul het eenmalig wachtwoord in dat de app genereert:</p>
              <InputGroup className="mb-3">
                <input
                  className="form-control"
                  value={token}
                  maxLength={6}
                  onChange={(e) => setToken(e.target.value)}
                />
                <Button className="me-2 icon-wrapper" onClick={validateToken}>
                  <BsShieldLock />
                  <span>{t('user.validateToken')}</span>
                </Button>
              </InputGroup>
              {!valid && <p className="text-danger">Ongeldig wachtwoord</p>}
            </li>
          </ol>
        </Modal.Body>
      </Modal>
    </>
  )
}
