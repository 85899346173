import { PropsWithChildren } from 'react'
import { Col, Row } from 'react-bootstrap'
import { MakeTranslationKeyArgs } from '../../services/translation'
import {
  type TranslationOptions,
  TranslationProvider,
  useTranslation,
} from '../translation/translation-provider'

export const Dl = ({ children, translationPath }: PropsWithChildren<TranslationOptions>) => (
  <TranslationProvider translationPath={translationPath}>
    <Row as="dl">{children}</Row>
  </TranslationProvider>
)

export const Dt = ({ children }) => (
  <Col md={4} className="mb-2 text-end" as="dt">
    {children}
  </Col>
)

export const Dd = ({ children }) => (
  <Col md={8} className="mb-2 text-left" as="dd">
    {children}
  </Col>
)

export interface DetailFieldProps extends MakeTranslationKeyArgs {
  name: string
  children: React.ReactNode
}

export const DetailField = ({
  translationKey,
  translationPath,
  name,
  children,
}: DetailFieldProps) => {
  const { t } = useTranslation({
    // do not use a translationPath if translationKey is provided
    translationPath: translationKey ? null : translationPath,
  })
  return (
    <>
      <Dt>{t(translationKey ?? name)}</Dt>
      <Dd>{children}</Dd>
    </>
  )
}
