import { translate as t } from '../../services/translation'
import AccordionItem from 'react-bootstrap/AccordionItem'
import AccordionHeader from 'react-bootstrap/AccordionHeader'
import AccordionBody from 'react-bootstrap/AccordionBody'

export const BCContractDetails = ({ asset }) => {
  return (
    <AccordionItem eventKey="contract">
      <AccordionHeader>{t('asset.remarks')}</AccordionHeader>
      <AccordionBody>
        <span className="pre">{asset.remarks}</span>
      </AccordionBody>
    </AccordionItem>
  )
}
