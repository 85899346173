import { BsGearWide, BsPeople } from 'react-icons/bs'
import { Link } from 'react-router-dom'
import { NavDropdown } from './nav-dropdown'
import { translate as t } from '../../services/translation'

export const AdminMenu = (props) => {
  if (!props.user) {
    return null
  }
  return (
    <NavDropdown icon={<BsGearWide />} title="Administrator" menuVariant="dark" menuAlign="end">
      <NavDropdown.Item className="icon-wrapper" to="/admin/users" as={Link}>
        <BsPeople />
        <span>{t('users')}</span>
      </NavDropdown.Item>
      <NavDropdown.Item to="/admin/baloise-upload" as={Link}>
        <span>Baloise CSV upload</span>
      </NavDropdown.Item>
      <NavDropdown.Item to="/admin/excel-download" as={Link}>
        <span>Excel download</span>
      </NavDropdown.Item>
    </NavDropdown>
  )
}
