import { AssetTypeEnum } from '../generated'
import { LifeNonFiscalContractForm } from '../components/asset/life-non-fiscal-contract-form'
import { T26ContractForm } from '../components/asset/T26-contract-form'
import { LifeFiscalContractForm } from '../components/asset/life-fiscal-contract-form'
import { BPT21T23ContractForm } from '../components/asset/BP-T21-T23-contract-form'
import React from 'react'
import { BCContractForm } from '../components/asset/BC-contract-form'
import { BPBCContractForm } from '../components/asset/BP-BC-contract-form'
import { BCPSContractForm } from '../components/asset/BC-PS-contract-form'
import { OVLContractForm } from '../components/asset/OVL-contract-form'
import { RevenueContractForm } from '../components/asset/revenue-contract-form'
import { RealEstateContractForm } from '../components/asset/real-estate-contract-form'
import { CreditContractForm } from '../components/asset/credit-contract-form'
import { LifeNonFiscalContractDetails } from '../components/asset/life-non-fiscal-contract-details'
import { T26ContractDetails } from '../components/asset/T26-contract-details'
import { LifeFiscalContractDetails } from '../components/asset/life-fiscal-contract-details'
import { BPT21T23ContractDetails } from '../components/asset/BP-T21-T23-contract-details'
import { BPBCContractDetails } from '../components/asset/BP-BC-contract-details'
import { BCContractDetails } from '../components/asset/BC-contract-details'
import { BCPSContractDetails } from '../components/asset/BC-PS-contract-details'
import { OVLContractDetails } from '../components/asset/OVL-contract-details'
import { RevenueContractDetails } from '../components/asset/revenue-contract-details'
import { RealEstateContractDetails } from '../components/asset/real-estate-contract-details'
import { CreditContractDetails } from '../components/asset/credit-contract-details'

const contractProperties = [
  'policyHolder',
  'insured',
  'beneficiaryLife',
  'beneficiaryDeath',
  'acceptingBeneficiary',
  'deathInsuranceCoverage130',

  'gift',
  'giftConditions',

  'entryCost',
  'premiumTax',
  'premiumTaxType',

  'guaranteedReturns',

  'contractState',

  'pensionAnnualPremium',
  'pensionPeriodicity',
  'pensionEntryCost',
  'pensionIndexation',

  'deathInsuredCapital',
  'deathAnnualPremium',
  'deathPeriodicity',

  'invalidityMonthlyInterest',
  'invalidityAnnualPremium',
  'invalidityPeriodicity',
  'invalidityWait',
  'invalidityCoverageType',
  'invalidityIndexation',

  'premiumWaiverAnnualPremium',
  'premiumWaiverPeriodicity',

  'mainDueDate',
  'premiumPaymentEndDate',
  'insuranceType',
  'advanceTaken',

  'periodicity',
  'monthlyPremium',
  'annualPremium',
  'additionalWarrants',
  'monthlyAmount',
  'insuredMonthlyInterest',
  'indexation',
  'remarks',
  'annualRentalIncome',
  'contractId',
  'lender',
  'interestRate',
  'variability',
  'startAmount',
]

export function copyContract(src: Object, destination: Object) {
  for (let prop of contractProperties) {
    if (src[prop] === undefined) {
      delete destination[prop]
    } else {
      // @ts-ignore
      destination[prop] = src[prop]
    }
  }
}

export const assetTypes = {
  [AssetTypeEnum.T21]: {
    form: (fieldValues) => <LifeNonFiscalContractForm fieldValues={fieldValues} />,
    details: (asset) => <LifeNonFiscalContractDetails asset={asset} />,
    defaultOpen: ['contract'],
  },
  [AssetTypeEnum.T23]: {
    form: (fieldValues) => <LifeNonFiscalContractForm fieldValues={fieldValues} />,
    details: (asset) => <LifeNonFiscalContractDetails asset={asset} />,
    defaultOpen: ['contract'],
  },
  [AssetTypeEnum.T26]: {
    form: (fieldValues) => <T26ContractForm fieldValues={fieldValues} />,
    details: (asset) => <T26ContractDetails asset={asset} />,
    defaultOpen: ['contract'],
  },
  [AssetTypeEnum.T21Ps]: {
    form: (fieldValues) => <LifeFiscalContractForm fieldValues={fieldValues} />,
    details: (asset) => <LifeFiscalContractDetails asset={asset} />,
    defaultOpen: ['contract'],
  },
  [AssetTypeEnum.T21Lts]: {
    form: (fieldValues) => <LifeFiscalContractForm fieldValues={fieldValues} />,
    details: (asset) => <LifeFiscalContractDetails asset={asset} />,
    defaultOpen: ['contract'],
  },
  [AssetTypeEnum.T21Ipt]: {
    form: (fieldValues) => <LifeFiscalContractForm fieldValues={fieldValues} />,
    details: (asset) => <LifeFiscalContractDetails asset={asset} />,
    defaultOpen: ['contract'],
  },
  [AssetTypeEnum.T21Vapz]: {
    form: (fieldValues) => <LifeFiscalContractForm fieldValues={fieldValues} />,
    details: (asset) => <LifeFiscalContractDetails asset={asset} />,
    defaultOpen: ['contract'],
  },
  [AssetTypeEnum.T21Riziv]: {
    form: (fieldValues) => <LifeFiscalContractForm fieldValues={fieldValues} />,
    details: (asset) => <LifeFiscalContractDetails asset={asset} />,
    defaultOpen: ['contract'],
  },
  [AssetTypeEnum.T23Ps]: {
    form: (fieldValues) => <LifeFiscalContractForm fieldValues={fieldValues} />,
    details: (asset) => <LifeFiscalContractDetails asset={asset} />,
    defaultOpen: ['contract'],
  },
  [AssetTypeEnum.T23Ipt]: {
    form: (fieldValues) => <LifeFiscalContractForm fieldValues={fieldValues} />,
    details: (asset) => <LifeFiscalContractDetails asset={asset} />,
    defaultOpen: ['contract'],
  },
  [AssetTypeEnum.BpT21T23]: {
    form: (fieldValues) => <BPT21T23ContractForm fieldValues={fieldValues} />,
    details: (asset) => <BPT21T23ContractDetails asset={asset} />,
    defaultOpen: ['contract'],
  },
  [AssetTypeEnum.BpBc]: {
    form: (fieldValues) => <BPBCContractForm fieldValues={fieldValues} />,
    details: (asset) => <BPBCContractDetails asset={asset} />,
    defaultOpen: ['contract'],
  },
  [AssetTypeEnum.Bc]: {
    form: (fieldValues) => <BCContractForm fieldValues={fieldValues} />,
    details: (asset) => <BCContractDetails asset={asset} />,
    defaultOpen: ['contract', 'evolution'],
  },
  [AssetTypeEnum.BcPs]: {
    form: (fieldValues) => <BCPSContractForm fieldValues={fieldValues} />,
    details: (asset) => <BCPSContractDetails asset={asset} />,
    defaultOpen: ['contract'],
  },
  [AssetTypeEnum.Ovl]: {
    form: (fieldValues) => <OVLContractForm fieldValues={fieldValues} />,
    details: (asset) => <OVLContractDetails asset={asset} />,
    defaultOpen: ['contract'],
  },
  [AssetTypeEnum.Omzet]: {
    form: (fieldValues) => <RevenueContractForm fieldValues={fieldValues} />,
    details: (asset) => <RevenueContractDetails asset={asset} />,
    defaultOpen: ['contract'],
  },
  [AssetTypeEnum.Vastgoed]: {
    form: (fieldValues) => <RealEstateContractForm fieldValues={fieldValues} />,
    details: (asset) => <RealEstateContractDetails asset={asset} />,
    defaultOpen: ['contract'],
  },
  [AssetTypeEnum.Krediet]: {
    form: (fieldValues) => <CreditContractForm fieldValues={fieldValues} />,
    details: (asset) => <CreditContractDetails asset={asset} />,
    defaultOpen: ['contract'],
  },
}
