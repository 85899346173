import { translate as t } from '../../services/translation'
import { Col, Row, Table } from 'react-bootstrap'
import { AssetTypeEnum } from '../../generated'
import { dateFmt, diffFmt, moneyFmt } from '../../util/formatters'
import {
  getAssetSummary,
  getAssetValue,
  getNearestValidFrom,
  getNetDeposit,
} from '../../business-logic/asset'
import { MILLIS_IN_YEAR, previousYearEnd, todayIso } from '../../util/date-util'
import { calculateAnnualInterestRate } from '../../business-logic/interest-rate'
import { useState } from 'react'
import { useIsStaff } from '../security/authorisation'

const MAX_ASSET_SUMMARY_LENGTH = 10

export const AssetEvolution = ({ asset }) => {
  const isStaff = useIsStaff()
  const today = todayIso()
  const currentAssetValue = getAssetValue(asset, today)
  const endDate = currentAssetValue?.validFrom
  const isOoldValue = endDate && new Date().getTime() - new Date(endDate).getTime() > MILLIS_IN_YEAR
  const [period, setPeriod] = useState({
    from: asset.values ? asset.values[asset.values.length - 1].validFrom : endDate,
    to: endDate,
  })
  let validPeriod = false
  let periodAnnualInterestRate = 0
  const calculatePeriodAnnualInterestRate = () => {
    const to = period.to || endDate
    const from = period.from || endDate
    if (
      to &&
      from &&
      to > from &&
      new Date(to).getTime() - new Date(from).getTime() >= MILLIS_IN_YEAR
    ) {
      validPeriod = true
      periodAnnualInterestRate = calculateAnnualInterestRate(asset, to, from)!
    }
  }

  if (currentAssetValue) {
    const currentValue = currentAssetValue?.value
    const startDate = asset.values.at(-1).validFrom
    const netDeposit = getNetDeposit(asset, today)
    const difference = currentValue - netDeposit
    const differenceClass = difference >= 0 ? 'bg-success-subtle' : 'bg-danger-subtle'

    const annualInterestRateStart = calculateAnnualInterestRate(asset, endDate!)
    const prevYearEnd = previousYearEnd(today)
    const ytdAssetValue = getAssetValue(asset, prevYearEnd)
    // only show YTD if there is a value on 31 / 12 of the previous year
    const ytdValue = ytdAssetValue?.validFrom === prevYearEnd ? ytdAssetValue?.value : undefined
    const diffYTD =
      ytdValue && !getNetDeposit(asset, today, prevYearEnd)
        ? (currentValue - ytdValue) / ytdValue
        : undefined

    const min3Y = getNearestValidFrom(asset, -3)
    const annualInterestRate3Y = min3Y
      ? calculateAnnualInterestRate(asset, endDate!, min3Y)
      : undefined

    const min5Y = getNearestValidFrom(asset, -5)
    const annualInterestRate5Y = min5Y
      ? calculateAnnualInterestRate(asset, endDate!, min5Y)
      : undefined

    const showAnnualInterestRates =
      (isStaff || asset.showAnnualInterestRate) &&
      (annualInterestRateStart || diffYTD || annualInterestRate3Y || annualInterestRate5Y) &&
      asset.type !== AssetTypeEnum.Vastgoed &&
      asset.type !== AssetTypeEnum.Krediet &&
      asset.product.riskProfile > 0

    const summaryValues = getAssetSummary(asset, today, MAX_ASSET_SUMMARY_LENGTH)
    calculatePeriodAnnualInterestRate()

    const updatePeriod = (event) => {
      setPeriod((prev) => ({ ...prev, [event.target.name]: event.target.value }))
    }
    return (
      <>
        {currentAssetValue && (
          <Row>
            <Col>
              <Table responsive>
                <tbody>
                  <tr>
                    <td className="text-end bg-info-subtle">
                      {t('asset.evolution.currentValue')}:
                    </td>
                    <td className="text-end bg-info-subtle"> {moneyFmt(asset)}</td>
                  </tr>
                  <tr>
                    <td className="text-end">{t('netDeposit')}:</td>
                    <td className="text-end">{moneyFmt(netDeposit)}</td>
                  </tr>
                  <tr>
                    <td className={differenceClass + ' text-end'}>{t('difference')}:</td>
                    <td className={differenceClass + ' text-end'}>
                      {(difference > 0 ? '+' : '') + moneyFmt(difference)}
                    </td>
                  </tr>
                </tbody>
              </Table>
              {showAnnualInterestRates && (
                <Table responsive bordered>
                  <thead>
                    <tr>
                      <th>
                        {isOoldValue && (
                          <span className={'text-warning'}>{t('asset.evolution.oldValue')}</span>
                        )}
                      </th>
                      <th>{t('averageAnnualRate')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {!!annualInterestRateStart && (
                      <tr>
                        <td>
                          {t('sinceStart')}
                          <br />
                          <span className="small">
                            {dateFmt(startDate)} - {dateFmt(endDate)}
                          </span>
                        </td>
                        <td className={annualInterestRateStart < 0 ? 'text-danger' : ''}>
                          {diffFmt(annualInterestRateStart)}
                        </td>
                      </tr>
                    )}
                    {!!annualInterestRate3Y && (
                      <tr>
                        <td>
                          {t('min3Y')}
                          <br />
                          <span className="small">
                            {dateFmt(min3Y)} - {dateFmt(endDate)}
                          </span>
                        </td>
                        <td className={annualInterestRate3Y < 0 ? 'text-danger' : ''}>
                          {diffFmt(annualInterestRate3Y)}
                        </td>
                      </tr>
                    )}
                    {!!annualInterestRate5Y && (
                      <tr>
                        <td>
                          {t('min5Y')}
                          <br />
                          <span className="small">
                            {dateFmt(min5Y)} - {dateFmt(endDate)}
                          </span>
                        </td>
                        <td className={annualInterestRate5Y < 0 ? 'text-danger' : ''}>
                          {diffFmt(annualInterestRate5Y)}
                        </td>
                      </tr>
                    )}
                    {!!diffYTD && (
                      <tr>
                        <td>YTD</td>
                        <td className={diffYTD < 0 ? 'text-danger' : ''}>
                          {diffFmt(diffYTD)} {t('difference')}
                        </td>
                      </tr>
                    )}
                    <tr>
                      <td>
                        {t('from')}{' '}
                        <select name="from" value={period.from} onChange={updatePeriod}>
                          {asset.values.map((v) => (
                            <option value={v.validFrom} key={v.validFrom}>
                              {dateFmt(v.validFrom)}
                            </option>
                          ))}
                        </select>{' '}
                        {t('to')}{' '}
                        <select name="to" value={period.to} onChange={updatePeriod}>
                          {asset.values.map((v) => (
                            <option value={v.validFrom} key={v.validFrom}>
                              {dateFmt(v.validFrom)}
                            </option>
                          ))}
                        </select>
                      </td>
                      <td>
                        {validPeriod && (
                          <span className={periodAnnualInterestRate < 0 ? 'text-danger' : ''}>
                            {diffFmt(periodAnnualInterestRate)}
                          </span>
                        )}
                        {!validPeriod && (
                          <span className="text-danger">{t('lessThanOneYearPeriod')}</span>
                        )}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              )}
            </Col>
            <Col className="col-sm-5">
              <Table responsive className="table-condensed">
                <thead>
                  <tr>
                    <th>Datum</th>
                    <th className="text-end">{t('amount')}</th>
                    <th className="text-end">{t('asset.value.amountChange')}</th>
                  </tr>
                </thead>
                <tbody>
                  {summaryValues.map((val) => (
                    <tr key={val.validFrom}>
                      <td>{dateFmt(val.validFrom)}</td>
                      <td className="text-end">{moneyFmt(val)}</td>
                      <td className="text-end">
                        {!!val.amountChange && <span>{moneyFmt(val.amountChange)}</span>}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
          </Row>
        )}
      </>
    )
  }
  return <div />
}
