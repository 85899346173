import { Button, Col, Form, Row } from 'react-bootstrap'
import { BsSave, BsSlashCircle } from 'react-icons/bs'
import { useNavigate, useParams } from 'react-router-dom'
import { CenteredContentLayout } from '../../../components/layouts/centered-content-layout'
import { translate as t } from '../../../services/translation'
import { useQueryClient } from 'react-query'
import { api } from '../../../services/api'
import { userQueryKeys, useUserQuery } from '../../../queries/user-queries'
import { FormContainer, FormField } from '../../../components/form'

const UserEditForm = () => {
  const navigate = useNavigate()
  const { username } = useParams()
  const queryClient = useQueryClient()

  const onCancel = () => navigate(-1)
  const onSubmit = async (data) => {
    if (username) {
      await api.usersUpdate(username, data)
    } else {
      await api.usersSave(data)
    }
    await queryClient.invalidateQueries(userQueryKeys.list)
    await queryClient.invalidateQueries(userQueryKeys.user(username))
    navigate(-1)
  }

  // Queries
  const { data } = useUserQuery(username)
  if (username && !data) {
    return null
  }
  const values = { enabled: true, ...data }
  return (
    <FormContainer values={values} onSubmit={onSubmit} translationPath="user">
      <FormField
        name="username"
        registerOptions={{ required: true }}
        inputProps={{ disabled: !!username }}
      />
      <FormField name="email" />
      <FormField name="staff" inputProps={{ type: 'checkbox' }} />
      <FormField name="admin" inputProps={{ type: 'checkbox' }} />
      <FormField name="enabled" inputProps={{ type: 'checkbox' }} />
      <FormField
        name="temporaryPassword"
        registerOptions={{ required: !username }}
        inputProps={{ type: 'password', placeholder: t('user.temporaryPassword.placeholder') }}
      />
      <FormField
        name="multiFactor"
        inputProps={{ type: 'checkbox', disabled: !data?.multiFactor }}
      />

      <Form.Group as={Row} className="mb-3">
        <Col md={{ span: 9, offset: 3 }}>
          <Button type="submit" className="me-2 icon-wrapper">
            <BsSave />
            <span>{t('button.save')}</span>
          </Button>
          <Button variant="secondary" className="icon-wrapper" onClick={onCancel}>
            <BsSlashCircle />
            <span>{t('button.cancel')}</span>
          </Button>
        </Col>
      </Form.Group>
    </FormContainer>
  )
}

export const UserEdit = () => {
  const { username } = useParams()
  return (
    <CenteredContentLayout>
      <h1>{t(username ? 'edit' : 'create', t('user'))}</h1>
      <UserEditForm />
    </CenteredContentLayout>
  )
}
