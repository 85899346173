import type { ResourcesConfig } from '@aws-amplify/core'

/**
 * Easy way to find out all env variables that are used
 */
export const ENV_BG =
  process.env.REACT_APP_NAV_BG ?? (process.env.NODE_ENV === 'production' ? 'primary' : 'warning')

// used for authentication
export const APP_REGION = process.env.REACT_APP_REGION
export const USER_POOL_ID = process.env.REACT_APP_USER_POOL_ID
export const USER_POOL_CLIENT_ID = process.env.REACT_APP_USER_POOL_CLIENT_ID

export const AMPLIFY_CONFIG: ResourcesConfig = {
  Auth: {
    // mandatorySignIn: true,
    Cognito: {
      userPoolId: USER_POOL_ID!,
      userPoolClientId: USER_POOL_CLIENT_ID!,
    },
  },
}
