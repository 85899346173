import { Fragment, useCallback, useEffect, useMemo, useState } from 'react'
import { Button, Col, Form, Row } from 'react-bootstrap'
import { useFormContext, useWatch } from 'react-hook-form'
import { BsSave, BsSlashCircle } from 'react-icons/bs'
import { confirmedAddOrUpdateValue, getAssetValue } from '../../business-logic/asset'
import { assetTypes, copyContract } from '../../business-logic/contract-properties'
import { Asset, AssetTypeEnum } from '../../generated'
import { dossierQueryKeys } from '../../queries/dossier-queries'
import { useAllInstitutionQuery } from '../../queries/institution-queries'
import { useAllProductsQuery } from '../../queries/product-queries'
import { queryClient } from '../../queries/react-query-client'
import { api } from '../../services/api'
import { translate as t } from '../../services/translation'
import { EMPTY_ARRAY, prepareForPost } from '../../util/object-utils'
import { FormContainer, FormField, TypeaheadFormField } from '../form/'

async function saveAsset(data, wallet) {
  let saved
  if (data.key) {
    saved = (await api.assetsUpdate(data.id!, data.key, data)).data
  } else {
    saved = (await api.assetsSave(data.id!, data)).data
  }
  if (saved.reference) {
    const sameReferences =
      wallet.assets?.filter((a) => a.reference === saved.reference && a.key !== saved.key) || []
    for (const a of sameReferences) {
      copyContract(saved, a)
      await api.assetsUpdate(a.id, a.key, a)
    }
  }
  await queryClient.invalidateQueries(dossierQueryKeys.dossier(data.id))
}

function copyContractProps(assetReference: string, values: Asset, otherAssets: Asset[]) {
  const other = otherAssets.find(
    (a) => assetReference && a.reference === assetReference && a.key !== values.key
  )
  if (other) {
    values.institution = other.institution
    values.managedByFeax = other.managedByFeax
    values.type = other.type
    copyContract(other, values)
  }
}

const useRemountKey = (prefix: string) => {
  const [remountKey, setRemountKey] = useState(0)
  const remount = useCallback(() => setRemountKey((key) => +((key + 1) % 2)), [setRemountKey])
  return [`${prefix}-${remountKey}`, remount] as const
}

const AssetFormFields = ({ wallet }) => {
  const { data: products = EMPTY_ARRAY } = useAllProductsQuery()
  const { data: institutions = EMPTY_ARRAY } = useAllInstitutionQuery()

  const [productKey, remountProductTypeahead] = useRemountKey('productTypeahead')
  const [institionKey, remountInstitutionTypeahead] = useRemountKey('institutionTypeahead')

  const { register, getValues, setValue } = useFormContext<Asset>()
  const assetType = useWatch({ name: 'type' })
  const assetReference = useWatch({ name: 'reference' })
  const assetKey = useWatch({ name: 'key' })

  useEffect(() => {
    // quick workaround to reuse existing code but ideally we should just update the necessary fields in the form
    const formValues = getValues()
    copyContractProps(assetReference, formValues, wallet.assets ?? EMPTY_ARRAY)
    // prepareForPost is needed to convert dates and remove Nan values
    prepareForPost(formValues)
    prepareForPost(formValues['value'])
    Object.entries(formValues).forEach(([key, value]) => {
      setValue(key as any, value, { shouldTouch: true })
    })
    remountProductTypeahead()
    remountInstitutionTypeahead()
  }, [
    assetReference,
    getValues,
    setValue,
    wallet.assets,
    remountProductTypeahead,
    remountInstitutionTypeahead,
  ])

  const walletAssets = wallet.assets ?? EMPTY_ARRAY
  const referenceOptions = useMemo(
    () =>
      walletAssets
        .map((a) => a.reference)
        .filter((value, index, array) => value && array.indexOf(value) === index)
        .sort(), // build a friendly state for custom options in typeahead
    [walletAssets]
  )

  return (
    <>
      <input type="hidden" {...register('walletKey')} />
      <input type="hidden" {...register('index')} />
      <FormField
        name="value.value"
        inputProps={{ type: 'number', step: '0.01', autoFocus: true }} //TODO autoFocus does not work in create form
        registerOptions={{ required: true, valueAsNumber: true }}
      />
      <FormField
        name="value.validFrom"
        inputProps={{ type: 'date' }}
        registerOptions={{ required: true, valueAsDate: true }}
      />
      {!!assetKey && (
        <>
          <FormField
            name="value.amountChange"
            inputProps={{ type: 'number', step: '0.01' }}
            registerOptions={{ valueAsNumber: true }}
          />
          <FormField
            name="value.entryCost"
            inputProps={{ type: 'number', step: '0.01' }}
            registerOptions={{ valueAsNumber: true }}
          />
        </>
      )}
      <TypeaheadFormField
        name="reference"
        inputProps={{
          maxLength: 100,
          placeholder: t('asset.reference.placeholder'),
        }}
        options={referenceOptions}
        allowNew={true}
      />
      <TypeaheadFormField
        key={productKey}
        name={'product'}
        inputProps={{
          name: 'product',
          placeholder: t('asset.product.placeholder'),
        }}
        options={products.map((p) => ({
          ...p,
          name: p.reference ? `${p.name} - ${p.reference}` : p.name,
        }))}
        registerOptions={{ required: true }}
        labelKey="name"
      />
      <TypeaheadFormField
        key={institionKey}
        name={'institution'}
        inputProps={{ name: 'institution', placeholder: t('asset.institution.placeholder') }}
        options={institutions}
        registerOptions={{ required: true }}
        labelKey="name"
      />
      <FormField
        name="startDate"
        inputProps={{ type: 'date' }}
        registerOptions={{ valueAsDate: true }}
      />
      <FormField
        name="endDate"
        inputProps={{ type: 'date' }}
        registerOptions={{ valueAsDate: true }}
      />
      <FormField
        name="type"
        inputProps={{
          type: 'select',
          options: ['', ...Object.values(AssetTypeEnum)],
        }}
        registerOptions={{ required: true }}
      />
      {assetType !== AssetTypeEnum.Vastgoed && assetType !== AssetTypeEnum.Krediet && (
        <FormField
          name="managedByFeax"
          inputProps={{
            type: 'select',
            options: [
              { label: '', value: '' },
              { label: t('boolean.true'), value: true },
              { label: t('boolean.false'), value: false },
            ],
          }}
          registerOptions={{ requiredBool: true }}
        />
      )}
      {assetType !== AssetTypeEnum.Vastgoed && assetType !== AssetTypeEnum.Krediet && (
        <FormField
          name="showAnnualInterestRate"
          inputProps={{
            type: 'select',
            options: [
              { label: t('boolean.true'), value: true },
              { label: t('boolean.false'), value: false },
            ],
          }}
        />
      )}
      {assetTypes[assetType]?.form(getValues())}
    </>
  )
}

export const AssetForm = ({ asset, wallet, onClose }) => {
  const [loading, setLoading] = useState(false)
  const values = {
    showAnnualInterestRate: false,
    reference: '', // if not set, creating a new asset turns into an infinit loop
    ...asset,
    value: { ...getAssetValue(asset) },
  }
  const onSubmit = async (data: Asset, event) => {
    if (!loading) {
      if (data.type === AssetTypeEnum.Vastgoed || data.type === AssetTypeEnum.Krediet) {
        data.managedByFeax = false
        data.showAnnualInterestRate = false
      }
      const assetValue = prepareForPost(data['value'])
      delete data['value']
      prepareForPost(data)
      if (confirmedAddOrUpdateValue(data, assetValue)) {
        setLoading(true)
        await saveAsset(data, wallet)
        setLoading(false)
        event.target.reset()
        if (event.nativeEvent.submitter.name !== 'next') {
          onClose()
        }
      }
    }
  }

  return (
    <FormContainer values={values} onSubmit={onSubmit} translationPath="asset">
      <AssetFormFields wallet={wallet} />
      <Form.Group as={Row} className="mb-3">
        <Col md={{ span: 9, offset: 3 }}>
          {!values?.key && (
            <Button type="submit" name="next" className="me-2 icon-wrapper">
              <BsSave />
              <span>{t(values?.id ? 'button.next' : 'button.create')}</span>
            </Button>
          )}
          <Button type="submit" className="me-2 icon-wrapper">
            <BsSave />
            <span>{t('button.save')}</span>
          </Button>
          <Button variant="secondary" className="icon-wrapper" onClick={onClose}>
            <BsSlashCircle />
            <span>{t('button.cancel')}</span>
          </Button>
          {loading && <img src="/spinner.png" alt="spinner" id="spinner" />}
        </Col>
      </Form.Group>
    </FormContainer>
  )
}
