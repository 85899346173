import { type AuthUser } from 'aws-amplify/auth'
import { BsBoxArrowRight, BsPerson, BsPersonBadge } from 'react-icons/bs'
import { Link } from 'react-router-dom'
import { translate as t } from '../../services/translation'
import { NavDropdown } from './nav-dropdown'

type CurrentUserMenuProps = {
  user: AuthUser
  signOut: () => void
}

export const CurrentUserMenu = (props: CurrentUserMenuProps) => {
  if (!props.user) {
    return null
  }
  return (
    <NavDropdown icon={<BsPerson />} menuVariant="dark" menuAlign="end">
      <NavDropdown.Item className="icon-wrapper" to="/profile" as={Link}>
        <BsPersonBadge />
        <span>{props.user.username}</span>
      </NavDropdown.Item>
      <NavDropdown.Item className="icon-wrapper" onClick={props.signOut}>
        <BsBoxArrowRight className="text-danger" />
        <span>{t('signOut')}</span>
      </NavDropdown.Item>
    </NavDropdown>
  )
}
