import globalAxios from 'axios'
import { setupAxiosCognitoInterceptors } from '../config/axios-config'
import { Configuration, DefaultApi } from '../generated'

setupAxiosCognitoInterceptors(globalAxios)

const cfg = new Configuration({
  // basePath /api is now redirected via cloudfront in AWS or via webpack proxy from create-react-app
  basePath: '/api',
})

export const api = new DefaultApi(cfg)
