import { Table as BTable } from 'react-bootstrap'
import { BsSortDown, BsSortUp } from 'react-icons/bs'
import {
  ColumnDef as ReactTableColumnDef,
  flexRender,
  type Table as ReactTable,
} from '@tanstack/react-table'

export type ColumnDef<TData> = ReactTableColumnDef<TData>

export function TableLayout<TData>(table: ReactTable<TData>) {
  return (
    <BTable bordered hover responsive size="sm">
      <thead>
        {table.getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <th
                key={header.id}
                colSpan={header.colSpan}
                style={{ width: header.column.columnDef.meta?.width }}
              >
                {header.isPlaceholder ? null : (
                  <div
                    {...{
                      role: header.column.getCanSort() ? 'button' : undefined,
                      onClick: header.column.getToggleSortingHandler(),
                    }}
                  >
                    {flexRender(header.column.columnDef.header, header.getContext())}
                    {
                      {
                        asc: <BsSortUp className="ms-1" />,
                        desc: <BsSortDown className="ms-1" />,
                      }[header.column.getIsSorted() as string]
                    }
                  </div>
                )}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody>
        {table.getRowModel().rows.map((row) => (
          <tr key={row.id} {...(table.options.meta?.getRowProps?.(row) ?? {})}>
            {row.getVisibleCells().map((cell) => (
              <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </BTable>
  )
}
