import { useState } from 'react'
import { Button, Col, Form, Row } from 'react-bootstrap'
import { BsArrowDown, BsArrowUp, BsPlus, BsSave, BsSlashCircle, BsTrash } from 'react-icons/bs'
import { useNavigate, useParams } from 'react-router-dom'
import { Profile, User } from '../../generated'
import { dossierQueryKeys, useDossierQuery } from '../../queries/dossier-queries'
import { queryClient } from '../../queries/react-query-client'
import { useAllUsersQuery, useListDossierUsersQuery } from '../../queries/user-queries'
import { api } from '../../services/api'
import { translate as t } from '../../services/translation'
import { EMPTY_ARRAY } from '../../util/object-utils'
import { FormContainer, FormField, TypeaheadFormField } from '../form'
import { IfAdmin } from '../security/authorisation'

async function saveDossier(dossier) {
  let response
  if (dossier.id) {
    response = await api.dossierInfosUpdate(dossier.id, dossier)
    dossier = response.data
    await queryClient.invalidateQueries(dossierQueryKeys.dossier(dossier.id))
    return `/dossiers/${dossier.id}/info`
  } else {
    response = await api.dossiersSave(dossier)
    return `/dossiers/${response.data.id}/edit`
  }
}

async function saveWallets(dossier, wallets) {
  wallets.forEach(async (wallet, index) => {
    wallet.index = index
    if (wallet.id) {
      await api.walletsUpdate(wallet.id, wallet.key, wallet)
    } else {
      await api.walletsSave(dossier.id, wallet)
    }
  })
  const toBeWalletKeys = wallets.map((w) => w.key)
  for (let wallet of dossier.wallets ?? []) {
    if (toBeWalletKeys.indexOf(wallet.key) < 0) {
      await api.walletsDelete(wallet.id, wallet.key)
    }
  }
  await queryClient.invalidateQueries(dossierQueryKeys.dossier(dossier.id))
}

export const DossierInfoForm = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const { data: dossier } = useDossierQuery(id, { refetchOnMount: false })

  const [wallets, setWallets] = useState(dossier?.wallets ?? EMPTY_ARRAY)
  const [loading, setLoading] = useState(false)
  const onCancel = () => {
    queryClient.invalidateQueries(dossierQueryKeys.dossier(id)).then()
    navigate(-1)
  }
  const onSubmit = async (dossier) => {
    if (!loading) {
      setLoading(true)
      await saveWallets(dossier, wallets)
      setLoading(false)
      navigate(await saveDossier(dossier))
    }
  }

  return (
    <>
      <FormContainer
        defaultValues={{}}
        values={dossier ?? {}}
        onSubmit={onSubmit}
        translationPath="dossier"
      >
        <FormField name="name" registerOptions={{ required: true }} />
        <FormField
          name="profile"
          inputProps={{
            type: 'select',
            options: ['', ...Object.values(Profile)],
          }}
          registerOptions={{ required: true }}
        />
        <FormField name="description" inputProps={{ as: 'textarea', rows: 5, maxLength: 512 }} />
        <FormField name="archived" inputProps={{ type: 'checkbox' }} />

        {dossier?.id && (
          <>
            <h2>{t('wallets')}</h2>
            {wallets.map((wallet, index) => (
              <Row key={index}>
                <Col sm={9}>
                  <FormField
                    name={`wallet${index}`}
                    registerOptions={{ required: true }}
                    translationKey="wallet.name"
                    inputProps={{
                      value: wallet.name,
                      onChange: (event) => {
                        wallet.name = event.target.value
                        setWallets(wallets.slice())
                      },
                    }}
                  />
                </Col>
                <Col sm={3}>
                  <Button variant="link" disabled={index === 0}>
                    <BsArrowUp
                      onClick={() => {
                        ;[wallets[index], wallets[index - 1]] = [wallets[index - 1], wallets[index]]
                        setWallets(wallets.slice())
                      }}
                    />
                  </Button>
                  <Button variant="link" disabled={index === wallets.length - 1}>
                    <BsArrowDown
                      onClick={() => {
                        ;[wallets[index], wallets[index + 1]] = [wallets[index + 1], wallets[index]]
                        setWallets(wallets.slice())
                      }}
                    />
                  </Button>
                  <Button
                    variant="link"
                    onClick={() => {
                      wallets.splice(index, 1)
                      setWallets(wallets.slice())
                    }}
                  >
                    <BsTrash />
                  </Button>
                </Col>
              </Row>
            ))}
            <Button
              onClick={() => {
                wallets.push({ index: 0, name: '' })
                setWallets(wallets.slice())
              }}
            >
              <BsPlus size="1.5em" />
              {t('wallet')}
            </Button>
          </>
        )}
        <Form.Group as={Row} className="mb-3">
          <Col md={{ span: 9, offset: 3 }}>
            <Button type="submit" className="me-2 icon-wrapper">
              <BsSave />
              <span>{t('button.save')}</span>
            </Button>
            <Button variant="secondary" className="icon-wrapper" onClick={onCancel}>
              <BsSlashCircle />
              <span>{t('button.cancel')}</span>
            </Button>
            {loading && <img src="/spinner.png" alt="spinner" id="spinner" />}
          </Col>
        </Form.Group>
      </FormContainer>
      {dossier?.id && (
        <IfAdmin>
          <DossierUsersForm dossierId={dossier.id} />
        </IfAdmin>
      )}
    </>
  )
}

const DossierUsersForm = ({ dossierId }) => {
  const { data: dossierUsers = EMPTY_ARRAY, refetch: refetchUsers } =
    useListDossierUsersQuery(dossierId)
  const { data: users = EMPTY_ARRAY } = useAllUsersQuery()

  const onSubmit = async ({ user = null }: { user: User | null }, event) => {
    if (user?.username) {
      await api.usersAddDossier(user.username, dossierId)
      await refetchUsers()
    }
  }

  const removeUser = async (username) => {
    await api.usersRemoveDossier(username, dossierId)
    await refetchUsers()
  }

  return (
    <>
      <h2>{t('users')}</h2>
      {dossierUsers?.map((user) => (
        <Row key={user.username}>
          <Col sm={3}>{user.username}</Col>
          <Col sm={1}>
            <Button variant="link" onClick={() => removeUser(user.username)}>
              <BsTrash />
            </Button>
          </Col>
        </Row>
      ))}
      <FormContainer defaultValues={{ user: null }} onSubmit={onSubmit}>
        <Row>
          <Col sm={3}>
            <TypeaheadFormField
              name="user"
              options={users}
              labelKey="username"
              clearButton
              className="clear-user"
            />
          </Col>
          <Col sm={3}>
            <Button type="submit">
              <BsPlus size="1.5em" />
              {t('dossier.grantAccess')}
            </Button>
          </Col>
        </Row>
      </FormContainer>
    </>
  )
}
