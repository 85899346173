import clsx from 'clsx'
import { ComponentProps, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import { Typeahead, TypeaheadComponentProps } from 'react-bootstrap-typeahead'
import {
  useFormContext,
  useWatch,
  type FieldPath,
  type FieldValues,
  type RegisterOptions,
} from 'react-hook-form'
import { EMPTY_ARRAY, get } from '../../util/object-utils'
import { useTranslation } from '../translation/translation-provider'

type FormCheckProps = ComponentProps<typeof Form.Check>
type FormControlProps = ComponentProps<typeof Form.Control>
type InputProps = FormCheckProps | FormControlProps

const isCheckProps = (props: InputProps): props is FormCheckProps =>
  ['checkbox', 'radio', 'switch'].includes(props.type ?? '')

export interface FormFieldProps<
  TFieldValues extends FieldValues,
  TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> {
  translationPath?: string
  translationKey?: string
  name: TFieldName
  registerOptions?: RegisterOptions<TFieldValues, TFieldName> & { requiredBool?: boolean }
  inputProps?: InputProps
}

const useFormField = <TFieldValues extends FieldValues>({
  translationKey,
  translationPath,
  registerOptions,
}) => {
  const formContext = useFormContext<TFieldValues>()
  const { t, containsTranslation, translate } = useTranslation({
    // do not use a translationPath if translationKey is provided
    translationPath: translationKey ? null : translationPath,
  })

  let _registerOptions = { ...registerOptions }
  if (registerOptions?.required === true) {
    // use translated message for required error
    _registerOptions.required = translate('required.error')
  }
  if (registerOptions?.requiredBool === true) {
    // boolean false does not pass the required validation
    _registerOptions.validate = _registerOptions.validate = (b) =>
      b === true || b === false ? undefined : translate('required.error')
    _registerOptions.setValueAs = (value) => value && value === 'true'
  }

  return {
    ...formContext,
    t,
    containsTranslation,
    registerOptions: _registerOptions,
  }
}

const buildInput = ({ name, register, registerOptions, errors, inputProps }) => {
  let input: ReactNode
  if (isCheckProps(inputProps)) {
    const { className, ...otherProps } = inputProps
    input = (
      <Form.Check
        {...register(name, registerOptions)}
        isInvalid={!!get(errors, name)}
        className={clsx('d-flex align-items-center form-control border-0', className)}
        {...otherProps}
      />
    )
  } else if (inputProps.type === 'select') {
    input = (
      <Form.Control
        as="select"
        {...register(name, registerOptions)}
        isInvalid={!!get(errors, name)}
        {...inputProps}
      >
        {inputProps.options?.map((o) => {
          const [label, value] = o instanceof Object ? [o.label, o.value] : [o, o]
          return (
            <option key={value} value={value}>
              {label}
            </option>
          )
        })}
      </Form.Control>
    )
  } else {
    input = (
      <Form.Control
        {...register(name, registerOptions)}
        isInvalid={!!get(errors, name)}
        {...inputProps}
      />
    )
  }
  return input
}

export const FormField = <
  TFieldValues extends FieldValues,
  TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  translationPath,
  translationKey,
  name,
  registerOptions,
  inputProps = {},
}: FormFieldProps<TFieldValues, TFieldName>) => {
  const {
    register,
    formState: { errors },
    t,
    containsTranslation,
    registerOptions: _registerOptions,
  } = useFormField<TFieldValues>({ translationKey, translationPath, registerOptions })

  const placeholderTranslationKey = `${name}.placeholder`
  if (!inputProps.placeholder && containsTranslation(placeholderTranslationKey)) {
    inputProps.placeholder = t(placeholderTranslationKey)
  }
  let input = buildInput({
    name,
    register,
    registerOptions: _registerOptions,
    errors,
    inputProps,
  })

  return (
    <Form.Group as={Row} className="mb-3" controlId={name}>
      <Form.Label column md={3}>
        {t(translationKey ?? name)}
        {(_registerOptions?.required || _registerOptions?.requiredBool) && (
          <span className="text-danger"> *</span>
        )}
      </Form.Label>
      <Col sm={9}>
        {input}
        <Form.Control.Feedback type="invalid">
          {get(errors, name)?.message?.toString()}
        </Form.Control.Feedback>
      </Col>
    </Form.Group>
  )
}

export const InlineFormField = <
  TFieldValues extends FieldValues,
  TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  translationPath,
  translationKey,
  name,
  registerOptions,
  inputProps = {},
}: FormFieldProps<TFieldValues, TFieldName>) => {
  const {
    register,
    formState: { errors },
    t,
    registerOptions: _registerOptions,
  } = useFormField<TFieldValues>({ translationKey, translationPath, registerOptions })

  const isCheckBox = isCheckProps(inputProps)
  let inlineLabel
  if (isCheckBox) {
    inlineLabel = (
      <>
        {t(translationKey ?? name)}
        {_registerOptions?.required && <span className="text-danger"> *</span>}
      </>
    )
  }

  let input = buildInput({
    name,
    register,
    registerOptions: _registerOptions,
    errors,
    inputProps: {
      label: inlineLabel,
      className: clsx({ 'gap-2': isCheckBox }),
      ...inputProps,
    },
  })

  return (
    <Form.Group as={Col} md={4} className="mb-3" controlId={name}>
      <Form.Label>
        {!isCheckBox ? (
          <>
            {t(translationKey ?? name)}
            {_registerOptions?.required && <span className="text-danger"> *</span>}
          </>
        ) : (
          ' '
        )}
      </Form.Label>
      {input}
      <Form.Control.Feedback type="invalid">
        {get(errors, name)?.message?.toString()}
      </Form.Control.Feedback>
    </Form.Group>
  )
}

const rawTypes = ['number', 'string']

export type TypeaheadFormFieldProps<
  TFieldValues extends FieldValues,
  TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = FormFieldProps<TFieldValues, TFieldName> &
  TypeaheadComponentProps & {
    labelKey?: string // current workaround only supports string
  }

export const TypeaheadFormField = <
  TFieldValues extends FieldValues,
  TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  translationPath,
  translationKey,
  name,
  registerOptions,
  inputProps = {},
  options = [],
  allowNew = false,
  labelKey = 'label',
}: TypeaheadFormFieldProps<TFieldValues, TFieldName>) => {
  const {
    formState: { errors },
    register,
    t,
    registerOptions: _registerOptions,
    setValue,
  } = useFormField<TFieldValues>({ translationKey, translationPath, registerOptions })

  // build friendly state for Typeahead
  const [selections, setSelections] = useState<any[]>(EMPTY_ARRAY)

  // create a ref for the input of Typeahead
  const _inputRef = useRef<HTMLInputElement | null>(null)
  const setInputValue = (value?: string) => {
    if (_inputRef.current && value !== undefined) {
      _inputRef.current.value = value
    }
  }

  const onBlur = () => {
    if (allowNew) {
      // without this, the value from the input is discarded
      setSelections([_inputRef.current?.value ?? ''])
      return
    }
    if (!selections.length) {
      setInputValue('')
      // @ts-ignore
      setValue(name, null, { shouldValidate: true })
    }
  } // register the field and watch value
  const field = register(name, {
    ..._registerOptions,
    onBlur,
  })
  const formValue = useWatch({ name })

  useEffect(() => {
    // set selection from default value
    if (selections === EMPTY_ARRAY && formValue) {
      setSelections([formValue])
      setInputValue(rawTypes.includes(typeof formValue) ? formValue : formValue[labelKey])
    }
  }, [selections, formValue, setSelections, labelKey])
  useEffect(() => {
    // set form value whenever the selections have change
    if (selections !== EMPTY_ARRAY) {
      setValue(name, selections[0] ?? null, { shouldValidate: true })
    }
  }, [selections, name, setValue])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const initialFormValueType = useMemo(() => typeof formValue, [])

  const handleOnChange = useCallback(
    (selections) => {
      if (allowNew && !selections.length && _inputRef.current?.value) {
        setSelections([_inputRef.current.value])
        return
      }
      // handle setSelections to use string or object depending on initial value
      if (rawTypes.includes(initialFormValueType)) {
        const values = selections.map((s) => (rawTypes.includes(typeof s) ? s : s[labelKey]))
        setSelections(values)
        setInputValue(values[0])
      } else {
        // it can be that initial value is undefined or null (eg. new asset) and so then we try a default behaviour
        setSelections(selections)
        setInputValue(
          rawTypes.includes(typeof selections[0]) ? selections[0] : selections[0]?.[labelKey]
        )
      }
    },
    [allowNew, labelKey, initialFormValueType, setSelections]
  )

  return (
    <Form.Group as={Row} className="mb-3" controlId={name}>
      <Form.Label column md={3}>
        {t(translationKey ?? name)}
        {_registerOptions?.required && <span className="text-danger"> *</span>}
      </Form.Label>
      <Col sm={9}>
        <Typeahead
          {...field}
          // Sometimes it does not pass the name to inputProps :shrug: and so onBlur does not work as expected. See https://github.com/orgs/react-hook-form/discussions/5138
          inputProps={{ ...inputProps, name: field.name }}
          id={name}
          multiple={false} // current logic assumes only one value
          options={options}
          labelKey={labelKey}
          onChange={handleOnChange}
          allowNew={allowNew}
          selected={selections}
          newSelectionPrefix=""
          renderInput={({ inputRef, referenceElementRef, value, ...inputProps }) => {
            return (
              <Form.Control
                {...inputProps}
                ref={(node) => {
                  inputRef(node)
                  referenceElementRef(node)
                  _inputRef.current = node
                }}
                isInvalid={!!get(errors, name)}
                type="text"
              />
            )
          }}
        />
        <Form.Control.Feedback type="invalid">
          {get(errors, name)?.message?.toString()}
        </Form.Control.Feedback>
      </Col>
    </Form.Group>
  )
}
