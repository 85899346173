import { DateSelect } from '../../components/report/date-select'
import { translate as t } from '../../services/translation'
import { WalletSelect } from '../../components/report/wallet-select'
import { useEffect, useState } from 'react'
import { todayIso } from '../../util/date-util'
import { useParams } from 'react-router-dom'
import { useDossierQuery } from '../../queries/dossier-queries'
import { BsPrinter, BsSquare } from 'react-icons/bs'
import { Button, Table } from 'react-bootstrap'
import { ReportSummary } from '../../components/report/report-summary'
import {
  CASH,
  DEFENSIVE,
  DYNAMIC,
  getCategoryAnalysis,
  getRiskAnalysis,
  NEUTRAL,
} from '../../business-logic/risk-analysis'
import { dateFmt, numberFmt, percentageFmt, totalFmt } from '../../util/formatters'
import { WalletDetails } from '../../components/report/wallet-details'
import { RiskProfile } from '../../components/report/risk-profile'
import { api } from '../../services/api'
import { IfStaff } from '../../components/security/authorisation'

export const Print = () => {
  const { id } = useParams()
  const { data: dossier } = useDossierQuery(id, { refetchOnMount: false })
  const [date, setDate] = useState(todayIso())
  const dossierWallets = dossier?.wallets || []
  const [globalReportWallets, setGlobalReportWallets] = useState(
    dossierWallets.filter((w) => w.includeInGlobalSummary)
  )
  const [individualReportWallets, setIndividualReportWallets] = useState(
    dossierWallets.filter((w) => w.individualSummary)
  )

  let riskAnalysis = { total: 0, weightedRiskProfile: 0 }
  let categoriesMap = {}

  useEffect(() => {
    document.title = dossier!.name
  }, [dossier])

  async function updateIndividualWallets(wallets) {
    for (let wallet of dossierWallets) {
      const isSelected = wallets.indexOf(wallet) > -1
      if (wallet.individualSummary !== isSelected) {
        wallet.individualSummary = !wallet.individualSummary
        const response = await api.walletsUpdate(wallet.id!, wallet.key!, wallet)
        Object.assign(wallet, response.data)
      }
    }
    setIndividualReportWallets(wallets)
    updateReport()
  }

  async function updateGlobalAnalysisWallets(wallets) {
    for (let wallet of dossierWallets) {
      const isSelected = wallets.indexOf(wallet) > -1
      if (wallet.includeInGlobalSummary !== isSelected) {
        wallet.includeInGlobalSummary = !wallet.includeInGlobalSummary
        const response = await api.walletsUpdate(wallet.id!, wallet.key!, wallet)
        Object.assign(wallet, response.data)
      }
    }
    setGlobalReportWallets(wallets)
    updateReport()
  }

  function updateReport() {
    riskAnalysis = getRiskAnalysis(dossier, globalReportWallets, date)
    categoriesMap = getCategoryAnalysis(globalReportWallets, date).categoriesMap
  }

  updateReport()

  return (
    <div className="print-report">
      <div className="d-print-none mb-3">
        <div className="row row-cols-lg-auto align-items-center mb-2">
          <DateSelect date={date} setDate={setDate} />
        </div>
        <IfStaff>
          <div className="my-2">
            <span>{t('wallet.individualAnalysis')}: </span>
            <WalletSelect
              dossier={dossier}
              selectedWallets={individualReportWallets}
              setWallets={updateIndividualWallets}
            />
          </div>
          <div className="my-2">
            <span>{t('wallet.includeInGlobalAnalysis')}: </span>
            <WalletSelect
              dossier={dossier}
              selectedWallets={globalReportWallets}
              setWallets={updateGlobalAnalysisWallets}
            />
          </div>
        </IfStaff>
        <Button onClick={window.print}>
          <BsPrinter /> {t('print')}
        </Button>
      </div>
      <div className="d-print-block d-none page-break-after">
        <h1 className="m-5">Studie: {dossier!.name}</h1>
        <h4 className="m-5">Datum: {dateFmt(date)}</h4>
        <img src="/feax.svg" alt="Feax" width="200" />
      </div>

      <ReportSummary wallets={globalReportWallets} date={date} />
      {dossierWallets.map((wallet) => (
        <div key={wallet.key}>
          {wallet.individualSummary && <ReportSummary wallets={[wallet]} date={date} />}
          {(wallet.individualSummary || wallet.includeInGlobalSummary) && (
            <div className="page-break-after">
              <h2>
                {t('wallet.detailedOverview')} <small>{wallet.name}</small>
              </h2>
              <WalletDetails wallet={wallet} date={date} />
            </div>
          )}
        </div>
      ))}
      <RiskProfile dossier={dossier} wallets={globalReportWallets} date={date} />
      <div className="d-none d-print-block">
        <h1>{dossier?.name}</h1>
        <h3>
          {t('dossier.profile')}: {t(dossier!.profile)}
        </h3>
        <h3>Risicoscore: {numberFmt(riskAnalysis.weightedRiskProfile, 1)}</h3>

        <Table responsive className="score-table">
          <thead>
            <tr>
              <th>{t('profile')}</th>
              <th className="text-end">Bedrag</th>
              <th className="text-end">%</th>
            </tr>
          </thead>
          <tbody>
            {[DYNAMIC, NEUTRAL, DEFENSIVE, CASH].map((category) => (
              <tr key={category}>
                <td className="col-3">{t(category)}</td>
                <td className="col-1 text-end">{totalFmt(categoriesMap[category]?.total)}</td>
                <td className="col-1 text-end">
                  {percentageFmt(categoriesMap[category]?.percentage)}
                </td>
              </tr>
            ))}
            <tr className="total">
              <th>Totaal</th>
              <th className="text-end">{totalFmt(riskAnalysis.total)}</th>
              <th className="text-end">100,0%</th>
            </tr>
          </tbody>
        </Table>

        <h3>Voor ontvangst</h3>
        <div className="row">
          <div className="col-6">
            <p>
              <BsSquare /> Mijn bestaand beleggersprofiel klopt nog met mijn huidige ingesteldheid
              t.o.v. beleggingen en uit huidig rapport blijkt dat mijn vermogenssituatie niet in die
              mate is gewijzigd dat een nieuw beleggersprofiel dient opgesteld te worden.
            </p>
            <p>
              <BsSquare /> Mijn beleggersprofiel en/of vermogenssituatie is in die mate gewijzigd
              dat een nieuw beleggersprofiel dient te worden opgesteld.
            </p>
          </div>
          <div className="col-6">
            <p className="float-start" style={{ width: 100 }}>
              Naam:
            </p>
            <div
              className="float-start"
              style={{ marginLeft: 10, width: 300, height: 20, borderBottom: 'solid 1px black' }}
            ></div>
            <div className="clearfix"></div>
            <p className="float-start" style={{ width: 100, marginTop: 20 }}>
              Datum:
            </p>
            <div
              className="float-start"
              style={{ marginLeft: 10, width: 300, height: 35, borderBottom: 'solid 1px black' }}
            ></div>
            <div className="clearfix"></div>
            <p className="float-start" style={{ width: 100, marginTop: 50 }}>
              Handtekening:
            </p>
            <div
              className="float-start"
              style={{
                marginLeft: 10,
                marginTop: 5,
                width: 300,
                height: 80,
                border: 'solid thin black',
              }}
            ></div>
          </div>
        </div>
        <p></p>
        <p className="text-muted small">
          Dit Vermogensrapport kwam tot stand op basis van door u als cliënt verstrekte informatie
          enerzijds en informatie over de verzekerings- en beleggingsproducten die wij ontvangen van
          de verzekeraars en beheerders anderzijds. Feax BVBA verbindt zich ertoe om op regelmatige
          basis na te gaan of de door verzekeraars en beheerders verstrekte informatie, o.m. over de
          risicoklasse van de producten, nog actueel is. Wij kunnen echter niet garanderen dat deze
          informatie niet meer werd gewijzigd tussen het tijdstip van raadpleging en de opstelling
          van het Vermogensrapport. Feax BVBA kan dan ook geen enkele garantie bieden over de
          juistheid en volledigheid van de verstrekte gegevens. Dit Vermogensrapport geeft louter
          een indicatief overzicht van uw financiële situatie en verbindt Feax BVBA op geen enkele
          manier, noch kan zij aansprakelijk worden gesteld voor enige schade die hieruit zou
          voortvloeien.
        </p>
      </div>
    </div>
  )
}
