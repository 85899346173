import { useParams } from 'react-router-dom'
import { useDossierQuery } from '../../queries/dossier-queries'
import { todayIso } from '../../util/date-util'
import { DateSelect } from '../../components/report/date-select'
import { useState } from 'react'
import { WalletSelect } from '../../components/report/wallet-select'
import { ReportSummary } from '../../components/report/report-summary'

export const Report = () => {
  const { id } = useParams()
  const { data } = useDossierQuery(id, { refetchOnMount: false })

  const [date, setDate] = useState(todayIso())
  const [wallets, setWallets] = useState(data?.wallets || [])

  return (
    <>
      <div className="row row-cols-auto align-items-center mb-3">
        <DateSelect date={date} setDate={setDate} />
        <WalletSelect dossier={data} selectedWallets={wallets} setWallets={setWallets} />
      </div>
      {wallets.length > 0 && <ReportSummary date={date} wallets={wallets} />}
    </>
  )
}
