export const WalletSelect = ({ dossier, selectedWallets, setWallets }) => {
  const allWallets = dossier.wallets || []
  let selectedNames = selectedWallets.map((w) => w.name)
  return (
    <>
      {allWallets.map((wallet) => (
        <div className="form-check form-check-inline" key={wallet.key}>
          <input
            className="form-check-input"
            type="checkbox"
            id={wallet.key}
            checked={selectedNames.indexOf(wallet.name) > -1}
            onChange={(e) => {
              if (e.target.checked) {
                selectedNames.push(wallet.name)
              } else {
                const index = selectedNames.indexOf(wallet.name)
                if (index > -1) {
                  selectedNames.splice(index, 1)
                }
              }
              setWallets(allWallets.filter((w) => selectedNames.indexOf(w.name) > -1))
            }}
          />
          <label className="form-check-label" htmlFor="name">
            {wallet.name}
          </label>
        </div>
      ))}
    </>
  )
}
