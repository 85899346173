import { useQuery } from 'react-query'
import { type Dossier } from '../generated'
import { api } from '../services/api'
import { type QueryOptions } from './query-utils'

export const dossierQueryKeys = {
  list: ['dossiers'],
  dossier: (id?: string) => [...dossierQueryKeys.list, id],
}

export const useAllDossiersQuery = (options: QueryOptions<Dossier[]> = {}) =>
  useQuery({
    queryKey: dossierQueryKeys.list,
    queryFn: () => api.dossiersList().then((r) => r.data),
    ...options,
  })

export const useDossierQuery = (id?: string, options: QueryOptions<Dossier> = {}) =>
  useQuery({
    queryKey: dossierQueryKeys.dossier(id),
    queryFn: async () => {
      let r = await api.dossiersGet(id!)
      return r.data
    },
    enabled: !!id,
    refetchOnWindowFocus: false,
    ...options,
  })
