import { useParams } from 'react-router-dom'
import { DossierInfoForm } from '../../components/dossier/dossier-info-form'
import { CenteredContentLayout } from '../../components/layouts/centered-content-layout'
import { translate as t } from '../../services/translation'

export const DossierEdit = () => {
  const { id } = useParams()
  return (
    <CenteredContentLayout>
      <h1>{t(id ? 'edit' : 'create', t('dossier'))}</h1>
      <DossierInfoForm />
    </CenteredContentLayout>
  )
}
