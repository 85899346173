import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom'
import { CenteredContentLayout } from '../../components/layouts/centered-content-layout'
import { useDossierQuery } from '../../queries/dossier-queries'
import { Tab, Tabs } from 'react-bootstrap'
import { BsBarChart, BsDroplet, BsFilePdf, BsGraphUp, BsHeartPulse, BsInfo } from 'react-icons/bs'
import { useIsStaff } from '../../components/security/authorisation'
export const Dossier = () => {
  const { id, walletKey } = useParams()
  // Queries
  const { data: dossier } = useDossierQuery(id)
  const navigate = useNavigate()
  const location = useLocation()
  const isStaff = useIsStaff()

  if (id && !dossier) {
    return null
  }

  const selectTab = (tab) => {
    navigate(tab, { replace: true })
  }

  const lastPathPart = location.pathname.replace(new RegExp(`.+${id}/?`), '')
  const defaultActiveKey = walletKey
    ? `wallets/${walletKey}`
    : lastPathPart.length
      ? lastPathPart
      : 'report'

  return (
    <CenteredContentLayout>
      <h1 className="d-print-none">{dossier?.name}</h1>
      <Tabs
        id="controlled-tab-example"
        defaultActiveKey={defaultActiveKey}
        onSelect={(tab) => selectTab(tab)}
        className="mb-3"
      >
        <Tab
          eventKey="report"
          title={<BsBarChart />}
          active={location.pathname.includes('report')}
        />
        <Tab
          eventKey="risk-profile"
          title={<BsHeartPulse />}
          active={location.pathname.includes('risk-profile')}
        />
        <Tab
          eventKey="liquidity"
          title={<BsDroplet />}
          active={location.pathname.includes('liquidity')}
        />
        <Tab
          eventKey="evolution"
          title={<BsGraphUp />}
          active={location.pathname.includes('evolution')}
        />
        <Tab
          tabClassName={isStaff ? '' : 'd-none'}
          eventKey="returns"
          title="%"
          active={location.pathname.includes('returns')}
        />
        <Tab eventKey="info" title={<BsInfo />} active={location.pathname.includes('info')} />
        {dossier?.wallets?.map((w) => (
          <Tab
            key={w.key}
            eventKey={`wallets/${w.key}`}
            title={w.name}
            active={location.pathname.includes(w.key!)}
          />
        ))}
        <Tab eventKey="print" title={<BsFilePdf />} active={location.pathname.includes('print')} />
      </Tabs>
      <Outlet />
    </CenteredContentLayout>
  )
}
