import { translate as t } from '../../services/translation'
import AccordionItem from 'react-bootstrap/AccordionItem'
import AccordionHeader from 'react-bootstrap/AccordionHeader'
import AccordionBody from 'react-bootstrap/AccordionBody'
import { DetailField, Dl } from '../detail/detail-list'
import { dateFmt, moneyFmt } from '../../util/formatters'
import { Card, Row } from 'react-bootstrap'

export const LifeFiscalContractDetails = ({ asset }) => {
  return (
    <AccordionItem eventKey="contract">
      <AccordionHeader>
        {t('asset.policy')} {asset.reference}
      </AccordionHeader>
      <AccordionBody>
        <Dl translationPath="asset">
          <DetailField name="policyHolder">{asset.policyHolder}</DetailField>
          <DetailField name="insured">{asset.insured}</DetailField>
          <DetailField name="beneficiaryLife">{asset.beneficiaryLife}</DetailField>
          <DetailField name="beneficiaryDeath">{asset.beneficiaryDeath}</DetailField>
          <DetailField name="contractState">{asset.contractState}</DetailField>
          <Card>
            <Card.Body>
              <Card.Title>{t('asset.pensionStructure')}</Card.Title>
              {!!asset.pensionAnnualPremium && (
                <Row>
                  <DetailField name="pensionAnnualPremium">
                    {moneyFmt(asset.pensionAnnualPremium)}
                  </DetailField>
                </Row>
              )}
              {!!asset.pensionPeriodicity && (
                <Row>
                  <DetailField name="pensionPeriodicity">{t(asset.pensionPeriodicity)}</DetailField>
                </Row>
              )}
              {!!asset.pensionEntryCost && (
                <Row>
                  <DetailField name="pensionEntryCost">
                    {moneyFmt(asset.pensionEntryCost)}
                  </DetailField>
                </Row>
              )}
              {!!asset.pensionIndexation && (
                <Row>
                  <DetailField name="pensionIndexation">{asset.pensionIndexation}</DetailField>
                </Row>
              )}
            </Card.Body>
          </Card>
          <Card>
            <Card.Body>
              <Card.Title>{t('asset.deathInsuranceCoverage')}</Card.Title>
              {!!asset.deathInsuredCapital && (
                <Row>
                  <DetailField name="deathInsuredCapital">
                    {moneyFmt(asset.deathInsuredCapital)}
                  </DetailField>
                </Row>
              )}
              {!!asset.deathAnnualPremium && (
                <Row>
                  <DetailField name="deathAnnualPremium">
                    {moneyFmt(asset.deathAnnualPremium)}
                  </DetailField>
                </Row>
              )}
              {!!asset.deathPeriodicity && (
                <Row>
                  <DetailField name="deathPeriodicity">{t(asset.deathPeriodicity)}</DetailField>
                </Row>
              )}
            </Card.Body>
          </Card>
          <Card>
            <Card.Body>
              <Card.Title>{t('asset.disability')}</Card.Title>
              {!!asset.invalidityMonthlyInterest && (
                <Row>
                  <DetailField name="invalidityMonthlyInterest">
                    {moneyFmt(asset.deathInsuredCapital)}
                  </DetailField>
                </Row>
              )}
              {!!asset.invalidityAnnualPremium && (
                <Row>
                  <DetailField name="invalidityAnnualPremium">
                    {moneyFmt(asset.invalidityAnnualPremium)}
                  </DetailField>
                </Row>
              )}
              {!!asset.invalidityPeriodicity && (
                <Row>
                  <DetailField name="invalidityPeriodicity">
                    {t(asset.invalidityPeriodicity)}
                  </DetailField>
                </Row>
              )}
              {!!asset.invalidityWait && (
                <Row>
                  <DetailField name="invalidityWait">{asset.invalidityWait}</DetailField>
                </Row>
              )}
              <Row>
                <DetailField name="invalidityCoverageType">
                  {asset.invalidityCoverageType}
                </DetailField>
              </Row>
              <Row>
                <DetailField name="invalidityIndexation">{asset.invalidityIndexation}</DetailField>
              </Row>
            </Card.Body>
          </Card>
          <Card>
            <Card.Body>
              <Card.Title>{t('asset.premiumWaiver')}</Card.Title>
              {!!asset.premiumWaiverAnnualPremium && (
                <Row>
                  <DetailField name="premiumWaiverAnnualPremium">
                    {moneyFmt(asset.premiumWaiverAnnualPremium)}
                  </DetailField>
                </Row>
              )}
              {!!asset.premiumWaiverPeriodicity && (
                <Row>
                  <DetailField name="premiumWaiverPeriodicity">
                    {t(asset.premiumWaiverPeriodicity)}
                  </DetailField>
                </Row>
              )}
            </Card.Body>
          </Card>
          <DetailField name="mainDueDate">{asset.mainDueDate}</DetailField>
          <DetailField name="premiumPaymentEndDate">
            {dateFmt(asset.premiumPaymentEndDate)}
          </DetailField>
          <DetailField name="insuranceType">{asset.insuranceType}</DetailField>
          {!!asset.advanceTaken && (
            <DetailField name="advanceTaken">{moneyFmt(asset.advanceTaken)}</DetailField>
          )}
          {!!asset.remarks && (
            <DetailField name="remarks">
              <span className="pre">{asset.remarks}</span>
            </DetailField>
          )}
        </Dl>
      </AccordionBody>
    </AccordionItem>
  )
}
