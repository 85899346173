import { Link, useParams } from 'react-router-dom'
import { dossierQueryKeys, useDossierQuery } from '../../queries/dossier-queries'
import { translate as t } from '../../services/translation'
import { Asset, Value, Wallet } from '../../generated'
import { todayIso } from '../../util/date-util'
import { CreateAssetLink } from '../../components/asset/asset-modal'
import { Button, ButtonGroup, Col, Container, Form, Nav, Row } from 'react-bootstrap'
import { confirmedAddOrUpdateValue, getAssetValue, groupAssets } from '../../business-logic/asset'
import { BsArrowDownUp, BsArrowLeft, BsArrowsMove, BsSave } from 'react-icons/bs'
import { FormContainer } from '../../components/form'
import { api } from '../../services/api'
import { useFormContext } from 'react-hook-form'
import { queryClient } from '../../queries/react-query-client'
import { prepareForPost } from '../../util/object-utils'
import { DeleteDialog } from '../../components/form/delete-dialog'
import React, { useState } from 'react'
import { IfStaff, useIsStaff } from '../../components/security/authorisation'
import { AssetValuesModal } from '../../components/asset/asset-values'

const SELF_MANAGED = 'In eigen beheer'

const AssetValueInputs = () => {
  const { register } = useFormContext()

  return (
    <>
      <Col>
        <Form.Control
          type="number"
          step="0.01"
          {...register('value', { required: true, valueAsNumber: true })}
        />
      </Col>
      <Col>
        <Form.Control
          type="date"
          {...register('validFrom', { required: true, valueAsDate: true })}
        />
      </Col>
      <Col>
        <Form.Control
          type="number"
          step="0.01"
          {...register('amountChange', { valueAsNumber: true })}
        />
      </Col>
      <Col>
        <Form.Control
          type="number"
          step="0.01"
          {...register('entryCost', { valueAsNumber: true })}
        />
      </Col>
    </>
  )
}
const AssetValueForm = ({ asset }) => {
  const [loading, setLoading] = useState(false)

  const onSubmit = async (assetValue: Value) => {
    if (!loading) {
      const assetCopy = { ...asset }
      if (confirmedAddOrUpdateValue(assetCopy, prepareForPost(assetValue))) {
        setLoading(true)
        await api.assetsUpdate(asset.id!, asset.key!, assetCopy)
        await queryClient.invalidateQueries(dossierQueryKeys.dossier(asset.id))
        setLoading(false)
      }
    }
  }

  const deleteAsset = async (asset: Asset) => {
    if (!loading) {
      setLoading(true)
      await api.assetsDelete(asset.id!, asset.key!)
      await queryClient.invalidateQueries(dossierQueryKeys.dossier(asset.id))
      setLoading(false)
    }
  }

  return (
    <FormContainer values={getAssetValue(asset)} onSubmit={onSubmit}>
      <Row className="asset" key={asset.key}>
        <Col md="2">
          {asset?.reference || '-'} / {asset?.type}
        </Col>
        <Col>{asset.product.name}</Col>
        <AssetValueInputs />
        <Col>
          <ButtonGroup>
            <AssetValuesModal asset={asset} edit={true} />
            <Button title={t('button.save')} className="icon-wrapper" type="submit">
              <BsSave />
            </Button>
            <IfStaff>
              <DeleteDialog
                action="delete"
                entity="asset"
                showLabel={false}
                onDelete={() => deleteAsset(asset)}
              />
            </IfStaff>
          </ButtonGroup>
          {loading && <img src="/spinner.png" alt="spinner" id="spinner" />}
        </Col>
      </Row>
    </FormContainer>
  )
}

export const WalletEdit = () => {
  const { id, walletKey } = useParams()
  const { data } = useDossierQuery(id, { refetchOnMount: false })
  const isStaff = useIsStaff()
  const wallet: Wallet = data!.wallets!.find((w) => w.key === walletKey)!
  const date = todayIso()

  const groups = groupAssets(wallet.assets ?? [], date)

  return (
    <>
      <Nav>
        <IfStaff>
          <Nav.Item>
            <CreateAssetLink wallet={wallet} />
          </Nav.Item>
          <Nav.Item>
            <Link className="nav-link icon-wrapper" to="../move-assets">
              <BsArrowsMove />
              <span>{t('wallet.moveAssets')}</span>
            </Link>
          </Nav.Item>
          <Nav.Item>
            <Link className="nav-link icon-wrapper" to="../sort-assets">
              <BsArrowDownUp />
              <span>{t('wallet.sortAssets')}</span>
            </Link>
          </Nav.Item>
        </IfStaff>
        <Nav.Item>
          <Link className="nav-link icon-wrapper" to="..">
            <BsArrowLeft />
            <span>{t('button.back')}</span>
          </Link>
        </Nav.Item>
      </Nav>
      <Container className="table">
        <Row className="fw-bold">
          <Col md="2">
            {t('asset.reference')} / {t('asset.type')}
          </Col>
          <Col>{t('product')}</Col>
          <Col>{t('amount')}</Col>
          <Col>{t('asset.value.validFrom')}</Col>
          <Col>{t('asset.value.amountChange')}</Col>
          <Col>{t('asset.value.entryCost')}</Col>
          <Col />
        </Row>
        {groups.map((group, groupIndex) => (
          <div className="group wallet-group" key={groupIndex}>
            <Row className="info">
              <Col>
                {group.institution?.name} {group.reference && `- ${group.reference}`}{' '}
              </Col>
            </Row>
            {group.assets
              .filter(
                (asset) =>
                  isStaff ||
                  asset.product.riskProfile === 0 ||
                  asset.product.assetManager?.name === SELF_MANAGED
              )
              .map((asset) => (
                <AssetValueForm asset={asset} key={asset.key} />
              ))}
          </div>
        ))}
      </Container>
      <Nav>
        <IfStaff>
          <Nav.Item>
            <CreateAssetLink wallet={wallet} />
          </Nav.Item>
        </IfStaff>
        <Nav.Item>
          <Link className="nav-link icon-wrapper" to="..">
            <BsArrowLeft />
            <span>{t('button.back')}</span>
          </Link>
        </Nav.Item>
      </Nav>
    </>
  )
}
