import { type CellContext, createColumnHelper, Table } from '@tanstack/react-table'
import { ButtonGroup, Card, Row } from 'react-bootstrap'
import { BsCheck, BsEye, BsPen, BsPlus, BsX } from 'react-icons/bs'
import { Link } from 'react-router-dom'
import { CenteredContentLayout } from '../../../components/layouts/centered-content-layout'
import { useOpinionatedReactTable } from '../../../components/table/react-table'
import { TableLayout } from '../../../components/table/table-layout'
import { User } from '../../../generated'
import { useAllUsersQuery } from '../../../queries/user-queries'
import { useFilterStateInURL } from '../../../components/table/filter-sorting'
import { FormContainer, InlineFormField } from '../../../components/form'
import { translate as t } from '../../../services/translation'

const userFiltersDefaultValues = {
  username: '',
  admin: '',
  staff: '',
}

const mapUserFilterValue = (k, v) => v

const booleanFilter = (row, columnId, filterValue, _) => !filterValue || row.getValue(columnId)
const booleanCell = ({ getValue }) => (getValue() ? <BsCheck /> : '')

const UserFilters = ({ setColumnFilters }: Table<User>) => {
  const { filterState, onFilterStateChanged } = useFilterStateInURL({
    defaultFilters: userFiltersDefaultValues,
    setColumnFilters,
    mapFilter: mapUserFilterValue,
  })

  return (
    <Card className="mb-3">
      <Card.Header>Filter</Card.Header>
      <Card.Body>
        <FormContainer
          defaultValues={userFiltersDefaultValues}
          values={filterState}
          submitOnChange={true}
          onSubmit={onFilterStateChanged}
          translationPath="user"
        >
          <Row>
            <InlineFormField name="username" />
            <InlineFormField name="staff" inputProps={{ type: 'switch' }} />
            <InlineFormField name="admin" inputProps={{ type: 'switch' }} />
          </Row>
        </FormContainer>
      </Card.Body>
    </Card>
  )
}

const UserRowActions = (props: CellContext<User, unknown>) => (
  <div className="d-flex">
    <ButtonGroup className="ms-auto" size="sm">
      <Link className="btn btn-info icon-wrapper" to={`${props.row.original.username}`}>
        <BsEye />
      </Link>
      <Link className="btn btn-primary icon-wrapper" to={`${props.row.original.username}/edit`}>
        <BsPen />
        <span>Edit</span>
      </Link>
      <Link className="btn btn-danger icon-wrapper" to={`${props.row.original.username}/delete`}>
        <BsX />
        <span>Delete</span>
      </Link>
    </ButtonGroup>
  </div>
)

const columnHelper = createColumnHelper<User>()

const columns = [
  columnHelper.accessor('username', {
    header: 'user.username',
  }),
  columnHelper.accessor('staff', {
    header: t('user.staff'),
    filterFn: booleanFilter,
    cell: booleanCell,
  }),
  columnHelper.accessor('admin', {
    header: t('user.admin'),
    filterFn: booleanFilter,
    cell: booleanCell,
  }),
  columnHelper.display({
    id: 'actions',
    cell: (props) => <UserRowActions {...props} />,
    footer: (_) => null,
    meta: {
      width: '1%', // this is a trick to set minimal width
    },
  }),
]

export const Users = () => {
  // Queries
  const query = useAllUsersQuery()

  const tableInstance = useOpinionatedReactTable({
    columns,
    data: query.isSuccess ? query.data : [],
  })

  return (
    <CenteredContentLayout>
      <h1>Gebruikersprofiel Overzicht</h1>
      <div className="d-flex justify-content-end h2">
        <Link to="/admin/users/new" className="btn btn-primary">
          <BsPlus />
          <span>Create new User</span>
        </Link>
      </div>
      <UserFilters {...tableInstance} />
      <TableLayout {...tableInstance} />
    </CenteredContentLayout>
  )
}
