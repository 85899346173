import { redirect, RouteObject } from 'react-router-dom'
import { api } from '../../services/api'

export const routes: RouteObject[] = [
  {
    path: 'assets',
    children: [
      {
        path: ':assetKey',
        children: [
          {
            index: true,
            loader: async ({ request, params }) => {
              const response = await api.assetsGet(params.id!, params.assetKey!)
              return redirect(
                `/dossiers/${params.id}/wallets/${response.data.walletKey}?assetKey=${params.assetKey}`
              )
            },
          },
        ],
      },
    ],
  },
]
