import { BsBank, BsDatabaseFillGear, BsGraphUpArrow, BsPiggyBank } from 'react-icons/bs'
import { Link } from 'react-router-dom'
import { NavDropdown } from './nav-dropdown'
import { translate as t } from '../../services/translation'

export const MasterDataMenu = (props) => {
  if (!props.user) {
    return null
  }
  return (
    <NavDropdown icon={<BsDatabaseFillGear />} title="Tabellen" menuVariant="dark" menuAlign="end">
      <NavDropdown.Item className="icon-wrapper" to="/master-data/products" as={Link}>
        <BsPiggyBank />
        <span>{t('product')}</span>
      </NavDropdown.Item>
      <NavDropdown.Item className="icon-wrapper" to="/master-data/asset-managers" as={Link}>
        <BsGraphUpArrow />
        <span>{t('assetManager')}</span>
      </NavDropdown.Item>
      <NavDropdown.Item className="icon-wrapper" to="/master-data/institutions" as={Link}>
        <BsBank />
        <span>{t('institution')}</span>
      </NavDropdown.Item>
    </NavDropdown>
  )
}
