import { RouteObject } from 'react-router-dom'
import { routes as userRoutes } from './users'
import { routes as baloiseRoutes } from './baloise'
import { routes as downloadRoutes } from './download'

export const routes: RouteObject[] = [
  {
    path: 'admin',
    children: [...userRoutes, ...baloiseRoutes, ...downloadRoutes],
  },
]
