import { Link, useNavigate, useParams } from 'react-router-dom'
import { dossierQueryKeys, useDossierQuery } from '../../queries/dossier-queries'
import { translate as t } from '../../services/translation'
import { Wallet } from '../../generated'
import { todayIso } from '../../util/date-util'
import { AssetLink } from '../../components/asset/asset-modal'
import { Button, Col, Container, Nav, Row } from 'react-bootstrap'
import { groupAssets } from '../../business-logic/asset'
import { moneyFmt } from '../../util/formatters'
import { BsArrowDown, BsArrowLeft, BsArrowUp, BsSave } from 'react-icons/bs'
import React, { useState } from 'react'
import { api } from '../../services/api'
import { queryClient } from '../../queries/react-query-client'

const Buttons = ({ onSave }) => {
  return (
    <Nav>
      <Nav.Item>
        <Button className="nav-link icon-wrapper" onClick={onSave}>
          <BsSave />
          <span>{t('button.save')}</span>
        </Button>
      </Nav.Item>
      <Nav.Item>
        <Link className="nav-link icon-wrapper" to="../edit">
          <BsArrowLeft />
          <span>{t('button.back')}</span>
        </Link>
      </Nav.Item>
    </Nav>
  )
}
export const WalletSortAssets = () => {
  const navigate = useNavigate()
  const { id, walletKey } = useParams()
  const { data } = useDossierQuery(id, { refetchOnMount: false })
  const wallet: Wallet = data!.wallets!.find((w) => w.key === walletKey)!
  const date = todayIso()
  const [groups, setGroups] = useState(groupAssets(wallet.assets ?? [], date))
  const moveGroup = (index, direction) => {
    ;[groups[index + direction], groups[index]] = [groups[index], groups[index + direction]]
    setGroups([...groups])
  }
  const moveAsset = (groupIndex, index, direction) => {
    const assets = groups[groupIndex].assets
    ;[assets[index + direction], assets[index]] = [assets[index], assets[index + direction]]
    setGroups([...groups])
  }

  const onSave = async () => {
    let index = 0
    let invalidate = false
    for (let group of groups) {
      for (let asset of group.assets) {
        if (asset.index !== index) {
          asset.index = index
          await api.assetsUpdate(asset.id!, asset.key!, asset)
          invalidate = true
        }
        index++
      }
    }
    if (invalidate) {
      await queryClient.invalidateQueries(dossierQueryKeys.dossier(id))
    }
    navigate('../edit')
  }

  return (
    <>
      <Buttons onSave={onSave} />
      <Container className="table">
        <Row className="fw-bold">
          <Col>
            {t('asset.reference')} / {t('asset.type')}
          </Col>
          <Col>{t('product')}</Col>
          <Col className="text-center">{t('amount')}</Col>
          <Col> </Col>
        </Row>
        {groups.map((group, groupIndex) => (
          <div className="group wallet-group" key={groupIndex}>
            <Row className="info">
              <Col className="col" md="9">
                {group.institution?.name} {group.reference && `- ${group.reference}`}{' '}
              </Col>
              <Col>
                <Button
                  variant="link"
                  disabled={groupIndex === 0}
                  onClick={() => {
                    moveGroup(groupIndex, -1)
                  }}
                >
                  <BsArrowUp />
                </Button>
                <Button
                  variant="link"
                  disabled={groupIndex === groups.length - 1}
                  onClick={() => {
                    moveGroup(groupIndex, 1)
                  }}
                >
                  <BsArrowDown />
                </Button>
              </Col>
            </Row>
            {group.assets.map((asset, index) => (
              <Row key={index}>
                <Col>
                  <AssetLink wallet={wallet} asset={asset} />
                </Col>
                <Col>{asset.product.name}</Col>
                <Col className="text-center">{moneyFmt(asset, date)}</Col>
                <Col>
                  <Button
                    variant="link"
                    disabled={index === 0}
                    onClick={() => {
                      moveAsset(groupIndex, index, -1)
                    }}
                  >
                    <BsArrowUp />
                  </Button>
                  <Button
                    variant="link"
                    disabled={index === group.assets.length - 1}
                    onClick={() => {
                      moveAsset(groupIndex, index, 1)
                    }}
                  >
                    <BsArrowDown />
                  </Button>
                </Col>
              </Row>
            ))}
          </div>
        ))}
      </Container>
      <Buttons onSave={onSave} />
    </>
  )
}
