import { translate as t } from '../../services/translation'
import { getAssetValue, groupAssets } from '../../business-logic/asset'
import { dateFmt, moneyFmt, validFromFmt } from '../../util/formatters'
import { riskCategory } from '../../business-logic/risk-analysis'
import { FullProductLink } from '../links'
import { ManualPageBreak } from './manual-page-break'
import { AssetLink } from '../asset/asset-modal'
import { Table } from 'react-bootstrap'
import { PolicyLink } from './policy-modal'

export const WalletDetails = ({ wallet, date, showAll = false }) => {
  const groups = groupAssets(wallet.assets ?? [], date)
  const walletTotal = groups
    .map((g) => g.total)
    .reduce((total, groupTotal) => total + groupTotal, 0)

  return (
    <Table responsive>
      <thead>
        <tr>
          <th>
            {t('asset.reference')} / {t('asset.type')}
          </th>
          <th>{t('product')}</th>
          <th>{t('asset.startDate')}</th>
          <th>{t('asset.endDate')}</th>
          <th className="text-end">{t('amount')}</th>
          <th className="text-end">{t('asset.value.validFrom')}</th>
        </tr>
      </thead>
      {groups.map(
        (group, groupIndex) =>
          (showAll || group.show) && (
            <tbody className="group wallet-group" key={groupIndex}>
              <tr className="info">
                <th colSpan={2}>
                  {group.institution?.name}{' '}
                  {group.reference && (
                    <span>
                      - <PolicyLink assetGroup={group} />
                    </span>
                  )}
                </th>
                <th colSpan={4}>{dateFmt(group.startDate)}</th>
              </tr>
              {group.assets.map(
                (asset) =>
                  (showAll || (getAssetValue(asset, date)?.value ?? 0) !== 0) && (
                    <tr className="asset" key={asset.key}>
                      <td>
                        <span className={`profile ${riskCategory(asset.product.riskProfile!)}`} />
                        <AssetLink wallet={wallet} asset={asset} />
                      </td>
                      <td>
                        <FullProductLink entity={asset.product} />
                      </td>
                      <td>{dateFmt(asset.startDate)}</td>
                      <td>{dateFmt(asset.endDate)}</td>
                      <td className="text-end">{moneyFmt(asset, date)}</td>
                      <td className="text-end">{validFromFmt(asset, date)}</td>
                    </tr>
                  )
              )}
              <tr className="subtotal">
                <th colSpan={4} />
                <th className="text-end">{moneyFmt(group.total)}</th>
                <th>
                  <ManualPageBreak />
                </th>
              </tr>
            </tbody>
          )
      )}
      <tbody>
        <tr className="total">
          <th colSpan={4}>
            {t('total')} {wallet.name}
          </th>
          <th className="text-end">{moneyFmt(walletTotal)}</th>
          <th />
        </tr>
      </tbody>
    </Table>
  )
}
