export function getYear(isoDate: string) {
  return parseInt(isoDate.substring(0, 4), 10)
}

export function toIsoDate(date: Date) {
  return date.toISOString().substring(0, 10)
}

export function todayIso() {
  return toIsoDate(new Date())
}

export function previousYearEnd(isoDate: string, yearsAgo = 1) {
  return getYear(isoDate) - yearsAgo + '-12-31'
}

export function addYears(isoDate: string, years) {
  const date = new Date(isoDate)
  date.setFullYear(date.getFullYear() + years)
  return toIsoDate(date)
}

export function findNearestWithinYear(
  target: string,
  date1: string | undefined,
  date2: string | undefined
) {
  const targetDate = new Date(target).getTime()
  const date1Date = date1 ? new Date(date1).getTime() : 0
  const date2Date = date2 ? new Date(date2).getTime() : 0
  const [nearest, nearestDate] =
    Math.abs(targetDate - date1Date) < Math.abs(targetDate - date2Date)
      ? [date1, date1Date]
      : [date2, date2Date]
  return Math.abs(targetDate - nearestDate) < MILLIS_IN_YEAR ? nearest : undefined
}

export function tenYearsAgo() {
  const date = new Date()
  date.setFullYear(date.getFullYear() - 10)
  return toIsoDate(date)
}

export function diff(date1: string, date2: string) {
  return new Date(date1).getTime() - new Date(date2).getTime()
}

export const MILLIS_IN_YEAR = 365 * 24 * 3600 * 1000
