import { Button, Col, Form, Row } from 'react-bootstrap'
import { BsFiletypeXlsx } from 'react-icons/bs'
import { translate as t } from '../../../services/translation'
import { CenteredContentLayout } from '../../../components/layouts/centered-content-layout'
import { DateSelect } from '../../../components/report/date-select'
import { useState } from 'react'
import { todayIso } from '../../../util/date-util'
import { api } from '../../../services/api'
import { toast } from 'react-toastify'
import { EMPTY_ARRAY } from '../../../util/object-utils'
import { useAllReportsQuery } from '../../../queries/reporting-queries'

export const ExcelDownload = () => {
  let [date, setDate] = useState(todayIso())
  const { data: reports = EMPTY_ARRAY } = useAllReportsQuery({ refetchOnWindowFocus: false })
  const generateReport = async () => {
    await api.reportingGenerate(date)
    toast.info(t('admin.generating'))
  }

  return (
    <CenteredContentLayout>
      <h1>Excel download</h1>
      <ul>
        {reports.map((report) => (
          <li key={report.filename}>
            <a href={report.url}>{report.filename}</a>
          </li>
        ))}
      </ul>
      <Form.Group as={Row} className="gy-2 mb-3">
        <Col md={2}>
          <DateSelect date={date} setDate={setDate} />
        </Col>
        <Col md={2}>
          <Button className="btn btn-primary me-2 icon-wrapper" onClick={generateReport}>
            <BsFiletypeXlsx />
            <span>{t('admin.generateExcel')}</span>
          </Button>
        </Col>
      </Form.Group>
    </CenteredContentLayout>
  )
}
