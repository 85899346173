import { Button, Col, Form, Row } from 'react-bootstrap'
import { BsSave, BsSlashCircle } from 'react-icons/bs'
import { useNavigate, useParams } from 'react-router-dom'
import { FormContainer, FormField, TypeaheadFormField } from '../../../components/form'
import { CenteredContentLayout } from '../../../components/layouts/centered-content-layout'
import {
  productQueryKeys,
  useAllProductsQuery,
  useProductQuery,
} from '../../../queries/product-queries'
import { api } from '../../../services/api'
import { translate as t } from '../../../services/translation'
import { useQueryClient } from 'react-query'
import { useMemo } from 'react'
import { EMPTY_ARRAY, prepareForPost } from '../../../util/object-utils'
import { useAllAssetManagerQuery } from '../../../queries/asset-manager-queries'

const ProductEditForm = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const queryClient = useQueryClient()
  const productsQuery = useAllProductsQuery()
  const products = useMemo(() => {
    if (productsQuery.isSuccess) {
      return productsQuery.data
    }
    return EMPTY_ARRAY
  }, [productsQuery.isSuccess, productsQuery.data])
  const assetManagersQuery = useAllAssetManagerQuery()
  const assetManagers = useMemo(() => {
    if (assetManagersQuery.isSuccess) {
      return assetManagersQuery.data
    }
    return EMPTY_ARRAY
  }, [assetManagersQuery.isSuccess, assetManagersQuery.data])

  const onCancel = () => navigate(-1)
  const onSubmit = async (data) => {
    prepareForPost(data)
    if (id) {
      await api.productsUpdate(id, data)
    } else {
      await api.productsSave(data)
    }
    await queryClient.invalidateQueries(productQueryKeys.list)
    navigate(-1)
  }

  // Queries
  const { data } = useProductQuery(id)

  if (id && !data) {
    return null
  }

  return (
    <FormContainer values={data ?? {}} onSubmit={onSubmit} translationPath="product">
      <FormField name="name" registerOptions={{ required: true }} />
      <FormField name="reference" />
      <FormField name="description" inputProps={{ as: 'textarea', rows: 5, maxLength: 512 }} />
      <FormField
        name="riskProfile"
        inputProps={{ type: 'number', min: 0, max: 7, step: 1 }}
        registerOptions={{ required: true, valueAsNumber: true }}
      />
      <TypeaheadFormField name="assetManager" options={assetManagers} labelKey="name" />
      <TypeaheadFormField name="referenceProduct" options={products} labelKey="name" />
      <FormField name="url" />
      <FormField
        name="minimumDuration"
        inputProps={{ type: 'number', min: 0, step: 1 }}
        registerOptions={{ valueAsNumber: true }}
      />
      <FormField
        name="maxEarlyWithdrawalPercentage"
        inputProps={{ type: 'number', min: 0, max: 100, step: 0.01 }}
        registerOptions={{ valueAsNumber: true }}
      />
      <FormField
        name="maxEarlyWithdrawal"
        inputProps={{ type: 'number', min: 0, step: 0.01 }}
        registerOptions={{ valueAsNumber: true }}
      />
      <FormField name="archived" inputProps={{ type: 'checkbox' }} />

      <Form.Group as={Row} className="mb-3">
        <Col md={{ span: 9, offset: 3 }}>
          <Button type="submit" className="me-2 icon-wrapper">
            <BsSave />
            <span>{t('button.save')}</span>
          </Button>
          <Button variant="secondary" className="icon-wrapper" onClick={onCancel}>
            <BsSlashCircle />
            <span>{t('button.cancel')}</span>
          </Button>
        </Col>
      </Form.Group>
    </FormContainer>
  )
}

export const ProductEdit = () => {
  const { id } = useParams()
  return (
    <CenteredContentLayout>
      <h1>{t(id ? 'edit' : 'create', t('product'))}</h1>
      <ProductEditForm />
    </CenteredContentLayout>
  )
}
