import { translate as t } from '../../services/translation'
import { InputGroup } from 'react-bootstrap'

export const DateSelect = ({ date, setDate, label = 'date' }) => {
  return (
    <div>
      <InputGroup>
        <div className="input-group-text">{t(label)}</div>
        <input
          type="date"
          name="date-input"
          className="form-control"
          value={date}
          onChange={(e) => setDate(e.target.value)}
        />
      </InputGroup>
    </div>
  )
}
