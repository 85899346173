import { translate as t } from '../../services/translation'
import { FormField } from '../form'
import { useState } from 'react'
import { AssetTypeEnum } from '../../generated'

export const LifeNonFiscalContractForm = ({ fieldValues }) => {
  const [gift, setGift] = useState(fieldValues.gift)
  const onGiftChange = (event) => {
    setGift(event.target.value === 'true')
  }

  return (
    <>
      <FormField name="policyHolder" inputProps={{ maxLength: '100' }} />
      <FormField name="insured" inputProps={{ maxLength: '100' }} />
      <FormField name="beneficiaryLife" inputProps={{ maxLength: '100' }} />
      <FormField
        name="deathInsuranceCoverage130"
        inputProps={{
          type: 'select',
          options: [
            { label: '', value: '' },
            { label: t('boolean.true'), value: true },
            { label: t('boolean.false'), value: false },
          ],
        }}
      />
      <FormField name="beneficiaryDeath" inputProps={{ maxLength: '100' }} />
      <FormField name="acceptingBeneficiary" inputProps={{ maxLength: '100' }} />
      <FormField
        name="gift"
        inputProps={{
          type: 'select',
          options: [
            { label: '', value: '' },
            { label: t('boolean.true'), value: true },
            { label: t('boolean.false'), value: false },
          ],
          defaultValue: fieldValues.gift,
        }}
        registerOptions={{ onChange: onGiftChange }}
      />
      {!!gift && <FormField name="giftConditions" inputProps={{ maxLength: '100' }} />}
      <FormField
        name="entryCost"
        inputProps={{ type: 'number', min: 0, step: 0.01 }}
        registerOptions={{ min: 0, valueAsNumber: true }}
      />
      <FormField
        name="premiumTax"
        inputProps={{ type: 'number', min: 0, step: 0.01 }}
        registerOptions={{ min: 0, valueAsNumber: true }}
      />
      <FormField
        name="premiumTaxType"
        inputProps={{
          type: 'select',
          options: ['', 'eenmalig afgehouden', 'gespreid afgehouden', 'actie taks'],
        }}
      />
      {fieldValues.type !== AssetTypeEnum.T23 && (
        <FormField
          name="guaranteedReturns"
          inputProps={{ as: 'textarea', rows: 5, maxLength: 512 }}
        />
      )}
      <FormField name="remarks" inputProps={{ as: 'textarea', rows: 5, maxLength: 512 }} />
    </>
  )
}
