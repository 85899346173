import { FormField } from '../form'

export const BPT21T23ContractForm = (_) => {
  return (
    <>
      <FormField
        name="monthlyPremium"
        inputProps={{ type: 'number', min: 0, step: 0.01 }}
        registerOptions={{ min: 0, valueAsNumber: true }}
      />
      <FormField name="policyHolder" inputProps={{ maxLength: '100' }} />
      <FormField name="insured" inputProps={{ maxLength: '100' }} />
      <FormField name="beneficiaryLife" inputProps={{ maxLength: '100' }} />
      <FormField name="beneficiaryDeath" inputProps={{ maxLength: '100' }} />
      <FormField
        name="entryCost"
        inputProps={{ type: 'number', min: 0, step: 0.01 }}
        registerOptions={{ min: 0, valueAsNumber: true }}
      />
      <FormField name="remarks" inputProps={{ as: 'textarea', rows: 5, maxLength: 512 }} />
    </>
  )
}
