import { useState } from 'react'
import { Button, InputGroup, Modal } from 'react-bootstrap'
import { BsKey } from 'react-icons/bs'
import { translate as t } from '../../services/translation'
import { AccountSettings } from '@aws-amplify/ui-react'

export function ChangePassword() {
  const [show, setShow] = useState(false)
  const toggle = () => {
    setShow(!show)
  }

  return (
    <>
      <InputGroup className="mb-3">
        <Button className="me-2 icon-wrapper" onClick={toggle}>
          <BsKey />
          <span>{t('Change Password')}</span>
        </Button>
      </InputGroup>
      <Modal centered show={show} onHide={toggle} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">{t('Change Password')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AccountSettings.ChangePassword onSuccess={toggle} />
        </Modal.Body>
      </Modal>
    </>
  )
}
