import { BsFileBreakFill } from 'react-icons/bs'
import { translate as t } from '../../services/translation'

export const ManualPageBreak = () => {
  return (
    <div>
      <div className="manual-page-break d-none d-print-none">
        <BsFileBreakFill
          title={t('pageBreak')}
          size={20}
          className="text-secondary"
          onClick={(e) => {
            e.currentTarget.classList.toggle('text-info')
            e.currentTarget.parentElement?.parentElement?.classList?.toggle('page-break-after')
          }}
        />
      </div>
    </div>
  )
}
