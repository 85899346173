import { useDossierQuery } from '../../queries/dossier-queries'
import { Link, useParams } from 'react-router-dom'
import { BsPenFill } from 'react-icons/bs'
import { IfStaff } from '../../components/security/authorisation'
import { translate as t } from '../../services/translation'

export const Info = () => {
  const { id } = useParams()
  const { data: dossier } = useDossierQuery(id, { refetchOnMount: false })

  return (
    <>
      <h2>Info</h2>
      <h4>
        {t('dossier.profile')}: {t(dossier?.profile ?? '')}
      </h4>
      <p>
        <span className="pre">{dossier?.description}</span>
      </p>
      <IfStaff>
        <Link to="../edit">
          <BsPenFill className="text-primary" />
        </Link>
      </IfStaff>
    </>
  )
}
