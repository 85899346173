import { createContext, type PropsWithChildren, useContext, useMemo } from 'react'
import { containsTranslation, makeTranslationKey, translate } from '../../services/translation'

export type TranslationOptions = {
  translationPath?: string | null
}

const contextTranslate =
  ({ translationPath }: TranslationOptions) =>
  (name: string, ...args: any[]) => {
    let key = makeTranslationKey({
      translationPath,
      name,
    })
    return translate(key, args)
  }

const contextContainsTranslation =
  ({ translationPath }: TranslationOptions) =>
  (name: string) => {
    let key = makeTranslationKey({
      translationPath,
      name,
    })
    return containsTranslation(key)
  }

export type TranslationContextType = TranslationOptions & {
  t: typeof translate
  containsTranslation: typeof containsTranslation
  translate: typeof translate
}

const TranslationContext = createContext<TranslationContextType>({
  t: contextTranslate({}),
  containsTranslation: contextContainsTranslation({}),
  translate,
})

export type TranslationProviderProps = PropsWithChildren<TranslationOptions>

export const TranslationProvider = ({ children, translationPath }: TranslationProviderProps) => {
  const context = useMemo(
    () => ({
      translationPath,
      t: contextTranslate({ translationPath }),
      containsTranslation: contextContainsTranslation({ translationPath }),
      translate,
    }),
    [translationPath]
  )
  return <TranslationContext.Provider value={context}>{children}</TranslationContext.Provider>
}

export const useTranslation = ({ translationPath }: TranslationOptions = {}) => {
  const context = useContext(TranslationContext)
  if (translationPath !== undefined) {
    return {
      ...context,
      translationPath,
      t: contextTranslate({ translationPath }),
      containsTranslation: contextContainsTranslation({ translationPath }),
    }
  }
  return context
}
