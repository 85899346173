import { redirect, RouteObject } from 'react-router-dom'
import { DeleteDialog } from '../../../components/form/delete-dialog'
import { api } from '../../../services/api'
import { ProductDetail } from './product-detail'
import { ProductEdit } from './product-edit'
import { Products } from './products'
import { queryClient } from '../../../queries/react-query-client'
import { productQueryKeys } from '../../../queries/product-queries'

export const routes: RouteObject[] = [
  {
    path: 'products',
    children: [
      { index: true, element: <Products /> },
      { path: 'new', element: <ProductEdit /> },
      {
        path: ':id',
        children: [
          { index: true, element: <ProductDetail /> },
          { path: 'edit', element: <ProductEdit /> },
          {
            path: 'delete',
            element: (
              <>
                <ProductDetail />
                <DeleteDialog action="archive" entity="product" initialShow={true} />
              </>
            ),
            action: async ({ params }) => {
              await api.productsDelete(params.id!)
              await queryClient.invalidateQueries(productQueryKeys.list)
              return redirect('../..')
            },
          },
        ],
      },
    ],
  },
]
