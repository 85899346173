import { useQuery } from 'react-query'
import { api } from '../services/api'
import { type QueryOptions } from './query-utils'
import { Report } from '../generated'

export const reportingQueryKeys = {
  list: ['reporting'],
}

export const useAllReportsQuery = (options: QueryOptions<Report[]> = {}) =>
  useQuery({
    queryKey: reportingQueryKeys.list,
    queryFn: () => api.reportingList().then((r) => r.data),
    ...options,
  })
