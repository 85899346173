import { api } from '../services/api'
import { translate as t } from '../services/translation'
import React, { useEffect, useState } from 'react'
import { CenteredContentLayout } from '../components/layouts/centered-content-layout'
import { useSearchParams } from 'react-router-dom'

export function EmailVerification() {
  const [status, setStatus] = useState('')
  const [searchParams] = useSearchParams()

  useEffect(() => {
    const token = searchParams.get('token')
    if (token) {
      api
        .usersVerifyEmail(token)
        .then(() => setStatus('user.emailVerified'))
        .catch(() => setStatus('user.emailNotVerified'))
    }
  }, [searchParams])

  return (
    <CenteredContentLayout>
      <h2>{t('user.email')}</h2>
      <p>{t(status)}</p>
    </CenteredContentLayout>
  )
}
