import { Col, Form, Row } from 'react-bootstrap'
import { BsArchive, BsArrowLeft, BsPen, BsTrash } from 'react-icons/bs'
import { Link, useParams } from 'react-router-dom'
import { DetailField, Dl } from '../../../components/detail/detail-list'
import { CenteredContentLayout } from '../../../components/layouts/centered-content-layout'
import { useProductQuery } from '../../../queries/product-queries'
import { translate as t } from '../../../services/translation'
import { AssetManagerLink, ProductLink } from '../../../components/links'
import React from 'react'
import { IfStaff } from '../../../components/security/authorisation'

export const ProductDetail = () => {
  const { id } = useParams()
  // Queries
  const { data } = useProductQuery(id)

  if (id && !data) {
    return null
  }

  return (
    <CenteredContentLayout>
      <h1>{t('show', t('product'))}</h1>
      <Dl translationPath="product">
        <DetailField name="name">{data?.name}</DetailField>
        <DetailField name="reference">{data?.reference}</DetailField>
        <DetailField name="description">{data?.description}</DetailField>
        <DetailField name="riskProfile">{data?.riskProfile}</DetailField>
        <DetailField name="assetManager">
          <AssetManagerLink entity={data?.assetManager} />
        </DetailField>
        <DetailField name="url">
          {data?.url && (
            <a href={data.url} target="_blank" rel="noreferrer">
              {data.url}
            </a>
          )}
        </DetailField>
        <DetailField name="referenceProduct">
          <ProductLink entity={data?.referenceProduct} />
        </DetailField>
        <DetailField name="archived">
          {data?.archived && <BsArchive className="text-danger" />}
        </DetailField>
      </Dl>
      <Form.Group as={Row} className="mb-3">
        <Col>
          <IfStaff>
            <Link className="btn btn-primary me-2 icon-wrapper" to={`../${id}/edit`}>
              <BsPen />
              <span>{t('button.edit')}</span>
            </Link>
            <Link className="btn btn-danger me-2 icon-wrapper" to={`../${id}/delete`}>
              <BsTrash />
              <span>{t('button.delete')}</span>
            </Link>
            <Link className="btn btn-secondary icon-wrapper" to="..">
              <BsArrowLeft />
              <span>{t('button.back')}</span>
            </Link>
          </IfStaff>
        </Col>
      </Form.Group>
    </CenteredContentLayout>
  )
}
