import { redirect, RouteObject } from 'react-router-dom'
import { UserEdit } from './user-edit'
import { Users } from './users'
import { UserDetail } from './user-detail'
import { DeleteDialog } from '../../../components/form/delete-dialog'
import { api } from '../../../services/api'
import { queryClient } from '../../../queries/react-query-client'
import { userQueryKeys } from '../../../queries/user-queries'

export const routes: RouteObject[] = [
  {
    path: 'users',
    children: [
      { index: true, element: <Users /> },
      { path: 'new', element: <UserEdit /> },
      {
        path: ':username',
        children: [
          { index: true, element: <UserDetail /> },
          { path: 'edit', element: <UserEdit /> },
          {
            path: 'delete',
            element: (
              <>
                <UserDetail />
                <DeleteDialog action="delete" entity="user" initialShow={true} />
              </>
            ),
            action: async ({ params }) => {
              await api.usersDelete(params.username!)
              await queryClient.invalidateQueries(userQueryKeys.list)
              return redirect('/admin/users')
            },
          },
        ],
      },
    ],
  },
]
