import { toIsoDate } from './date-util'

// this is to ensure unique reference for all empty array
export const EMPTY_ARRAY = []

export function copy<T extends Object>(src: T, target: T): T {
  Object.getOwnPropertyNames(target).forEach((p) => delete target[p])
  Object.assign(target, src)
  return target
}

export function prepareForPost<T extends Object>(obj: T): T {
  Object.entries(obj).forEach(([name, value]) => {
    if (value instanceof Date) {
      obj[name] = isNaN(value.getTime()) ? undefined : toIsoDate(value)
    } else if (
      value === null ||
      Number.isNaN(value) ||
      (typeof value === 'string' && value.trim() === '')
    ) {
      delete obj[name]
    } else if (['true', 'false'].includes(value)) {
      obj[name] = value === 'true'
    }
  })
  return obj
}

export function get(data: any, path: string) {
  let result = data
  for (const prop of path.split(/\./)) {
    if (!(result instanceof Object)) {
      return undefined
    }
    result = result[prop]
  }
  return result
}
