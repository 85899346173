import { useParams } from 'react-router-dom'
import { useDossierQuery } from '../../queries/dossier-queries'
import { todayIso } from '../../util/date-util'
import { DateSelect } from '../../components/report/date-select'
import { WalletSelect } from '../../components/report/wallet-select'
import { translate as t } from '../../services/translation'
import { getLiquidValue, groupAssets } from '../../business-logic/asset'
import { dateFmt, moneyFmt, totalFmt } from '../../util/formatters'
import { riskCategory } from '../../business-logic/risk-analysis'
import { useState } from 'react'
import { Asset } from '../../generated'
import { api } from '../../services/api'
import { FullProductLink } from '../../components/links'
import { Table } from 'react-bootstrap'
import { AssetLink } from '../../components/asset/asset-modal'
import { debounce } from 'lodash'
import { BsCheck, BsX } from 'react-icons/bs'

function getWalletLiquidity(wallet, date) {
  const groups = groupAssets(wallet.assets || [], date, true)
  for (let group of groups) {
    group.show = group.assets.some((asset) => asset.frozen) || group.show
  }
  const walletTotal = groups
    .map((g) => g.total)
    .reduce((total, groupTotal) => total + groupTotal, 0)
  return { wallet, groups, walletTotal }
}

function getWalletLiquidities(wallets, date) {
  return wallets.map((w) => getWalletLiquidity(w, date)).filter((wl) => wl.walletTotal > 0)
}

export const Liquidity = () => {
  const { id } = useParams()
  const { data: dossier } = useDossierQuery(id, { refetchOnMount: false })
  const dossierWallets = dossier?.wallets || []
  let [date, setDate] = useState(todayIso())
  let [wallets, setWallets] = useState(dossierWallets)
  const [walletLiquidities, setWalletLiquidities] = useState(getWalletLiquidities(wallets, date))
  const description = wallets.map((w) => w.name).join(' / ')

  function updateLiquidities() {
    setWalletLiquidities(getWalletLiquidities(wallets, date))
  }

  function updateWallets(w) {
    wallets = w
    setWallets(wallets)
    updateLiquidities()
  }

  function updateDate(d) {
    date = d
    setDate(d)
    updateLiquidities()
  }

  return (
    <>
      <div className="row row-cols-auto align-items-center mb-3">
        <DateSelect date={date} setDate={updateDate} />
        <WalletSelect dossier={dossier} selectedWallets={wallets} setWallets={updateWallets} />
      </div>
      <h2>
        {t('dossier.liquidityTotal')} <small>{description}: </small>
        <span className="text-info">
          {totalFmt(walletLiquidities.reduce((total, wl) => total + wl.walletTotal, 0))}
        </span>
      </h2>
      {walletLiquidities.map((wl) => (
        <WalletLiquidityTable
          walletLiquidity={wl}
          date={date}
          key={wl.wallet.key}
          onLiquidityChange={updateLiquidities}
        />
      ))}
    </>
  )
}

const WalletLiquidityTable = ({ walletLiquidity, date, onLiquidityChange }) => {
  async function toggleFrozen(asset: Asset) {
    asset.frozen = !asset.frozen
    const response = await api.assetsUpdate(asset.id!, asset.key!, asset)
    Object.assign(asset, response.data)
    onLiquidityChange()
  }

  return (
    <>
      <h3>
        {t('dossier.liquidityOverview')} <small>{walletLiquidity.wallet.name}</small>
      </h3>
      <Table responsive>
        <thead>
          <tr>
            <th>
              {t('asset.reference')} / {t('asset.type')}
            </th>
            <th>{t('product')}</th>
            <th>{t('asset.startDate')}</th>
            <th>{t('asset.endDate')}</th>
            <th className="text-end">{t('amount')}</th>
          </tr>
        </thead>
        {walletLiquidity.groups.map(
          (group, groupIndex) =>
            group.show && (
              <tbody className="group wallet-group" key={groupIndex}>
                <tr className="info">
                  <th colSpan={2}>
                    {group.institution?.name} {group.reference && `- ${group.reference}`}{' '}
                  </th>
                  <th colSpan={4}>{dateFmt(group.startDate)}</th>
                </tr>
                {group.assets.map(
                  (asset) =>
                    (asset.frozen || getLiquidValue(asset, date)!.value > 0) && (
                      <tr className="asset" key={asset.key}>
                        <td>
                          <span
                            className="d-print-none"
                            role="button"
                            onClick={debounce(() => {
                              toggleFrozen(asset)
                            }, 750)}
                          >
                            {asset.frozen ? <BsX /> : <BsCheck />}
                          </span>
                          <span className={`profile ${riskCategory(asset.product.riskProfile!)}`}>
                            {' '}
                          </span>
                          <AssetLink
                            wallet={walletLiquidity.wallet}
                            asset={asset}
                            strikeThrough={asset.frozen}
                          />
                        </td>
                        <td>
                          <FullProductLink entity={asset.product} />
                        </td>
                        <td>{dateFmt(asset.startDate)}</td>
                        <td>{dateFmt(asset.endDate)}</td>
                        <td className="text-end">{moneyFmt(asset, date, true)}</td>
                      </tr>
                    )
                )}
                <tr className="subtotal">
                  <th colSpan={4}></th>
                  <th className="text-end">{totalFmt(group.total)}</th>
                  <th></th>
                </tr>
              </tbody>
            )
        )}
        {walletLiquidity.walletTotal > 0 && (
          <tbody>
            <tr className="total">
              <th colSpan={4}>Totaal {walletLiquidity.wallet.name}</th>
              <th className="text-end">{totalFmt(walletLiquidity.walletTotal)}</th>
              <th></th>
            </tr>
          </tbody>
        )}
      </Table>
    </>
  )
}
