import { useAuthenticator } from '@aws-amplify/ui-react'
import { Container, Nav, Navbar } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { MasterDataMenu } from './master-data-menu'
import { AdminMenu } from './admin-menu'
import { CurrentUserMenu } from './current-user-menu'
import { BsInfoCircle } from 'react-icons/bs'
import './header-navbar.scss'
import { IfAdmin, IfNotStaff, IfStaff } from '../security/authorisation'
import { translate as t } from '../../services/translation'
import { ENV_BG } from '../../config'

export const HeaderNavbar = () => {
  const { user, signOut } = useAuthenticator((context) => [context.user])
  const isAuthenticated = !!user
  return (
    <header>
      <Navbar bg={ENV_BG} variant="dark" expand="lg" sticky="top">
        <Container fluid>
          <Navbar.Brand as={Link} to="/">
            <img src="/favicon-32x32.png" alt="Feax" /> {t('assetArchitect')}
            <img src="/spinner.png" alt="spinner" id="spinner" className="d-none" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="header-navbar-nav" />
          <Navbar.Collapse id="header-navbar-nav">
            <Nav className="ms-auto">
              <IfNotStaff>
                <Nav.Link to="/dossiers/my-dossier" as={Link}>
                  {t('my.dossier')}
                </Nav.Link>
              </IfNotStaff>
              <IfStaff>
                <Nav.Link to="/dossiers" as={Link}>
                  {t('dossiers')}
                </Nav.Link>
                <MasterDataMenu user={user} />
              </IfStaff>
              <IfAdmin>
                <AdminMenu user={user} />
              </IfAdmin>
              {isAuthenticated && (
                <Nav.Link className="icon-wrapper" to="/about" as={Link}>
                  <BsInfoCircle />
                </Nav.Link>
              )}
              <CurrentUserMenu user={user} signOut={signOut} />
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  )
}
