import { redirect, RouteObject } from 'react-router-dom'
import { DeleteDialog } from '../../../components/form/delete-dialog'
import { institutionQueryKeys } from '../../../queries/institution-queries'
import { queryClient } from '../../../queries/react-query-client'
import { api } from '../../../services/api'
import { InstitutionDetail } from './institution-detail'
import { InstitutionEdit } from './institution-edit'
import { Institutions } from './institutions'

export const routes: RouteObject[] = [
  {
    path: 'institutions',
    children: [
      { index: true, element: <Institutions /> },
      { path: 'new', element: <InstitutionEdit /> },
      {
        path: ':id',
        children: [
          { index: true, element: <InstitutionDetail /> },
          { path: 'edit', element: <InstitutionEdit /> },
          {
            path: 'delete',
            element: (
              <>
                <InstitutionDetail />
                <DeleteDialog action="archive" entity="institution" initialShow={true} />
              </>
            ),
            action: async ({ params }) => {
              await api.institutionsDelete(params.id!)
              await queryClient.invalidateQueries(institutionQueryKeys.list)
              return redirect('../..')
            },
          },
        ],
      },
    ],
  },
]
