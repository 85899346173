/* tslint:disable */
/* eslint-disable */
/**
 * AssetArchitect API
 * example AWS Python Lambda application deployed with CDK
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * A financial asset (bank account, stock, insurance, etc.). Note: the name field is an alias for the reference field and is read-only. 
 * @export
 * @interface Asset
 */
export interface Asset {
    /**
     * Unique ID
     * @type {string}
     * @memberof Asset
     */
    'id'?: string;
    /**
     * A unique descriptive name.
     * @type {string}
     * @memberof Asset
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof Asset
     */
    'dateCreated'?: string;
    /**
     * 
     * @type {string}
     * @memberof Asset
     */
    'lastUpdated'?: string;
    /**
     * 
     * @type {string}
     * @memberof Asset
     */
    'createdBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof Asset
     */
    'updatedBy'?: string;
    /**
     * The version of the entity to be updated.
     * @type {number}
     * @memberof Asset
     */
    'version'?: number;
    /**
     * Unique ID
     * @type {string}
     * @memberof Asset
     */
    'key'?: string;
    /**
     * The key of the wallet this Asset belongs to
     * @type {string}
     * @memberof Asset
     */
    'walletKey'?: string;
    /**
     * The position of the asset within the wallet
     * @type {number}
     * @memberof Asset
     */
    'index': number;
    /**
     * Bank account number, contract number, etc.
     * @type {string}
     * @memberof Asset
     */
    'reference'?: string;
    /**
     * 
     * @type {string}
     * @memberof Asset
     */
    'type': AssetTypeEnum;
    /**
     * 
     * @type {AssetAllOfProduct}
     * @memberof Asset
     */
    'product': AssetAllOfProduct;
    /**
     * 
     * @type {AssetAllOfInstitution}
     * @memberof Asset
     */
    'institution': AssetAllOfInstitution;
    /**
     * The start date of the asset or policy
     * @type {string}
     * @memberof Asset
     */
    'startDate'?: string;
    /**
     * The end date of the asset or policy
     * @type {string}
     * @memberof Asset
     */
    'endDate'?: string;
    /**
     * Whether this asset is managed by Feax
     * @type {boolean}
     * @memberof Asset
     */
    'managedByFeax': boolean;
    /**
     * The asset has no liquid value
     * @type {boolean}
     * @memberof Asset
     */
    'frozen'?: boolean;
    /**
     * Whether the annual interest rate is visible for non-staff users
     * @type {boolean}
     * @memberof Asset
     */
    'showAnnualInterestRate'?: boolean;
    /**
     * Whether the asset should be hidden in the returns report
     * @type {boolean}
     * @memberof Asset
     */
    'hideReturns'?: boolean;
    /**
     * 
     * @type {Array<Value>}
     * @memberof Asset
     */
    'values'?: Array<Value>;
    /**
     * 
     * @type {string}
     * @memberof Asset
     */
    'policyHolder'?: string;
    /**
     * 
     * @type {string}
     * @memberof Asset
     */
    'insured'?: string;
    /**
     * 
     * @type {string}
     * @memberof Asset
     */
    'beneficiaryLife'?: string;
    /**
     * 
     * @type {string}
     * @memberof Asset
     */
    'beneficiaryDeath'?: string;
    /**
     * 
     * @type {string}
     * @memberof Asset
     */
    'acceptingBeneficiary'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Asset
     */
    'deathInsuranceCoverage130'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Asset
     */
    'gift'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Asset
     */
    'giftConditions'?: string;
    /**
     * 
     * @type {number}
     * @memberof Asset
     */
    'entryCost'?: number;
    /**
     * 
     * @type {number}
     * @memberof Asset
     */
    'premiumTax'?: number;
    /**
     * 
     * @type {string}
     * @memberof Asset
     */
    'premiumTaxType'?: string;
    /**
     * 
     * @type {string}
     * @memberof Asset
     */
    'guaranteedReturns'?: string;
    /**
     * 
     * @type {string}
     * @memberof Asset
     */
    'contractState'?: string;
    /**
     * 
     * @type {number}
     * @memberof Asset
     */
    'pensionAnnualPremium'?: number;
    /**
     * 
     * @type {Periodicity}
     * @memberof Asset
     */
    'pensionPeriodicity'?: Periodicity;
    /**
     * 
     * @type {number}
     * @memberof Asset
     */
    'pensionEntryCost'?: number;
    /**
     * 
     * @type {string}
     * @memberof Asset
     */
    'pensionIndexation'?: string;
    /**
     * 
     * @type {number}
     * @memberof Asset
     */
    'deathInsuredCapital'?: number;
    /**
     * 
     * @type {number}
     * @memberof Asset
     */
    'deathAnnualPremium'?: number;
    /**
     * 
     * @type {Periodicity}
     * @memberof Asset
     */
    'deathPeriodicity'?: Periodicity;
    /**
     * 
     * @type {number}
     * @memberof Asset
     */
    'invalidityMonthlyInterest'?: number;
    /**
     * 
     * @type {number}
     * @memberof Asset
     */
    'invalidityAnnualPremium'?: number;
    /**
     * 
     * @type {Periodicity}
     * @memberof Asset
     */
    'invalidityPeriodicity'?: Periodicity;
    /**
     * 
     * @type {number}
     * @memberof Asset
     */
    'invalidityWait'?: number;
    /**
     * 
     * @type {string}
     * @memberof Asset
     */
    'invalidityCoverageType'?: string;
    /**
     * 
     * @type {string}
     * @memberof Asset
     */
    'invalidityIndexation'?: string;
    /**
     * 
     * @type {number}
     * @memberof Asset
     */
    'premiumWaiverAnnualPremium'?: number;
    /**
     * 
     * @type {Periodicity}
     * @memberof Asset
     */
    'premiumWaiverPeriodicity'?: Periodicity;
    /**
     * 
     * @type {string}
     * @memberof Asset
     */
    'mainDueDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof Asset
     */
    'premiumPaymentEndDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof Asset
     */
    'insuranceType'?: string;
    /**
     * 
     * @type {number}
     * @memberof Asset
     */
    'advanceTaken'?: number;
    /**
     * 
     * @type {Periodicity}
     * @memberof Asset
     */
    'periodicity'?: Periodicity;
    /**
     * 
     * @type {number}
     * @memberof Asset
     */
    'monthlyPremium'?: number;
    /**
     * 
     * @type {number}
     * @memberof Asset
     */
    'annualPremium'?: number;
    /**
     * 
     * @type {string}
     * @memberof Asset
     */
    'additionalWarrants'?: string;
    /**
     * 
     * @type {number}
     * @memberof Asset
     */
    'monthlyAmount'?: number;
    /**
     * 
     * @type {number}
     * @memberof Asset
     */
    'insuredMonthlyInterest'?: number;
    /**
     * 
     * @type {string}
     * @memberof Asset
     */
    'indexation'?: string;
    /**
     * 
     * @type {string}
     * @memberof Asset
     */
    'remarks'?: string;
    /**
     * 
     * @type {number}
     * @memberof Asset
     */
    'annualRentalIncome'?: number;
    /**
     * 
     * @type {string}
     * @memberof Asset
     */
    'contractId'?: string;
    /**
     * 
     * @type {string}
     * @memberof Asset
     */
    'lender'?: string;
    /**
     * 
     * @type {number}
     * @memberof Asset
     */
    'interestRate'?: number;
    /**
     * 
     * @type {string}
     * @memberof Asset
     */
    'variability'?: string;
    /**
     * 
     * @type {number}
     * @memberof Asset
     */
    'startAmount'?: number;
    /**
     * 
     * @type {string}
     * @memberof Asset
     */
    'cls'?: AssetClsEnum;
}

export const AssetTypeEnum = {
    T21: 'T21',
    T23: 'T23',
    T26: 'T26',
    T21Ps: 'T21-PS',
    T21Lts: 'T21-LTS',
    T21Ipt: 'T21-IPT',
    T21Vapz: 'T21-VAPZ',
    T21Riziv: 'T21-RIZIV',
    T23Ps: 'T23-PS',
    T23Ipt: 'T23-IPT',
    BpT21T23: 'BP-T21/T23',
    BpBc: 'BP-BC',
    Bc: 'BC',
    BcPs: 'BC-PS',
    Ovl: 'OVL',
    Omzet: 'OMZET',
    Vastgoed: 'VASTGOED',
    Krediet: 'KREDIET'
} as const;

export type AssetTypeEnum = typeof AssetTypeEnum[keyof typeof AssetTypeEnum];
export const AssetClsEnum = {
    Asset: 'Asset'
} as const;

export type AssetClsEnum = typeof AssetClsEnum[keyof typeof AssetClsEnum];

/**
 * 
 * @export
 * @interface AssetAllOfInstitution
 */
export interface AssetAllOfInstitution {
    /**
     * Unique ID
     * @type {string}
     * @memberof AssetAllOfInstitution
     */
    'id': string;
    /**
     * A unique descriptive name.
     * @type {string}
     * @memberof AssetAllOfInstitution
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssetAllOfInstitution
     */
    'dateCreated'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssetAllOfInstitution
     */
    'lastUpdated'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssetAllOfInstitution
     */
    'createdBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssetAllOfInstitution
     */
    'updatedBy'?: string;
    /**
     * The version of the entity to be updated.
     * @type {number}
     * @memberof AssetAllOfInstitution
     */
    'version'?: number;
    /**
     * The website of the institution
     * @type {string}
     * @memberof AssetAllOfInstitution
     */
    'url'?: string;
    /**
     * Mark as archived (not in use anymore)
     * @type {boolean}
     * @memberof AssetAllOfInstitution
     */
    'archived'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AssetAllOfInstitution
     */
    'cls'?: AssetAllOfInstitutionClsEnum;
}

export const AssetAllOfInstitutionClsEnum = {
    Institution: 'Institution'
} as const;

export type AssetAllOfInstitutionClsEnum = typeof AssetAllOfInstitutionClsEnum[keyof typeof AssetAllOfInstitutionClsEnum];

/**
 * 
 * @export
 * @interface AssetAllOfProduct
 */
export interface AssetAllOfProduct {
    /**
     * Unique ID
     * @type {string}
     * @memberof AssetAllOfProduct
     */
    'id': string;
    /**
     * A unique descriptive name.
     * @type {string}
     * @memberof AssetAllOfProduct
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssetAllOfProduct
     */
    'dateCreated'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssetAllOfProduct
     */
    'lastUpdated'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssetAllOfProduct
     */
    'createdBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssetAllOfProduct
     */
    'updatedBy'?: string;
    /**
     * The version of the entity to be updated.
     * @type {number}
     * @memberof AssetAllOfProduct
     */
    'version'?: number;
    /**
     * The website with information about the product
     * @type {string}
     * @memberof AssetAllOfProduct
     */
    'url'?: string;
    /**
     * A commonly used string as reference to this product (f.e. ISIN, product code, etc.)
     * @type {string}
     * @memberof AssetAllOfProduct
     */
    'reference'?: string;
    /**
     * A free text description of the product
     * @type {string}
     * @memberof AssetAllOfProduct
     */
    'description'?: string;
    /**
     * The risk profile of this product. A number between 0 (lowest risk) and 7 (highest risk)
     * @type {number}
     * @memberof AssetAllOfProduct
     */
    'riskProfile'?: number;
    /**
     * 
     * @type {Entity}
     * @memberof AssetAllOfProduct
     */
    'assetManager'?: Entity;
    /**
     * 
     * @type {ProductDataAllOfReferenceProduct}
     * @memberof AssetAllOfProduct
     */
    'referenceProduct'?: ProductDataAllOfReferenceProduct;
    /**
     * The minimum term for this product
     * @type {number}
     * @memberof AssetAllOfProduct
     */
    'minimumDuration'?: number;
    /**
     * The maximum amount that can be withdrawn before the end of the product\'s term
     * @type {number}
     * @memberof AssetAllOfProduct
     */
    'maxEarlyWithdrawal'?: number;
    /**
     * The maximum percentage of the total amount that can be withdrawn before the end of the product\'s term
     * @type {number}
     * @memberof AssetAllOfProduct
     */
    'maxEarlyWithdrawalPercentage'?: number;
    /**
     * Mark as archived (not in use anymore)
     * @type {boolean}
     * @memberof AssetAllOfProduct
     */
    'archived'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AssetAllOfProduct
     */
    'cls'?: AssetAllOfProductClsEnum;
}

export const AssetAllOfProductClsEnum = {
    Product: 'Product'
} as const;

export type AssetAllOfProductClsEnum = typeof AssetAllOfProductClsEnum[keyof typeof AssetAllOfProductClsEnum];

/**
 * 
 * @export
 * @interface AssetManager
 */
export interface AssetManager {
    /**
     * Unique ID
     * @type {string}
     * @memberof AssetManager
     */
    'id'?: string;
    /**
     * A unique descriptive name.
     * @type {string}
     * @memberof AssetManager
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof AssetManager
     */
    'dateCreated'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssetManager
     */
    'lastUpdated'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssetManager
     */
    'createdBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssetManager
     */
    'updatedBy'?: string;
    /**
     * The version of the entity to be updated.
     * @type {number}
     * @memberof AssetManager
     */
    'version'?: number;
    /**
     * The website of the asset manager
     * @type {string}
     * @memberof AssetManager
     */
    'url'?: string;
    /**
     * Mark as archived (not in use anymore)
     * @type {boolean}
     * @memberof AssetManager
     */
    'archived'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AssetManager
     */
    'cls'?: AssetManagerClsEnum;
}

export const AssetManagerClsEnum = {
    AssetManager: 'AssetManager'
} as const;

export type AssetManagerClsEnum = typeof AssetManagerClsEnum[keyof typeof AssetManagerClsEnum];

/**
 * 
 * @export
 * @interface AssetManagerData
 */
export interface AssetManagerData {
    /**
     * Unique ID
     * @type {string}
     * @memberof AssetManagerData
     */
    'id'?: string;
    /**
     * A unique descriptive name.
     * @type {string}
     * @memberof AssetManagerData
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssetManagerData
     */
    'dateCreated'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssetManagerData
     */
    'lastUpdated'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssetManagerData
     */
    'createdBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssetManagerData
     */
    'updatedBy'?: string;
    /**
     * The version of the entity to be updated.
     * @type {number}
     * @memberof AssetManagerData
     */
    'version'?: number;
    /**
     * The website of the asset manager
     * @type {string}
     * @memberof AssetManagerData
     */
    'url'?: string;
    /**
     * Mark as archived (not in use anymore)
     * @type {boolean}
     * @memberof AssetManagerData
     */
    'archived'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AssetManagerData
     */
    'cls'?: AssetManagerDataClsEnum;
}

export const AssetManagerDataClsEnum = {
    AssetManager: 'AssetManager'
} as const;

export type AssetManagerDataClsEnum = typeof AssetManagerDataClsEnum[keyof typeof AssetManagerDataClsEnum];

/**
 * 
 * @export
 * @interface AssetManagersList200ResponseInner
 */
export interface AssetManagersList200ResponseInner {
    /**
     * Unique ID
     * @type {string}
     * @memberof AssetManagersList200ResponseInner
     */
    'id': string;
    /**
     * A unique descriptive name.
     * @type {string}
     * @memberof AssetManagersList200ResponseInner
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof AssetManagersList200ResponseInner
     */
    'dateCreated'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssetManagersList200ResponseInner
     */
    'lastUpdated'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssetManagersList200ResponseInner
     */
    'createdBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssetManagersList200ResponseInner
     */
    'updatedBy'?: string;
    /**
     * The version of the entity to be updated.
     * @type {number}
     * @memberof AssetManagersList200ResponseInner
     */
    'version'?: number;
    /**
     * The website of the asset manager
     * @type {string}
     * @memberof AssetManagersList200ResponseInner
     */
    'url'?: string;
    /**
     * Mark as archived (not in use anymore)
     * @type {boolean}
     * @memberof AssetManagersList200ResponseInner
     */
    'archived'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AssetManagersList200ResponseInner
     */
    'cls'?: AssetManagersList200ResponseInnerClsEnum;
}

export const AssetManagersList200ResponseInnerClsEnum = {
    AssetManager: 'AssetManager'
} as const;

export type AssetManagersList200ResponseInnerClsEnum = typeof AssetManagersList200ResponseInnerClsEnum[keyof typeof AssetManagersList200ResponseInnerClsEnum];

/**
 * 
 * @export
 * @interface AssetsGet200Response
 */
export interface AssetsGet200Response {
    /**
     * Unique ID
     * @type {string}
     * @memberof AssetsGet200Response
     */
    'id': string;
    /**
     * A unique descriptive name.
     * @type {string}
     * @memberof AssetsGet200Response
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssetsGet200Response
     */
    'dateCreated'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssetsGet200Response
     */
    'lastUpdated'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssetsGet200Response
     */
    'createdBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssetsGet200Response
     */
    'updatedBy'?: string;
    /**
     * The version of the entity to be updated.
     * @type {number}
     * @memberof AssetsGet200Response
     */
    'version'?: number;
    /**
     * Unique ID
     * @type {string}
     * @memberof AssetsGet200Response
     */
    'key': string;
    /**
     * The key of the wallet this Asset belongs to
     * @type {string}
     * @memberof AssetsGet200Response
     */
    'walletKey': string;
    /**
     * The position of the asset within the wallet
     * @type {number}
     * @memberof AssetsGet200Response
     */
    'index': number;
    /**
     * Bank account number, contract number, etc.
     * @type {string}
     * @memberof AssetsGet200Response
     */
    'reference'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssetsGet200Response
     */
    'type': AssetsGet200ResponseTypeEnum;
    /**
     * 
     * @type {AssetAllOfProduct}
     * @memberof AssetsGet200Response
     */
    'product': AssetAllOfProduct;
    /**
     * 
     * @type {AssetAllOfInstitution}
     * @memberof AssetsGet200Response
     */
    'institution': AssetAllOfInstitution;
    /**
     * The start date of the asset or policy
     * @type {string}
     * @memberof AssetsGet200Response
     */
    'startDate'?: string;
    /**
     * The end date of the asset or policy
     * @type {string}
     * @memberof AssetsGet200Response
     */
    'endDate'?: string;
    /**
     * Whether this asset is managed by Feax
     * @type {boolean}
     * @memberof AssetsGet200Response
     */
    'managedByFeax': boolean;
    /**
     * The asset has no liquid value
     * @type {boolean}
     * @memberof AssetsGet200Response
     */
    'frozen'?: boolean;
    /**
     * Whether the annual interest rate is visible for non-staff users
     * @type {boolean}
     * @memberof AssetsGet200Response
     */
    'showAnnualInterestRate'?: boolean;
    /**
     * Whether the asset should be hidden in the returns report
     * @type {boolean}
     * @memberof AssetsGet200Response
     */
    'hideReturns'?: boolean;
    /**
     * 
     * @type {Array<Value>}
     * @memberof AssetsGet200Response
     */
    'values'?: Array<Value>;
    /**
     * 
     * @type {string}
     * @memberof AssetsGet200Response
     */
    'policyHolder'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssetsGet200Response
     */
    'insured'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssetsGet200Response
     */
    'beneficiaryLife'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssetsGet200Response
     */
    'beneficiaryDeath'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssetsGet200Response
     */
    'acceptingBeneficiary'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AssetsGet200Response
     */
    'deathInsuranceCoverage130'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AssetsGet200Response
     */
    'gift'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AssetsGet200Response
     */
    'giftConditions'?: string;
    /**
     * 
     * @type {number}
     * @memberof AssetsGet200Response
     */
    'entryCost'?: number;
    /**
     * 
     * @type {number}
     * @memberof AssetsGet200Response
     */
    'premiumTax'?: number;
    /**
     * 
     * @type {string}
     * @memberof AssetsGet200Response
     */
    'premiumTaxType'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssetsGet200Response
     */
    'guaranteedReturns'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssetsGet200Response
     */
    'contractState'?: string;
    /**
     * 
     * @type {number}
     * @memberof AssetsGet200Response
     */
    'pensionAnnualPremium'?: number;
    /**
     * 
     * @type {Periodicity}
     * @memberof AssetsGet200Response
     */
    'pensionPeriodicity'?: Periodicity;
    /**
     * 
     * @type {number}
     * @memberof AssetsGet200Response
     */
    'pensionEntryCost'?: number;
    /**
     * 
     * @type {string}
     * @memberof AssetsGet200Response
     */
    'pensionIndexation'?: string;
    /**
     * 
     * @type {number}
     * @memberof AssetsGet200Response
     */
    'deathInsuredCapital'?: number;
    /**
     * 
     * @type {number}
     * @memberof AssetsGet200Response
     */
    'deathAnnualPremium'?: number;
    /**
     * 
     * @type {Periodicity}
     * @memberof AssetsGet200Response
     */
    'deathPeriodicity'?: Periodicity;
    /**
     * 
     * @type {number}
     * @memberof AssetsGet200Response
     */
    'invalidityMonthlyInterest'?: number;
    /**
     * 
     * @type {number}
     * @memberof AssetsGet200Response
     */
    'invalidityAnnualPremium'?: number;
    /**
     * 
     * @type {Periodicity}
     * @memberof AssetsGet200Response
     */
    'invalidityPeriodicity'?: Periodicity;
    /**
     * 
     * @type {number}
     * @memberof AssetsGet200Response
     */
    'invalidityWait'?: number;
    /**
     * 
     * @type {string}
     * @memberof AssetsGet200Response
     */
    'invalidityCoverageType'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssetsGet200Response
     */
    'invalidityIndexation'?: string;
    /**
     * 
     * @type {number}
     * @memberof AssetsGet200Response
     */
    'premiumWaiverAnnualPremium'?: number;
    /**
     * 
     * @type {Periodicity}
     * @memberof AssetsGet200Response
     */
    'premiumWaiverPeriodicity'?: Periodicity;
    /**
     * 
     * @type {string}
     * @memberof AssetsGet200Response
     */
    'mainDueDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssetsGet200Response
     */
    'premiumPaymentEndDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssetsGet200Response
     */
    'insuranceType'?: string;
    /**
     * 
     * @type {number}
     * @memberof AssetsGet200Response
     */
    'advanceTaken'?: number;
    /**
     * 
     * @type {Periodicity}
     * @memberof AssetsGet200Response
     */
    'periodicity'?: Periodicity;
    /**
     * 
     * @type {number}
     * @memberof AssetsGet200Response
     */
    'monthlyPremium'?: number;
    /**
     * 
     * @type {number}
     * @memberof AssetsGet200Response
     */
    'annualPremium'?: number;
    /**
     * 
     * @type {string}
     * @memberof AssetsGet200Response
     */
    'additionalWarrants'?: string;
    /**
     * 
     * @type {number}
     * @memberof AssetsGet200Response
     */
    'monthlyAmount'?: number;
    /**
     * 
     * @type {number}
     * @memberof AssetsGet200Response
     */
    'insuredMonthlyInterest'?: number;
    /**
     * 
     * @type {string}
     * @memberof AssetsGet200Response
     */
    'indexation'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssetsGet200Response
     */
    'remarks'?: string;
    /**
     * 
     * @type {number}
     * @memberof AssetsGet200Response
     */
    'annualRentalIncome'?: number;
    /**
     * 
     * @type {string}
     * @memberof AssetsGet200Response
     */
    'contractId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssetsGet200Response
     */
    'lender'?: string;
    /**
     * 
     * @type {number}
     * @memberof AssetsGet200Response
     */
    'interestRate'?: number;
    /**
     * 
     * @type {string}
     * @memberof AssetsGet200Response
     */
    'variability'?: string;
    /**
     * 
     * @type {number}
     * @memberof AssetsGet200Response
     */
    'startAmount'?: number;
    /**
     * 
     * @type {string}
     * @memberof AssetsGet200Response
     */
    'cls'?: AssetsGet200ResponseClsEnum;
}

export const AssetsGet200ResponseTypeEnum = {
    T21: 'T21',
    T23: 'T23',
    T26: 'T26',
    T21Ps: 'T21-PS',
    T21Lts: 'T21-LTS',
    T21Ipt: 'T21-IPT',
    T21Vapz: 'T21-VAPZ',
    T21Riziv: 'T21-RIZIV',
    T23Ps: 'T23-PS',
    T23Ipt: 'T23-IPT',
    BpT21T23: 'BP-T21/T23',
    BpBc: 'BP-BC',
    Bc: 'BC',
    BcPs: 'BC-PS',
    Ovl: 'OVL',
    Omzet: 'OMZET',
    Vastgoed: 'VASTGOED',
    Krediet: 'KREDIET'
} as const;

export type AssetsGet200ResponseTypeEnum = typeof AssetsGet200ResponseTypeEnum[keyof typeof AssetsGet200ResponseTypeEnum];
export const AssetsGet200ResponseClsEnum = {
    Asset: 'Asset'
} as const;

export type AssetsGet200ResponseClsEnum = typeof AssetsGet200ResponseClsEnum[keyof typeof AssetsGet200ResponseClsEnum];

/**
 * 
 * @export
 * @interface BatchUpdateUpdateAssetValuesRequestInner
 */
export interface BatchUpdateUpdateAssetValuesRequestInner {
    /**
     * Dossier ID
     * @type {string}
     * @memberof BatchUpdateUpdateAssetValuesRequestInner
     */
    'id': string;
    /**
     * Asset Key
     * @type {string}
     * @memberof BatchUpdateUpdateAssetValuesRequestInner
     */
    'key': string;
    /**
     * 
     * @type {Value}
     * @memberof BatchUpdateUpdateAssetValuesRequestInner
     */
    'value': Value;
}
/**
 * A financial dossier
 * @export
 * @interface Dossier
 */
export interface Dossier {
    /**
     * Unique ID
     * @type {string}
     * @memberof Dossier
     */
    'id'?: string;
    /**
     * A unique descriptive name.
     * @type {string}
     * @memberof Dossier
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Dossier
     */
    'dateCreated'?: string;
    /**
     * 
     * @type {string}
     * @memberof Dossier
     */
    'lastUpdated'?: string;
    /**
     * 
     * @type {string}
     * @memberof Dossier
     */
    'createdBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof Dossier
     */
    'updatedBy'?: string;
    /**
     * The version of the entity to be updated.
     * @type {number}
     * @memberof Dossier
     */
    'version'?: number;
    /**
     * A description of the dossier
     * @type {string}
     * @memberof Dossier
     */
    'description'?: string;
    /**
     * 
     * @type {Profile}
     * @memberof Dossier
     */
    'profile': Profile;
    /**
     * The starting date for tracking the dossier\'s evolution
     * @type {string}
     * @memberof Dossier
     */
    'evolutionStartDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof Dossier
     */
    'cls'?: DossierClsEnum;
    /**
     * 
     * @type {Array<Wallet>}
     * @memberof Dossier
     */
    'wallets'?: Array<Wallet>;
}

export const DossierClsEnum = {
    DossierInfo: 'DossierInfo'
} as const;

export type DossierClsEnum = typeof DossierClsEnum[keyof typeof DossierClsEnum];

/**
 * Properties of a financial dossier
 * @export
 * @interface DossierInfo
 */
export interface DossierInfo {
    /**
     * Unique ID
     * @type {string}
     * @memberof DossierInfo
     */
    'id'?: string;
    /**
     * A unique descriptive name.
     * @type {string}
     * @memberof DossierInfo
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof DossierInfo
     */
    'dateCreated'?: string;
    /**
     * 
     * @type {string}
     * @memberof DossierInfo
     */
    'lastUpdated'?: string;
    /**
     * 
     * @type {string}
     * @memberof DossierInfo
     */
    'createdBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof DossierInfo
     */
    'updatedBy'?: string;
    /**
     * The version of the entity to be updated.
     * @type {number}
     * @memberof DossierInfo
     */
    'version'?: number;
    /**
     * A description of the dossier
     * @type {string}
     * @memberof DossierInfo
     */
    'description'?: string;
    /**
     * 
     * @type {Profile}
     * @memberof DossierInfo
     */
    'profile': Profile;
    /**
     * The starting date for tracking the dossier\'s evolution
     * @type {string}
     * @memberof DossierInfo
     */
    'evolutionStartDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof DossierInfo
     */
    'cls'?: DossierInfoClsEnum;
}

export const DossierInfoClsEnum = {
    DossierInfo: 'DossierInfo'
} as const;

export type DossierInfoClsEnum = typeof DossierInfoClsEnum[keyof typeof DossierInfoClsEnum];

/**
 * 
 * @export
 * @interface DossierInfosGet200Response
 */
export interface DossierInfosGet200Response {
    /**
     * Unique ID
     * @type {string}
     * @memberof DossierInfosGet200Response
     */
    'id': string;
    /**
     * A unique descriptive name.
     * @type {string}
     * @memberof DossierInfosGet200Response
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof DossierInfosGet200Response
     */
    'dateCreated'?: string;
    /**
     * 
     * @type {string}
     * @memberof DossierInfosGet200Response
     */
    'lastUpdated'?: string;
    /**
     * 
     * @type {string}
     * @memberof DossierInfosGet200Response
     */
    'createdBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof DossierInfosGet200Response
     */
    'updatedBy'?: string;
    /**
     * The version of the entity to be updated.
     * @type {number}
     * @memberof DossierInfosGet200Response
     */
    'version'?: number;
    /**
     * A description of the dossier
     * @type {string}
     * @memberof DossierInfosGet200Response
     */
    'description'?: string;
    /**
     * 
     * @type {Profile}
     * @memberof DossierInfosGet200Response
     */
    'profile': Profile;
    /**
     * The starting date for tracking the dossier\'s evolution
     * @type {string}
     * @memberof DossierInfosGet200Response
     */
    'evolutionStartDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof DossierInfosGet200Response
     */
    'cls'?: DossierInfosGet200ResponseClsEnum;
}

export const DossierInfosGet200ResponseClsEnum = {
    DossierInfo: 'DossierInfo'
} as const;

export type DossierInfosGet200ResponseClsEnum = typeof DossierInfosGet200ResponseClsEnum[keyof typeof DossierInfosGet200ResponseClsEnum];

/**
 * A financial dossier
 * @export
 * @interface DossierSummary
 */
export interface DossierSummary {
    /**
     * Unique ID
     * @type {string}
     * @memberof DossierSummary
     */
    'id'?: string;
    /**
     * A unique descriptive name.
     * @type {string}
     * @memberof DossierSummary
     */
    'name'?: string;
    /**
     * 
     * @type {Profile}
     * @memberof DossierSummary
     */
    'profile'?: Profile;
    /**
     * Mark as archived (not in use anymore)
     * @type {boolean}
     * @memberof DossierSummary
     */
    'archived'?: boolean;
}


/**
 * 
 * @export
 * @interface DossiersGet200Response
 */
export interface DossiersGet200Response {
    /**
     * Unique ID
     * @type {string}
     * @memberof DossiersGet200Response
     */
    'id': string;
    /**
     * A unique descriptive name.
     * @type {string}
     * @memberof DossiersGet200Response
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof DossiersGet200Response
     */
    'dateCreated'?: string;
    /**
     * 
     * @type {string}
     * @memberof DossiersGet200Response
     */
    'lastUpdated'?: string;
    /**
     * 
     * @type {string}
     * @memberof DossiersGet200Response
     */
    'createdBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof DossiersGet200Response
     */
    'updatedBy'?: string;
    /**
     * The version of the entity to be updated.
     * @type {number}
     * @memberof DossiersGet200Response
     */
    'version'?: number;
    /**
     * A description of the dossier
     * @type {string}
     * @memberof DossiersGet200Response
     */
    'description'?: string;
    /**
     * 
     * @type {Profile}
     * @memberof DossiersGet200Response
     */
    'profile': Profile;
    /**
     * The starting date for tracking the dossier\'s evolution
     * @type {string}
     * @memberof DossiersGet200Response
     */
    'evolutionStartDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof DossiersGet200Response
     */
    'cls'?: DossiersGet200ResponseClsEnum;
    /**
     * 
     * @type {Array<Wallet>}
     * @memberof DossiersGet200Response
     */
    'wallets'?: Array<Wallet>;
}

export const DossiersGet200ResponseClsEnum = {
    DossierInfo: 'DossierInfo'
} as const;

export type DossiersGet200ResponseClsEnum = typeof DossiersGet200ResponseClsEnum[keyof typeof DossiersGet200ResponseClsEnum];

/**
 * 
 * @export
 * @interface DossiersList200ResponseInner
 */
export interface DossiersList200ResponseInner {
    /**
     * Unique ID
     * @type {string}
     * @memberof DossiersList200ResponseInner
     */
    'id': string;
    /**
     * A unique descriptive name.
     * @type {string}
     * @memberof DossiersList200ResponseInner
     */
    'name'?: string;
    /**
     * 
     * @type {Profile}
     * @memberof DossiersList200ResponseInner
     */
    'profile'?: Profile;
    /**
     * Mark as archived (not in use anymore)
     * @type {boolean}
     * @memberof DossiersList200ResponseInner
     */
    'archived'?: boolean;
}


/**
 * 
 * @export
 * @interface Entity
 */
export interface Entity {
    /**
     * Unique ID
     * @type {string}
     * @memberof Entity
     */
    'id'?: string;
    /**
     * A unique descriptive name.
     * @type {string}
     * @memberof Entity
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof Entity
     */
    'dateCreated'?: string;
    /**
     * 
     * @type {string}
     * @memberof Entity
     */
    'lastUpdated'?: string;
    /**
     * 
     * @type {string}
     * @memberof Entity
     */
    'createdBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof Entity
     */
    'updatedBy'?: string;
    /**
     * The version of the entity to be updated.
     * @type {number}
     * @memberof Entity
     */
    'version'?: number;
}
/**
 * 
 * @export
 * @interface Institution
 */
export interface Institution {
    /**
     * Unique ID
     * @type {string}
     * @memberof Institution
     */
    'id'?: string;
    /**
     * A unique descriptive name.
     * @type {string}
     * @memberof Institution
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Institution
     */
    'dateCreated'?: string;
    /**
     * 
     * @type {string}
     * @memberof Institution
     */
    'lastUpdated'?: string;
    /**
     * 
     * @type {string}
     * @memberof Institution
     */
    'createdBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof Institution
     */
    'updatedBy'?: string;
    /**
     * The version of the entity to be updated.
     * @type {number}
     * @memberof Institution
     */
    'version'?: number;
    /**
     * The website of the institution
     * @type {string}
     * @memberof Institution
     */
    'url'?: string;
    /**
     * Mark as archived (not in use anymore)
     * @type {boolean}
     * @memberof Institution
     */
    'archived'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Institution
     */
    'cls'?: InstitutionClsEnum;
}

export const InstitutionClsEnum = {
    Institution: 'Institution'
} as const;

export type InstitutionClsEnum = typeof InstitutionClsEnum[keyof typeof InstitutionClsEnum];

/**
 * 
 * @export
 * @interface InstitutionData
 */
export interface InstitutionData {
    /**
     * Unique ID
     * @type {string}
     * @memberof InstitutionData
     */
    'id'?: string;
    /**
     * A unique descriptive name.
     * @type {string}
     * @memberof InstitutionData
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof InstitutionData
     */
    'dateCreated'?: string;
    /**
     * 
     * @type {string}
     * @memberof InstitutionData
     */
    'lastUpdated'?: string;
    /**
     * 
     * @type {string}
     * @memberof InstitutionData
     */
    'createdBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof InstitutionData
     */
    'updatedBy'?: string;
    /**
     * The version of the entity to be updated.
     * @type {number}
     * @memberof InstitutionData
     */
    'version'?: number;
    /**
     * The website of the institution
     * @type {string}
     * @memberof InstitutionData
     */
    'url'?: string;
    /**
     * Mark as archived (not in use anymore)
     * @type {boolean}
     * @memberof InstitutionData
     */
    'archived'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof InstitutionData
     */
    'cls'?: InstitutionDataClsEnum;
}

export const InstitutionDataClsEnum = {
    Institution: 'Institution'
} as const;

export type InstitutionDataClsEnum = typeof InstitutionDataClsEnum[keyof typeof InstitutionDataClsEnum];

/**
 * 
 * @export
 * @interface InstitutionsList200ResponseInner
 */
export interface InstitutionsList200ResponseInner {
    /**
     * Unique ID
     * @type {string}
     * @memberof InstitutionsList200ResponseInner
     */
    'id': string;
    /**
     * A unique descriptive name.
     * @type {string}
     * @memberof InstitutionsList200ResponseInner
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof InstitutionsList200ResponseInner
     */
    'dateCreated'?: string;
    /**
     * 
     * @type {string}
     * @memberof InstitutionsList200ResponseInner
     */
    'lastUpdated'?: string;
    /**
     * 
     * @type {string}
     * @memberof InstitutionsList200ResponseInner
     */
    'createdBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof InstitutionsList200ResponseInner
     */
    'updatedBy'?: string;
    /**
     * The version of the entity to be updated.
     * @type {number}
     * @memberof InstitutionsList200ResponseInner
     */
    'version'?: number;
    /**
     * The website of the institution
     * @type {string}
     * @memberof InstitutionsList200ResponseInner
     */
    'url'?: string;
    /**
     * Mark as archived (not in use anymore)
     * @type {boolean}
     * @memberof InstitutionsList200ResponseInner
     */
    'archived'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof InstitutionsList200ResponseInner
     */
    'cls'?: InstitutionsList200ResponseInnerClsEnum;
}

export const InstitutionsList200ResponseInnerClsEnum = {
    Institution: 'Institution'
} as const;

export type InstitutionsList200ResponseInnerClsEnum = typeof InstitutionsList200ResponseInnerClsEnum[keyof typeof InstitutionsList200ResponseInnerClsEnum];

/**
 * 
 * @export
 * @enum {string}
 */

export const Periodicity = {
    Monthly: 'MONTHLY',
    Semestrial: 'SEMESTRIAL',
    Quarterly: 'QUARTERLY',
    Yearly: 'YEARLY'
} as const;

export type Periodicity = typeof Periodicity[keyof typeof Periodicity];


/**
 * 
 * @export
 * @interface Product
 */
export interface Product {
    /**
     * Unique ID
     * @type {string}
     * @memberof Product
     */
    'id'?: string;
    /**
     * A unique descriptive name.
     * @type {string}
     * @memberof Product
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'dateCreated'?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'lastUpdated'?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'createdBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'updatedBy'?: string;
    /**
     * The version of the entity to be updated.
     * @type {number}
     * @memberof Product
     */
    'version'?: number;
    /**
     * The website with information about the product
     * @type {string}
     * @memberof Product
     */
    'url'?: string;
    /**
     * A commonly used string as reference to this product (f.e. ISIN, product code, etc.)
     * @type {string}
     * @memberof Product
     */
    'reference'?: string;
    /**
     * A free text description of the product
     * @type {string}
     * @memberof Product
     */
    'description'?: string;
    /**
     * The risk profile of this product. A number between 0 (lowest risk) and 7 (highest risk)
     * @type {number}
     * @memberof Product
     */
    'riskProfile': number;
    /**
     * 
     * @type {Entity}
     * @memberof Product
     */
    'assetManager'?: Entity;
    /**
     * 
     * @type {ProductDataAllOfReferenceProduct}
     * @memberof Product
     */
    'referenceProduct'?: ProductDataAllOfReferenceProduct;
    /**
     * The minimum term for this product
     * @type {number}
     * @memberof Product
     */
    'minimumDuration'?: number;
    /**
     * The maximum amount that can be withdrawn before the end of the product\'s term
     * @type {number}
     * @memberof Product
     */
    'maxEarlyWithdrawal'?: number;
    /**
     * The maximum percentage of the total amount that can be withdrawn before the end of the product\'s term
     * @type {number}
     * @memberof Product
     */
    'maxEarlyWithdrawalPercentage'?: number;
    /**
     * Mark as archived (not in use anymore)
     * @type {boolean}
     * @memberof Product
     */
    'archived'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'cls'?: ProductClsEnum;
}

export const ProductClsEnum = {
    Product: 'Product'
} as const;

export type ProductClsEnum = typeof ProductClsEnum[keyof typeof ProductClsEnum];

/**
 * 
 * @export
 * @interface ProductData
 */
export interface ProductData {
    /**
     * Unique ID
     * @type {string}
     * @memberof ProductData
     */
    'id'?: string;
    /**
     * A unique descriptive name.
     * @type {string}
     * @memberof ProductData
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductData
     */
    'dateCreated'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductData
     */
    'lastUpdated'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductData
     */
    'createdBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductData
     */
    'updatedBy'?: string;
    /**
     * The version of the entity to be updated.
     * @type {number}
     * @memberof ProductData
     */
    'version'?: number;
    /**
     * The website with information about the product
     * @type {string}
     * @memberof ProductData
     */
    'url'?: string;
    /**
     * A commonly used string as reference to this product (f.e. ISIN, product code, etc.)
     * @type {string}
     * @memberof ProductData
     */
    'reference'?: string;
    /**
     * A free text description of the product
     * @type {string}
     * @memberof ProductData
     */
    'description'?: string;
    /**
     * The risk profile of this product. A number between 0 (lowest risk) and 7 (highest risk)
     * @type {number}
     * @memberof ProductData
     */
    'riskProfile'?: number;
    /**
     * 
     * @type {Entity}
     * @memberof ProductData
     */
    'assetManager'?: Entity;
    /**
     * 
     * @type {ProductDataAllOfReferenceProduct}
     * @memberof ProductData
     */
    'referenceProduct'?: ProductDataAllOfReferenceProduct;
    /**
     * The minimum term for this product
     * @type {number}
     * @memberof ProductData
     */
    'minimumDuration'?: number;
    /**
     * The maximum amount that can be withdrawn before the end of the product\'s term
     * @type {number}
     * @memberof ProductData
     */
    'maxEarlyWithdrawal'?: number;
    /**
     * The maximum percentage of the total amount that can be withdrawn before the end of the product\'s term
     * @type {number}
     * @memberof ProductData
     */
    'maxEarlyWithdrawalPercentage'?: number;
    /**
     * Mark as archived (not in use anymore)
     * @type {boolean}
     * @memberof ProductData
     */
    'archived'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ProductData
     */
    'cls'?: ProductDataClsEnum;
}

export const ProductDataClsEnum = {
    Product: 'Product'
} as const;

export type ProductDataClsEnum = typeof ProductDataClsEnum[keyof typeof ProductDataClsEnum];

/**
 * 
 * @export
 * @interface ProductDataAllOfReferenceProduct
 */
export interface ProductDataAllOfReferenceProduct {
    /**
     * Unique ID
     * @type {string}
     * @memberof ProductDataAllOfReferenceProduct
     */
    'id': string;
    /**
     * A unique descriptive name.
     * @type {string}
     * @memberof ProductDataAllOfReferenceProduct
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductDataAllOfReferenceProduct
     */
    'dateCreated'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductDataAllOfReferenceProduct
     */
    'lastUpdated'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductDataAllOfReferenceProduct
     */
    'createdBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductDataAllOfReferenceProduct
     */
    'updatedBy'?: string;
    /**
     * The version of the entity to be updated.
     * @type {number}
     * @memberof ProductDataAllOfReferenceProduct
     */
    'version'?: number;
}
/**
 * 
 * @export
 * @interface ProductsList200ResponseInner
 */
export interface ProductsList200ResponseInner {
    /**
     * Unique ID
     * @type {string}
     * @memberof ProductsList200ResponseInner
     */
    'id': string;
    /**
     * A unique descriptive name.
     * @type {string}
     * @memberof ProductsList200ResponseInner
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ProductsList200ResponseInner
     */
    'dateCreated'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductsList200ResponseInner
     */
    'lastUpdated'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductsList200ResponseInner
     */
    'createdBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductsList200ResponseInner
     */
    'updatedBy'?: string;
    /**
     * The version of the entity to be updated.
     * @type {number}
     * @memberof ProductsList200ResponseInner
     */
    'version'?: number;
    /**
     * The website with information about the product
     * @type {string}
     * @memberof ProductsList200ResponseInner
     */
    'url'?: string;
    /**
     * A commonly used string as reference to this product (f.e. ISIN, product code, etc.)
     * @type {string}
     * @memberof ProductsList200ResponseInner
     */
    'reference'?: string;
    /**
     * A free text description of the product
     * @type {string}
     * @memberof ProductsList200ResponseInner
     */
    'description'?: string;
    /**
     * The risk profile of this product. A number between 0 (lowest risk) and 7 (highest risk)
     * @type {number}
     * @memberof ProductsList200ResponseInner
     */
    'riskProfile': number;
    /**
     * 
     * @type {Entity}
     * @memberof ProductsList200ResponseInner
     */
    'assetManager'?: Entity;
    /**
     * 
     * @type {ProductDataAllOfReferenceProduct}
     * @memberof ProductsList200ResponseInner
     */
    'referenceProduct'?: ProductDataAllOfReferenceProduct;
    /**
     * The minimum term for this product
     * @type {number}
     * @memberof ProductsList200ResponseInner
     */
    'minimumDuration'?: number;
    /**
     * The maximum amount that can be withdrawn before the end of the product\'s term
     * @type {number}
     * @memberof ProductsList200ResponseInner
     */
    'maxEarlyWithdrawal'?: number;
    /**
     * The maximum percentage of the total amount that can be withdrawn before the end of the product\'s term
     * @type {number}
     * @memberof ProductsList200ResponseInner
     */
    'maxEarlyWithdrawalPercentage'?: number;
    /**
     * Mark as archived (not in use anymore)
     * @type {boolean}
     * @memberof ProductsList200ResponseInner
     */
    'archived'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ProductsList200ResponseInner
     */
    'cls'?: ProductsList200ResponseInnerClsEnum;
}

export const ProductsList200ResponseInnerClsEnum = {
    Product: 'Product'
} as const;

export type ProductsList200ResponseInnerClsEnum = typeof ProductsList200ResponseInnerClsEnum[keyof typeof ProductsList200ResponseInnerClsEnum];

/**
 * 
 * @export
 * @enum {string}
 */

export const Profile = {
    Conservative: 'CONSERVATIVE',
    Defensive: 'DEFENSIVE',
    Balanced: 'BALANCED',
    Dynamic: 'DYNAMIC',
    HighlyDynamic: 'HIGHLY_DYNAMIC'
} as const;

export type Profile = typeof Profile[keyof typeof Profile];


/**
 * 
 * @export
 * @interface Report
 */
export interface Report {
    /**
     * 
     * @type {string}
     * @memberof Report
     */
    'filename'?: string;
    /**
     * 
     * @type {string}
     * @memberof Report
     */
    'url'?: string;
}
/**
 * 
 * @export
 * @interface SyncStatus
 */
export interface SyncStatus {
    /**
     * 
     * @type {string}
     * @memberof SyncStatus
     */
    'assetId'?: string;
    /**
     * 
     * @type {string}
     * @memberof SyncStatus
     */
    'assetKey'?: string;
    /**
     * 
     * @type {string}
     * @memberof SyncStatus
     */
    'currentValidFrom'?: string;
    /**
     * 
     * @type {number}
     * @memberof SyncStatus
     */
    'currentValue'?: number;
    /**
     * 
     * @type {string}
     * @memberof SyncStatus
     */
    'status'?: string;
    /**
     * 
     * @type {SyncStatusBaloiseAsset}
     * @memberof SyncStatus
     */
    'baloiseAsset'?: SyncStatusBaloiseAsset;
}
/**
 * 
 * @export
 * @interface SyncStatusBaloiseAsset
 */
export interface SyncStatusBaloiseAsset {
    /**
     * 
     * @type {string}
     * @memberof SyncStatusBaloiseAsset
     */
    'assetReference'?: string;
    /**
     * 
     * @type {string}
     * @memberof SyncStatusBaloiseAsset
     */
    'currency'?: string;
    /**
     * 
     * @type {string}
     * @memberof SyncStatusBaloiseAsset
     */
    'productName'?: string;
    /**
     * 
     * @type {string}
     * @memberof SyncStatusBaloiseAsset
     */
    'productReference'?: string;
    /**
     * 
     * @type {number}
     * @memberof SyncStatusBaloiseAsset
     */
    'value'?: number;
    /**
     * 
     * @type {string}
     * @memberof SyncStatusBaloiseAsset
     */
    'validFrom'?: string;
}
/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    'multiFactor'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'temporaryPassword'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof User
     */
    'dossiers'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'username'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'email'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    'enabled'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    'staff'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    'admin'?: boolean;
}
/**
 * 
 * @export
 * @interface UserSummary
 */
export interface UserSummary {
    /**
     * 
     * @type {string}
     * @memberof UserSummary
     */
    'username'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserSummary
     */
    'email'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserSummary
     */
    'enabled'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserSummary
     */
    'staff'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserSummary
     */
    'admin'?: boolean;
}
/**
 * 
 * @export
 * @interface UsersList200ResponseInner
 */
export interface UsersList200ResponseInner {
    /**
     * 
     * @type {string}
     * @memberof UsersList200ResponseInner
     */
    'username': string;
    /**
     * 
     * @type {string}
     * @memberof UsersList200ResponseInner
     */
    'email'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UsersList200ResponseInner
     */
    'enabled'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UsersList200ResponseInner
     */
    'staff'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UsersList200ResponseInner
     */
    'admin'?: boolean;
}
/**
 * 
 * @export
 * @interface UsersListDossierUsers200ResponseInner
 */
export interface UsersListDossierUsers200ResponseInner {
    /**
     * 
     * @type {boolean}
     * @memberof UsersListDossierUsers200ResponseInner
     */
    'multiFactor'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UsersListDossierUsers200ResponseInner
     */
    'temporaryPassword'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UsersListDossierUsers200ResponseInner
     */
    'dossiers'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof UsersListDossierUsers200ResponseInner
     */
    'username': string;
    /**
     * 
     * @type {string}
     * @memberof UsersListDossierUsers200ResponseInner
     */
    'email'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UsersListDossierUsers200ResponseInner
     */
    'enabled'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UsersListDossierUsers200ResponseInner
     */
    'staff'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UsersListDossierUsers200ResponseInner
     */
    'admin'?: boolean;
}
/**
 * 
 * @export
 * @interface UsersSetEmail200Response
 */
export interface UsersSetEmail200Response {
    /**
     * Whether a verification email was sent.
     * @type {boolean}
     * @memberof UsersSetEmail200Response
     */
    'verificationSent'?: boolean;
}
/**
 * 
 * @export
 * @interface UsersSetEmailRequest
 */
export interface UsersSetEmailRequest {
    /**
     * 
     * @type {string}
     * @memberof UsersSetEmailRequest
     */
    'email'?: string | null;
}
/**
 * The value of an asset at a certain point in time
 * @export
 * @interface Value
 */
export interface Value {
    /**
     * The value in Euros with 2 decimals precision
     * @type {number}
     * @memberof Value
     */
    'value': number;
    /**
     * The date as of which the value is valid
     * @type {string}
     * @memberof Value
     */
    'validFrom': string;
    /**
     * The amount deposited (+) or withdrawn (-)
     * @type {number}
     * @memberof Value
     */
    'amountChange'?: number;
    /**
     * The cost associated with the deposit or withdrawal
     * @type {number}
     * @memberof Value
     */
    'entryCost'?: number;
}
/**
 * A collection of assets within a dossier
 * @export
 * @interface Wallet
 */
export interface Wallet {
    /**
     * Unique ID
     * @type {string}
     * @memberof Wallet
     */
    'id'?: string;
    /**
     * A unique descriptive name.
     * @type {string}
     * @memberof Wallet
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Wallet
     */
    'dateCreated'?: string;
    /**
     * 
     * @type {string}
     * @memberof Wallet
     */
    'lastUpdated'?: string;
    /**
     * 
     * @type {string}
     * @memberof Wallet
     */
    'createdBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof Wallet
     */
    'updatedBy'?: string;
    /**
     * The version of the entity to be updated.
     * @type {number}
     * @memberof Wallet
     */
    'version'?: number;
    /**
     * Unique ID
     * @type {string}
     * @memberof Wallet
     */
    'key'?: string;
    /**
     * The position of the wallet inside the dossier
     * @type {number}
     * @memberof Wallet
     */
    'index': number;
    /**
     * Whether this wallet should have its own summary report
     * @type {boolean}
     * @memberof Wallet
     */
    'individualSummary'?: boolean;
    /**
     * Whether this wallet should be included in the global dossier summary report
     * @type {boolean}
     * @memberof Wallet
     */
    'includeInGlobalSummary'?: boolean;
    /**
     * 
     * @type {Array<Asset>}
     * @memberof Wallet
     */
    'assets'?: Array<Asset>;
    /**
     * 
     * @type {string}
     * @memberof Wallet
     */
    'cls'?: WalletClsEnum;
}

export const WalletClsEnum = {
    Wallet: 'Wallet'
} as const;

export type WalletClsEnum = typeof WalletClsEnum[keyof typeof WalletClsEnum];

/**
 * 
 * @export
 * @interface WalletsGet200Response
 */
export interface WalletsGet200Response {
    /**
     * Unique ID
     * @type {string}
     * @memberof WalletsGet200Response
     */
    'id': string;
    /**
     * A unique descriptive name.
     * @type {string}
     * @memberof WalletsGet200Response
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof WalletsGet200Response
     */
    'dateCreated'?: string;
    /**
     * 
     * @type {string}
     * @memberof WalletsGet200Response
     */
    'lastUpdated'?: string;
    /**
     * 
     * @type {string}
     * @memberof WalletsGet200Response
     */
    'createdBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof WalletsGet200Response
     */
    'updatedBy'?: string;
    /**
     * The version of the entity to be updated.
     * @type {number}
     * @memberof WalletsGet200Response
     */
    'version'?: number;
    /**
     * Unique ID
     * @type {string}
     * @memberof WalletsGet200Response
     */
    'key': string;
    /**
     * The position of the wallet inside the dossier
     * @type {number}
     * @memberof WalletsGet200Response
     */
    'index': number;
    /**
     * Whether this wallet should have its own summary report
     * @type {boolean}
     * @memberof WalletsGet200Response
     */
    'individualSummary'?: boolean;
    /**
     * Whether this wallet should be included in the global dossier summary report
     * @type {boolean}
     * @memberof WalletsGet200Response
     */
    'includeInGlobalSummary'?: boolean;
    /**
     * 
     * @type {Array<Asset>}
     * @memberof WalletsGet200Response
     */
    'assets'?: Array<Asset>;
    /**
     * 
     * @type {string}
     * @memberof WalletsGet200Response
     */
    'cls'?: WalletsGet200ResponseClsEnum;
}

export const WalletsGet200ResponseClsEnum = {
    Wallet: 'Wallet'
} as const;

export type WalletsGet200ResponseClsEnum = typeof WalletsGet200ResponseClsEnum[keyof typeof WalletsGet200ResponseClsEnum];


/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Delete an asset manager
         * @param {string} id unique ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetManagersDelete: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('assetManagersDelete', 'id', id)
            const localVarPath = `/asset-managers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get an assetManager
         * @param {string} id unique ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetManagersGet: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('assetManagersGet', 'id', id)
            const localVarPath = `/asset-managers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List all asset managers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetManagersList: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/asset-managers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a new assetManager
         * @param {AssetManager} assetManager 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetManagersSave: async (assetManager: AssetManager, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'assetManager' is not null or undefined
            assertParamExists('assetManagersSave', 'assetManager', assetManager)
            const localVarPath = `/asset-managers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(assetManager, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update an assetManager
         * @param {string} id unique ID
         * @param {AssetManager} assetManager 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetManagersUpdate: async (id: string, assetManager: AssetManager, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('assetManagersUpdate', 'id', id)
            // verify required parameter 'assetManager' is not null or undefined
            assertParamExists('assetManagersUpdate', 'assetManager', assetManager)
            const localVarPath = `/asset-managers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(assetManager, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete an asset
         * @param {string} id unique ID
         * @param {string} key unique ID within the dossier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetsDelete: async (id: string, key: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('assetsDelete', 'id', id)
            // verify required parameter 'key' is not null or undefined
            assertParamExists('assetsDelete', 'key', key)
            const localVarPath = `/dossiers/{id}/assets/{key}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get an asset
         * @param {string} id unique ID
         * @param {string} key unique ID within the dossier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetsGet: async (id: string, key: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('assetsGet', 'id', id)
            // verify required parameter 'key' is not null or undefined
            assertParamExists('assetsGet', 'key', key)
            const localVarPath = `/dossiers/{id}/assets/{key}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Add an asset to a wallet
         * @param {string} id unique ID
         * @param {Asset} asset 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetsSave: async (id: string, asset: Asset, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('assetsSave', 'id', id)
            // verify required parameter 'asset' is not null or undefined
            assertParamExists('assetsSave', 'asset', asset)
            const localVarPath = `/dossiers/{id}/assets`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(asset, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update an asset
         * @param {string} id unique ID
         * @param {string} key unique ID within the dossier
         * @param {Asset} asset 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetsUpdate: async (id: string, key: string, asset: Asset, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('assetsUpdate', 'id', id)
            // verify required parameter 'key' is not null or undefined
            assertParamExists('assetsUpdate', 'key', key)
            // verify required parameter 'asset' is not null or undefined
            assertParamExists('assetsUpdate', 'asset', asset)
            const localVarPath = `/dossiers/{id}/assets/{key}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(asset, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Compare the values from a Baloise CSV file with the stored asset values.
         * @param {string} [separator] The separator used to separate the columns in the CSV file.
         * @param {File} [filename] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchUpdateBaloiseSync: async (separator?: string, filename?: File, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/baloise-sync`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            if (separator !== undefined) {
                localVarQueryParameter['separator'] = separator;
            }


            if (filename !== undefined) { 
                localVarFormParams.append('filename', filename as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update asset values in batch
         * @param {Array<BatchUpdateUpdateAssetValuesRequestInner>} batchUpdateUpdateAssetValuesRequestInner 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchUpdateUpdateAssetValues: async (batchUpdateUpdateAssetValuesRequestInner: Array<BatchUpdateUpdateAssetValuesRequestInner>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'batchUpdateUpdateAssetValuesRequestInner' is not null or undefined
            assertParamExists('batchUpdateUpdateAssetValues', 'batchUpdateUpdateAssetValuesRequestInner', batchUpdateUpdateAssetValuesRequestInner)
            const localVarPath = `/dossiers/batch-update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(batchUpdateUpdateAssetValuesRequestInner, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a dossier
         * @param {string} id unique ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dossierInfosDelete: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('dossierInfosDelete', 'id', id)
            const localVarPath = `/dossiers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the dossier\'s info.
         * @param {string} id unique ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dossierInfosGet: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('dossierInfosGet', 'id', id)
            const localVarPath = `/dossiers/{id}/info`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update the dossier\'s info. Only the provided properties will be updated.
         * @param {string} id unique ID
         * @param {DossierInfo} dossierInfo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dossierInfosUpdate: async (id: string, dossierInfo: DossierInfo, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('dossierInfosUpdate', 'id', id)
            // verify required parameter 'dossierInfo' is not null or undefined
            assertParamExists('dossierInfosUpdate', 'dossierInfo', dossierInfo)
            const localVarPath = `/dossiers/{id}/info`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dossierInfo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get a dossier
         * @param {string} id unique ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dossiersGet: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('dossiersGet', 'id', id)
            const localVarPath = `/dossiers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List summaries of all dossiers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dossiersList: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/dossiers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a new dossier
         * @param {Dossier} dossier 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dossiersSave: async (dossier: Dossier, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dossier' is not null or undefined
            assertParamExists('dossiersSave', 'dossier', dossier)
            const localVarPath = `/dossiers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dossier, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get information about the application
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        info: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete an institution
         * @param {string} id unique ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        institutionsDelete: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('institutionsDelete', 'id', id)
            const localVarPath = `/institutions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get an institution
         * @param {string} id unique ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        institutionsGet: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('institutionsGet', 'id', id)
            const localVarPath = `/institutions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List all institutions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        institutionsList: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/institutions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a new institution
         * @param {Institution} institution 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        institutionsSave: async (institution: Institution, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'institution' is not null or undefined
            assertParamExists('institutionsSave', 'institution', institution)
            const localVarPath = `/institutions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(institution, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update an institution
         * @param {string} id unique ID
         * @param {Institution} institution 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        institutionsUpdate: async (id: string, institution: Institution, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('institutionsUpdate', 'id', id)
            // verify required parameter 'institution' is not null or undefined
            assertParamExists('institutionsUpdate', 'institution', institution)
            const localVarPath = `/institutions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(institution, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a product
         * @param {string} id unique ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsDelete: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('productsDelete', 'id', id)
            const localVarPath = `/products/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get a product
         * @param {string} id unique ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsGet: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('productsGet', 'id', id)
            const localVarPath = `/products/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List all products
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsList: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/products`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a new product
         * @param {Product} product 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsSave: async (product: Product, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'product' is not null or undefined
            assertParamExists('productsSave', 'product', product)
            const localVarPath = `/products`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(product, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update a product
         * @param {string} id unique ID
         * @param {Product} product 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsUpdate: async (id: string, product: Product, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('productsUpdate', 'id', id)
            // verify required parameter 'product' is not null or undefined
            assertParamExists('productsUpdate', 'product', product)
            const localVarPath = `/products/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(product, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Generate a new excel report.
         * @param {string} [date] The valuation date.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportingGenerate: async (date?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/reporting`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (date !== undefined) {
                localVarQueryParameter['date'] = (date as any instanceof Date) ?
                    (date as any).toISOString().substring(0,10) :
                    date;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List all available excel reports.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportingList: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/reporting`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Grant the user access to a dossier.
         * @param {string} username unique username
         * @param {string} dossierId dossier ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersAddDossier: async (username: string, dossierId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'username' is not null or undefined
            assertParamExists('usersAddDossier', 'username', username)
            // verify required parameter 'dossierId' is not null or undefined
            assertParamExists('usersAddDossier', 'dossierId', dossierId)
            const localVarPath = `/users/{username}/dossiers/{dossierId}`
                .replace(`{${"username"}}`, encodeURIComponent(String(username)))
                .replace(`{${"dossierId"}}`, encodeURIComponent(String(dossierId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a user
         * @param {string} username unique username
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersDelete: async (username: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'username' is not null or undefined
            assertParamExists('usersDelete', 'username', username)
            const localVarPath = `/users/{username}`
                .replace(`{${"username"}}`, encodeURIComponent(String(username)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get user details
         * @param {string} username unique username
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersGet: async (username: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'username' is not null or undefined
            assertParamExists('usersGet', 'username', username)
            const localVarPath = `/users/{username}`
                .replace(`{${"username"}}`, encodeURIComponent(String(username)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersList: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List the users that have access to this dossier.
         * @param {string} id unique ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersListDossierUsers: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('usersListDossierUsers', 'id', id)
            const localVarPath = `/dossiers/{id}/users`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Revoke the user\'s access to a dossier.
         * @param {string} username unique username
         * @param {string} dossierId dossier ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersRemoveDossier: async (username: string, dossierId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'username' is not null or undefined
            assertParamExists('usersRemoveDossier', 'username', username)
            // verify required parameter 'dossierId' is not null or undefined
            assertParamExists('usersRemoveDossier', 'dossierId', dossierId)
            const localVarPath = `/users/{username}/dossiers/{dossierId}`
                .replace(`{${"username"}}`, encodeURIComponent(String(username)))
                .replace(`{${"dossierId"}}`, encodeURIComponent(String(dossierId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a new user
         * @param {UsersListDossierUsers200ResponseInner} usersListDossierUsers200ResponseInner 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersSave: async (usersListDossierUsers200ResponseInner: UsersListDossierUsers200ResponseInner, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'usersListDossierUsers200ResponseInner' is not null or undefined
            assertParamExists('usersSave', 'usersListDossierUsers200ResponseInner', usersListDossierUsers200ResponseInner)
            const localVarPath = `/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(usersListDossierUsers200ResponseInner, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Set the user\'s email address.
         * @param {string} username unique username
         * @param {UsersSetEmailRequest} usersSetEmailRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersSetEmail: async (username: string, usersSetEmailRequest: UsersSetEmailRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'username' is not null or undefined
            assertParamExists('usersSetEmail', 'username', username)
            // verify required parameter 'usersSetEmailRequest' is not null or undefined
            assertParamExists('usersSetEmail', 'usersSetEmailRequest', usersSetEmailRequest)
            const localVarPath = `/users/{username}/email`
                .replace(`{${"username"}}`, encodeURIComponent(String(username)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(usersSetEmailRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update user details
         * @param {string} username unique username
         * @param {User} user 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersUpdate: async (username: string, user: User, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'username' is not null or undefined
            assertParamExists('usersUpdate', 'username', username)
            // verify required parameter 'user' is not null or undefined
            assertParamExists('usersUpdate', 'user', user)
            const localVarPath = `/users/{username}`
                .replace(`{${"username"}}`, encodeURIComponent(String(username)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(user, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Verify the user\'s email address with the provided token.
         * @param {string} token The verification token.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersVerifyEmail: async (token: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            assertParamExists('usersVerifyEmail', 'token', token)
            const localVarPath = `/users/verify-email`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a wallet
         * @param {string} id unique ID
         * @param {string} key unique ID within the dossier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        walletsDelete: async (id: string, key: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('walletsDelete', 'id', id)
            // verify required parameter 'key' is not null or undefined
            assertParamExists('walletsDelete', 'key', key)
            const localVarPath = `/dossiers/{id}/wallets/{key}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get a wallet.
         * @param {string} id unique ID
         * @param {string} key unique ID within the dossier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        walletsGet: async (id: string, key: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('walletsGet', 'id', id)
            // verify required parameter 'key' is not null or undefined
            assertParamExists('walletsGet', 'key', key)
            const localVarPath = `/dossiers/{id}/wallets/{key}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Add a wallet to a dossier.
         * @param {string} id unique ID
         * @param {Wallet} wallet 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        walletsSave: async (id: string, wallet: Wallet, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('walletsSave', 'id', id)
            // verify required parameter 'wallet' is not null or undefined
            assertParamExists('walletsSave', 'wallet', wallet)
            const localVarPath = `/dossiers/{id}/wallets`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(wallet, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update a wallet
         * @param {string} id unique ID
         * @param {string} key unique ID within the dossier
         * @param {Wallet} wallet 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        walletsUpdate: async (id: string, key: string, wallet: Wallet, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('walletsUpdate', 'id', id)
            // verify required parameter 'key' is not null or undefined
            assertParamExists('walletsUpdate', 'key', key)
            // verify required parameter 'wallet' is not null or undefined
            assertParamExists('walletsUpdate', 'wallet', wallet)
            const localVarPath = `/dossiers/{id}/wallets/{key}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(wallet, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * Delete an asset manager
         * @param {string} id unique ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assetManagersDelete(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assetManagersDelete(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.assetManagersDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get an assetManager
         * @param {string} id unique ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assetManagersGet(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssetManagersList200ResponseInner>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assetManagersGet(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.assetManagersGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * List all asset managers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assetManagersList(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AssetManagersList200ResponseInner>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assetManagersList(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.assetManagersList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Create a new assetManager
         * @param {AssetManager} assetManager 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assetManagersSave(assetManager: AssetManager, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssetManager>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assetManagersSave(assetManager, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.assetManagersSave']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Update an assetManager
         * @param {string} id unique ID
         * @param {AssetManager} assetManager 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assetManagersUpdate(id: string, assetManager: AssetManager, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssetManager>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assetManagersUpdate(id, assetManager, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.assetManagersUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Delete an asset
         * @param {string} id unique ID
         * @param {string} key unique ID within the dossier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assetsDelete(id: string, key: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assetsDelete(id, key, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.assetsDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get an asset
         * @param {string} id unique ID
         * @param {string} key unique ID within the dossier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assetsGet(id: string, key: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssetsGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assetsGet(id, key, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.assetsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Add an asset to a wallet
         * @param {string} id unique ID
         * @param {Asset} asset 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assetsSave(id: string, asset: Asset, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Asset>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assetsSave(id, asset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.assetsSave']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Update an asset
         * @param {string} id unique ID
         * @param {string} key unique ID within the dossier
         * @param {Asset} asset 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assetsUpdate(id: string, key: string, asset: Asset, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Asset>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assetsUpdate(id, key, asset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.assetsUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Compare the values from a Baloise CSV file with the stored asset values.
         * @param {string} [separator] The separator used to separate the columns in the CSV file.
         * @param {File} [filename] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async batchUpdateBaloiseSync(separator?: string, filename?: File, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SyncStatus>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.batchUpdateBaloiseSync(separator, filename, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.batchUpdateBaloiseSync']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Update asset values in batch
         * @param {Array<BatchUpdateUpdateAssetValuesRequestInner>} batchUpdateUpdateAssetValuesRequestInner 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async batchUpdateUpdateAssetValues(batchUpdateUpdateAssetValuesRequestInner: Array<BatchUpdateUpdateAssetValuesRequestInner>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.batchUpdateUpdateAssetValues(batchUpdateUpdateAssetValuesRequestInner, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.batchUpdateUpdateAssetValues']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Delete a dossier
         * @param {string} id unique ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dossierInfosDelete(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dossierInfosDelete(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.dossierInfosDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get the dossier\'s info.
         * @param {string} id unique ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dossierInfosGet(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DossierInfosGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dossierInfosGet(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.dossierInfosGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Update the dossier\'s info. Only the provided properties will be updated.
         * @param {string} id unique ID
         * @param {DossierInfo} dossierInfo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dossierInfosUpdate(id: string, dossierInfo: DossierInfo, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DossierInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dossierInfosUpdate(id, dossierInfo, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.dossierInfosUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get a dossier
         * @param {string} id unique ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dossiersGet(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DossiersGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dossiersGet(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.dossiersGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * List summaries of all dossiers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dossiersList(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DossiersList200ResponseInner>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dossiersList(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.dossiersList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Create a new dossier
         * @param {Dossier} dossier 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dossiersSave(dossier: Dossier, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Dossier>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dossiersSave(dossier, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.dossiersSave']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get information about the application
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async info(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.info(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.info']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Delete an institution
         * @param {string} id unique ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async institutionsDelete(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.institutionsDelete(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.institutionsDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get an institution
         * @param {string} id unique ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async institutionsGet(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InstitutionsList200ResponseInner>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.institutionsGet(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.institutionsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * List all institutions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async institutionsList(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<InstitutionsList200ResponseInner>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.institutionsList(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.institutionsList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Create a new institution
         * @param {Institution} institution 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async institutionsSave(institution: Institution, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Institution>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.institutionsSave(institution, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.institutionsSave']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Update an institution
         * @param {string} id unique ID
         * @param {Institution} institution 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async institutionsUpdate(id: string, institution: Institution, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Institution>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.institutionsUpdate(id, institution, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.institutionsUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Delete a product
         * @param {string} id unique ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productsDelete(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productsDelete(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.productsDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get a product
         * @param {string} id unique ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productsGet(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductsList200ResponseInner>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productsGet(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.productsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * List all products
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productsList(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProductsList200ResponseInner>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productsList(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.productsList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Create a new product
         * @param {Product} product 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productsSave(product: Product, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Product>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productsSave(product, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.productsSave']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Update a product
         * @param {string} id unique ID
         * @param {Product} product 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productsUpdate(id: string, product: Product, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Product>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productsUpdate(id, product, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.productsUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Generate a new excel report.
         * @param {string} [date] The valuation date.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportingGenerate(date?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reportingGenerate(date, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.reportingGenerate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * List all available excel reports.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportingList(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Report>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reportingList(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.reportingList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Grant the user access to a dossier.
         * @param {string} username unique username
         * @param {string} dossierId dossier ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersAddDossier(username: string, dossierId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersAddDossier(username, dossierId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.usersAddDossier']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Delete a user
         * @param {string} username unique username
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersDelete(username: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersDelete(username, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.usersDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get user details
         * @param {string} username unique username
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersGet(username: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UsersListDossierUsers200ResponseInner>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersGet(username, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.usersGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * List users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersList(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UsersList200ResponseInner>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersList(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.usersList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * List the users that have access to this dossier.
         * @param {string} id unique ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersListDossierUsers(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UsersListDossierUsers200ResponseInner>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersListDossierUsers(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.usersListDossierUsers']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Revoke the user\'s access to a dossier.
         * @param {string} username unique username
         * @param {string} dossierId dossier ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersRemoveDossier(username: string, dossierId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersRemoveDossier(username, dossierId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.usersRemoveDossier']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Create a new user
         * @param {UsersListDossierUsers200ResponseInner} usersListDossierUsers200ResponseInner 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersSave(usersListDossierUsers200ResponseInner: UsersListDossierUsers200ResponseInner, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersSave(usersListDossierUsers200ResponseInner, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.usersSave']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Set the user\'s email address.
         * @param {string} username unique username
         * @param {UsersSetEmailRequest} usersSetEmailRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersSetEmail(username: string, usersSetEmailRequest: UsersSetEmailRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UsersSetEmail200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersSetEmail(username, usersSetEmailRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.usersSetEmail']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Update user details
         * @param {string} username unique username
         * @param {User} user 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersUpdate(username: string, user: User, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersUpdate(username, user, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.usersUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Verify the user\'s email address with the provided token.
         * @param {string} token The verification token.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersVerifyEmail(token: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersVerifyEmail(token, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.usersVerifyEmail']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Delete a wallet
         * @param {string} id unique ID
         * @param {string} key unique ID within the dossier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async walletsDelete(id: string, key: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.walletsDelete(id, key, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.walletsDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get a wallet.
         * @param {string} id unique ID
         * @param {string} key unique ID within the dossier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async walletsGet(id: string, key: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WalletsGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.walletsGet(id, key, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.walletsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Add a wallet to a dossier.
         * @param {string} id unique ID
         * @param {Wallet} wallet 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async walletsSave(id: string, wallet: Wallet, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Wallet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.walletsSave(id, wallet, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.walletsSave']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Update a wallet
         * @param {string} id unique ID
         * @param {string} key unique ID within the dossier
         * @param {Wallet} wallet 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async walletsUpdate(id: string, key: string, wallet: Wallet, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Wallet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.walletsUpdate(id, key, wallet, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.walletsUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * Delete an asset manager
         * @param {string} id unique ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetManagersDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.assetManagersDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Get an assetManager
         * @param {string} id unique ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetManagersGet(id: string, options?: any): AxiosPromise<AssetManagersList200ResponseInner> {
            return localVarFp.assetManagersGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * List all asset managers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetManagersList(options?: any): AxiosPromise<Array<AssetManagersList200ResponseInner>> {
            return localVarFp.assetManagersList(options).then((request) => request(axios, basePath));
        },
        /**
         * Create a new assetManager
         * @param {AssetManager} assetManager 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetManagersSave(assetManager: AssetManager, options?: any): AxiosPromise<AssetManager> {
            return localVarFp.assetManagersSave(assetManager, options).then((request) => request(axios, basePath));
        },
        /**
         * Update an assetManager
         * @param {string} id unique ID
         * @param {AssetManager} assetManager 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetManagersUpdate(id: string, assetManager: AssetManager, options?: any): AxiosPromise<AssetManager> {
            return localVarFp.assetManagersUpdate(id, assetManager, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete an asset
         * @param {string} id unique ID
         * @param {string} key unique ID within the dossier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetsDelete(id: string, key: string, options?: any): AxiosPromise<void> {
            return localVarFp.assetsDelete(id, key, options).then((request) => request(axios, basePath));
        },
        /**
         * Get an asset
         * @param {string} id unique ID
         * @param {string} key unique ID within the dossier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetsGet(id: string, key: string, options?: any): AxiosPromise<AssetsGet200Response> {
            return localVarFp.assetsGet(id, key, options).then((request) => request(axios, basePath));
        },
        /**
         * Add an asset to a wallet
         * @param {string} id unique ID
         * @param {Asset} asset 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetsSave(id: string, asset: Asset, options?: any): AxiosPromise<Asset> {
            return localVarFp.assetsSave(id, asset, options).then((request) => request(axios, basePath));
        },
        /**
         * Update an asset
         * @param {string} id unique ID
         * @param {string} key unique ID within the dossier
         * @param {Asset} asset 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetsUpdate(id: string, key: string, asset: Asset, options?: any): AxiosPromise<Asset> {
            return localVarFp.assetsUpdate(id, key, asset, options).then((request) => request(axios, basePath));
        },
        /**
         * Compare the values from a Baloise CSV file with the stored asset values.
         * @param {string} [separator] The separator used to separate the columns in the CSV file.
         * @param {File} [filename] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchUpdateBaloiseSync(separator?: string, filename?: File, options?: any): AxiosPromise<Array<SyncStatus>> {
            return localVarFp.batchUpdateBaloiseSync(separator, filename, options).then((request) => request(axios, basePath));
        },
        /**
         * Update asset values in batch
         * @param {Array<BatchUpdateUpdateAssetValuesRequestInner>} batchUpdateUpdateAssetValuesRequestInner 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchUpdateUpdateAssetValues(batchUpdateUpdateAssetValuesRequestInner: Array<BatchUpdateUpdateAssetValuesRequestInner>, options?: any): AxiosPromise<object> {
            return localVarFp.batchUpdateUpdateAssetValues(batchUpdateUpdateAssetValuesRequestInner, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a dossier
         * @param {string} id unique ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dossierInfosDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.dossierInfosDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Get the dossier\'s info.
         * @param {string} id unique ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dossierInfosGet(id: string, options?: any): AxiosPromise<DossierInfosGet200Response> {
            return localVarFp.dossierInfosGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Update the dossier\'s info. Only the provided properties will be updated.
         * @param {string} id unique ID
         * @param {DossierInfo} dossierInfo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dossierInfosUpdate(id: string, dossierInfo: DossierInfo, options?: any): AxiosPromise<DossierInfo> {
            return localVarFp.dossierInfosUpdate(id, dossierInfo, options).then((request) => request(axios, basePath));
        },
        /**
         * Get a dossier
         * @param {string} id unique ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dossiersGet(id: string, options?: any): AxiosPromise<DossiersGet200Response> {
            return localVarFp.dossiersGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * List summaries of all dossiers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dossiersList(options?: any): AxiosPromise<Array<DossiersList200ResponseInner>> {
            return localVarFp.dossiersList(options).then((request) => request(axios, basePath));
        },
        /**
         * Create a new dossier
         * @param {Dossier} dossier 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dossiersSave(dossier: Dossier, options?: any): AxiosPromise<Dossier> {
            return localVarFp.dossiersSave(dossier, options).then((request) => request(axios, basePath));
        },
        /**
         * Get information about the application
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        info(options?: any): AxiosPromise<object> {
            return localVarFp.info(options).then((request) => request(axios, basePath));
        },
        /**
         * Delete an institution
         * @param {string} id unique ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        institutionsDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.institutionsDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Get an institution
         * @param {string} id unique ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        institutionsGet(id: string, options?: any): AxiosPromise<InstitutionsList200ResponseInner> {
            return localVarFp.institutionsGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * List all institutions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        institutionsList(options?: any): AxiosPromise<Array<InstitutionsList200ResponseInner>> {
            return localVarFp.institutionsList(options).then((request) => request(axios, basePath));
        },
        /**
         * Create a new institution
         * @param {Institution} institution 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        institutionsSave(institution: Institution, options?: any): AxiosPromise<Institution> {
            return localVarFp.institutionsSave(institution, options).then((request) => request(axios, basePath));
        },
        /**
         * Update an institution
         * @param {string} id unique ID
         * @param {Institution} institution 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        institutionsUpdate(id: string, institution: Institution, options?: any): AxiosPromise<Institution> {
            return localVarFp.institutionsUpdate(id, institution, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a product
         * @param {string} id unique ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.productsDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Get a product
         * @param {string} id unique ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsGet(id: string, options?: any): AxiosPromise<ProductsList200ResponseInner> {
            return localVarFp.productsGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * List all products
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsList(options?: any): AxiosPromise<Array<ProductsList200ResponseInner>> {
            return localVarFp.productsList(options).then((request) => request(axios, basePath));
        },
        /**
         * Create a new product
         * @param {Product} product 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsSave(product: Product, options?: any): AxiosPromise<Product> {
            return localVarFp.productsSave(product, options).then((request) => request(axios, basePath));
        },
        /**
         * Update a product
         * @param {string} id unique ID
         * @param {Product} product 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsUpdate(id: string, product: Product, options?: any): AxiosPromise<Product> {
            return localVarFp.productsUpdate(id, product, options).then((request) => request(axios, basePath));
        },
        /**
         * Generate a new excel report.
         * @param {string} [date] The valuation date.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportingGenerate(date?: string, options?: any): AxiosPromise<void> {
            return localVarFp.reportingGenerate(date, options).then((request) => request(axios, basePath));
        },
        /**
         * List all available excel reports.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportingList(options?: any): AxiosPromise<Array<Report>> {
            return localVarFp.reportingList(options).then((request) => request(axios, basePath));
        },
        /**
         * Grant the user access to a dossier.
         * @param {string} username unique username
         * @param {string} dossierId dossier ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersAddDossier(username: string, dossierId: string, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.usersAddDossier(username, dossierId, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a user
         * @param {string} username unique username
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersDelete(username: string, options?: any): AxiosPromise<void> {
            return localVarFp.usersDelete(username, options).then((request) => request(axios, basePath));
        },
        /**
         * Get user details
         * @param {string} username unique username
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersGet(username: string, options?: any): AxiosPromise<UsersListDossierUsers200ResponseInner> {
            return localVarFp.usersGet(username, options).then((request) => request(axios, basePath));
        },
        /**
         * List users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersList(options?: any): AxiosPromise<Array<UsersList200ResponseInner>> {
            return localVarFp.usersList(options).then((request) => request(axios, basePath));
        },
        /**
         * List the users that have access to this dossier.
         * @param {string} id unique ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersListDossierUsers(id: string, options?: any): AxiosPromise<Array<UsersListDossierUsers200ResponseInner>> {
            return localVarFp.usersListDossierUsers(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Revoke the user\'s access to a dossier.
         * @param {string} username unique username
         * @param {string} dossierId dossier ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersRemoveDossier(username: string, dossierId: string, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.usersRemoveDossier(username, dossierId, options).then((request) => request(axios, basePath));
        },
        /**
         * Create a new user
         * @param {UsersListDossierUsers200ResponseInner} usersListDossierUsers200ResponseInner 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersSave(usersListDossierUsers200ResponseInner: UsersListDossierUsers200ResponseInner, options?: any): AxiosPromise<User> {
            return localVarFp.usersSave(usersListDossierUsers200ResponseInner, options).then((request) => request(axios, basePath));
        },
        /**
         * Set the user\'s email address.
         * @param {string} username unique username
         * @param {UsersSetEmailRequest} usersSetEmailRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersSetEmail(username: string, usersSetEmailRequest: UsersSetEmailRequest, options?: any): AxiosPromise<UsersSetEmail200Response> {
            return localVarFp.usersSetEmail(username, usersSetEmailRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Update user details
         * @param {string} username unique username
         * @param {User} user 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersUpdate(username: string, user: User, options?: any): AxiosPromise<User> {
            return localVarFp.usersUpdate(username, user, options).then((request) => request(axios, basePath));
        },
        /**
         * Verify the user\'s email address with the provided token.
         * @param {string} token The verification token.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersVerifyEmail(token: string, options?: any): AxiosPromise<void> {
            return localVarFp.usersVerifyEmail(token, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a wallet
         * @param {string} id unique ID
         * @param {string} key unique ID within the dossier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        walletsDelete(id: string, key: string, options?: any): AxiosPromise<void> {
            return localVarFp.walletsDelete(id, key, options).then((request) => request(axios, basePath));
        },
        /**
         * Get a wallet.
         * @param {string} id unique ID
         * @param {string} key unique ID within the dossier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        walletsGet(id: string, key: string, options?: any): AxiosPromise<WalletsGet200Response> {
            return localVarFp.walletsGet(id, key, options).then((request) => request(axios, basePath));
        },
        /**
         * Add a wallet to a dossier.
         * @param {string} id unique ID
         * @param {Wallet} wallet 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        walletsSave(id: string, wallet: Wallet, options?: any): AxiosPromise<Wallet> {
            return localVarFp.walletsSave(id, wallet, options).then((request) => request(axios, basePath));
        },
        /**
         * Update a wallet
         * @param {string} id unique ID
         * @param {string} key unique ID within the dossier
         * @param {Wallet} wallet 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        walletsUpdate(id: string, key: string, wallet: Wallet, options?: any): AxiosPromise<Wallet> {
            return localVarFp.walletsUpdate(id, key, wallet, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * Delete an asset manager
     * @param {string} id unique ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public assetManagersDelete(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).assetManagersDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get an assetManager
     * @param {string} id unique ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public assetManagersGet(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).assetManagersGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List all asset managers
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public assetManagersList(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).assetManagersList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a new assetManager
     * @param {AssetManager} assetManager 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public assetManagersSave(assetManager: AssetManager, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).assetManagersSave(assetManager, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update an assetManager
     * @param {string} id unique ID
     * @param {AssetManager} assetManager 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public assetManagersUpdate(id: string, assetManager: AssetManager, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).assetManagersUpdate(id, assetManager, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete an asset
     * @param {string} id unique ID
     * @param {string} key unique ID within the dossier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public assetsDelete(id: string, key: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).assetsDelete(id, key, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get an asset
     * @param {string} id unique ID
     * @param {string} key unique ID within the dossier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public assetsGet(id: string, key: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).assetsGet(id, key, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Add an asset to a wallet
     * @param {string} id unique ID
     * @param {Asset} asset 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public assetsSave(id: string, asset: Asset, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).assetsSave(id, asset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update an asset
     * @param {string} id unique ID
     * @param {string} key unique ID within the dossier
     * @param {Asset} asset 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public assetsUpdate(id: string, key: string, asset: Asset, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).assetsUpdate(id, key, asset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Compare the values from a Baloise CSV file with the stored asset values.
     * @param {string} [separator] The separator used to separate the columns in the CSV file.
     * @param {File} [filename] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public batchUpdateBaloiseSync(separator?: string, filename?: File, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).batchUpdateBaloiseSync(separator, filename, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update asset values in batch
     * @param {Array<BatchUpdateUpdateAssetValuesRequestInner>} batchUpdateUpdateAssetValuesRequestInner 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public batchUpdateUpdateAssetValues(batchUpdateUpdateAssetValuesRequestInner: Array<BatchUpdateUpdateAssetValuesRequestInner>, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).batchUpdateUpdateAssetValues(batchUpdateUpdateAssetValuesRequestInner, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a dossier
     * @param {string} id unique ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public dossierInfosDelete(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).dossierInfosDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get the dossier\'s info.
     * @param {string} id unique ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public dossierInfosGet(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).dossierInfosGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update the dossier\'s info. Only the provided properties will be updated.
     * @param {string} id unique ID
     * @param {DossierInfo} dossierInfo 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public dossierInfosUpdate(id: string, dossierInfo: DossierInfo, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).dossierInfosUpdate(id, dossierInfo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get a dossier
     * @param {string} id unique ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public dossiersGet(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).dossiersGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List summaries of all dossiers
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public dossiersList(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).dossiersList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a new dossier
     * @param {Dossier} dossier 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public dossiersSave(dossier: Dossier, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).dossiersSave(dossier, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get information about the application
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public info(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).info(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete an institution
     * @param {string} id unique ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public institutionsDelete(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).institutionsDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get an institution
     * @param {string} id unique ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public institutionsGet(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).institutionsGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List all institutions
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public institutionsList(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).institutionsList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a new institution
     * @param {Institution} institution 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public institutionsSave(institution: Institution, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).institutionsSave(institution, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update an institution
     * @param {string} id unique ID
     * @param {Institution} institution 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public institutionsUpdate(id: string, institution: Institution, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).institutionsUpdate(id, institution, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a product
     * @param {string} id unique ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public productsDelete(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).productsDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get a product
     * @param {string} id unique ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public productsGet(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).productsGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List all products
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public productsList(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).productsList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a new product
     * @param {Product} product 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public productsSave(product: Product, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).productsSave(product, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update a product
     * @param {string} id unique ID
     * @param {Product} product 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public productsUpdate(id: string, product: Product, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).productsUpdate(id, product, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Generate a new excel report.
     * @param {string} [date] The valuation date.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public reportingGenerate(date?: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).reportingGenerate(date, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List all available excel reports.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public reportingList(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).reportingList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Grant the user access to a dossier.
     * @param {string} username unique username
     * @param {string} dossierId dossier ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public usersAddDossier(username: string, dossierId: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).usersAddDossier(username, dossierId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a user
     * @param {string} username unique username
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public usersDelete(username: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).usersDelete(username, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get user details
     * @param {string} username unique username
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public usersGet(username: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).usersGet(username, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List users
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public usersList(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).usersList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List the users that have access to this dossier.
     * @param {string} id unique ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public usersListDossierUsers(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).usersListDossierUsers(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Revoke the user\'s access to a dossier.
     * @param {string} username unique username
     * @param {string} dossierId dossier ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public usersRemoveDossier(username: string, dossierId: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).usersRemoveDossier(username, dossierId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a new user
     * @param {UsersListDossierUsers200ResponseInner} usersListDossierUsers200ResponseInner 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public usersSave(usersListDossierUsers200ResponseInner: UsersListDossierUsers200ResponseInner, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).usersSave(usersListDossierUsers200ResponseInner, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Set the user\'s email address.
     * @param {string} username unique username
     * @param {UsersSetEmailRequest} usersSetEmailRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public usersSetEmail(username: string, usersSetEmailRequest: UsersSetEmailRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).usersSetEmail(username, usersSetEmailRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update user details
     * @param {string} username unique username
     * @param {User} user 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public usersUpdate(username: string, user: User, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).usersUpdate(username, user, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Verify the user\'s email address with the provided token.
     * @param {string} token The verification token.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public usersVerifyEmail(token: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).usersVerifyEmail(token, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a wallet
     * @param {string} id unique ID
     * @param {string} key unique ID within the dossier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public walletsDelete(id: string, key: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).walletsDelete(id, key, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get a wallet.
     * @param {string} id unique ID
     * @param {string} key unique ID within the dossier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public walletsGet(id: string, key: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).walletsGet(id, key, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Add a wallet to a dossier.
     * @param {string} id unique ID
     * @param {Wallet} wallet 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public walletsSave(id: string, wallet: Wallet, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).walletsSave(id, wallet, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update a wallet
     * @param {string} id unique ID
     * @param {string} key unique ID within the dossier
     * @param {Wallet} wallet 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public walletsUpdate(id: string, key: string, wallet: Wallet, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).walletsUpdate(id, key, wallet, options).then((request) => request(this.axios, this.basePath));
    }
}



