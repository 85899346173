import { translate as t } from '../../services/translation'
import { numberFmt, percentageFmt, totalFmt } from '../../util/formatters'
import { BsCheck2Circle, BsExclamationTriangle } from 'react-icons/bs'
import { getRiskAnalysis } from '../../business-logic/risk-analysis'

export const RiskProfile = ({ dossier, wallets, date }) => {
  const { riskProfiles, risky, weightedRiskProfile } = getRiskAnalysis(dossier, wallets, date)
  const description = wallets.map((w) => w.name).join(' / ')

  return (
    <>
      {wallets.length > 0 && (
        <div className="page-break-after">
          <div className="risk-heading">
            <h2>
              {t('dossier.profile')}: <span className="text-info">{t(dossier!.profile)}</span>
            </h2>
            <h2>
              {t('dossier.riskAnalysis')} <small>{description}</small>
            </h2>
          </div>
          <div className="container risk-profile">
            <div className="row">
              <div className="col-sm-1"></div>
              <div className="col-sm-5">
                <h3 style={{ paddingLeft: 30 }}>{t('dossier.score')}</h3>
              </div>
              <div className="col-sm-5">
                <h3 style={{ paddingLeft: 30 }}>{t('dossier.spread')}</h3>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-1"></div>
              <div className="col-sm-5">
                <h6 style={{ paddingLeft: 40 }}>{t('dossier.highRisk')}</h6>
              </div>
              <div className="col-sm-5">
                <h6 style={{ paddingLeft: 40 }}>{t('dossier.highRisk')}</h6>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-1"></div>
              <div className="col-sm-5 risk-score">
                <div className="float-start labels">
                  {riskProfiles.map((riskProfile) => (
                    <p key={riskProfile.risk} style={{ paddingTop: 55, height: 70 }}>
                      {riskProfile.risk}
                    </p>
                  ))}
                </div>
                <div className="float-start">
                  {riskProfiles.map((riskProfile) => (
                    <div key={riskProfile.risk}>
                      <div
                        className="risk-block"
                        style={{ paddingTop: 30, backgroundColor: riskProfile.color }}
                      >
                        <p className="small">{totalFmt(riskProfile.total)}</p>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="marker">
                  <div className="offset" style={{ top: 404 - 70 * (weightedRiskProfile - 2) }}>
                    <p>
                      {t('dossier.yourScore')}: <strong>{numberFmt(weightedRiskProfile, 1)}</strong>
                    </p>
                    <div className="indicator">
                      {risky ? (
                        <BsExclamationTriangle className="text-danger" />
                      ) : (
                        <BsCheck2Circle className="text-success" />
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-4 risk-distribution">
                <div className="float-start labels">
                  {riskProfiles.map(
                    (riskProfile) =>
                      riskProfile.percentage > 0 && (
                        <p
                          key={riskProfile.risk}
                          style={{ height: riskProfile.height, paddingTop: riskProfile.padding }}
                        >
                          {riskProfile.height > 13 && <span>{riskProfile.risk}</span>}
                        </p>
                      )
                  )}
                </div>
                <div className="float-start">
                  {riskProfiles.map((riskProfile) => (
                    <div key={riskProfile.risk}>
                      {riskProfile.percentage > 0 && (
                        <div
                          className="risk-block"
                          style={{ backgroundColor: riskProfile.color, height: riskProfile.height }}
                        >
                          {riskProfile.height > 13 && (
                            <p className="small" style={{ paddingTop: riskProfile.padding }}>
                              {percentageFmt(riskProfile.percentage)}
                            </p>
                          )}
                        </div>
                      )}
                    </div>
                  ))}
                </div>
                <div className="float-start labels">
                  {riskProfiles.map((riskProfile) => (
                    <div key={riskProfile.risk}>
                      {riskProfile.percentage > 0 && riskProfile.risky && (
                        <p style={{ height: riskProfile.height, paddingTop: riskProfile.padding }}>
                          <BsExclamationTriangle className="text-danger" />
                        </p>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-1"></div>
              <div className="col-sm-5">
                <h6 style={{ paddingLeft: 40, paddingBottom: 0, paddingTop: 5, marginBottom: 0 }}>
                  {t('dossier.lowRisk')}
                </h6>
              </div>
              <div className="col-sm-5">
                <h6 style={{ paddingLeft: 40, paddingBottom: 0, paddingTop: 5, marginBottom: 0 }}>
                  {t('dossier.lowRisk')}
                </h6>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
