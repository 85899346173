import { Navigate, Outlet, RouteObject, useRouteError } from 'react-router-dom'
import { MainLayout } from './components/layouts/main-layout'
import { routes } from './pages'
import { toast } from 'react-toastify'
import { translate as t } from './services/translation'

const ErrorBoundary = () => {
  let error = useRouteError()
  console.error(error)
  // @ts-ignore
  if (error.status === 404) {
    toast.error(t('notFound.error'))
  } else {
    toast.error(t('unknown.error'))
  }
  return <Navigate to="/" />
}

const appRoutes: RouteObject[] = [
  {
    path: '/',
    element: (
      <MainLayout>
        <Outlet />
      </MainLayout>
    ),
    children: [...routes, { path: 'index.html', element: <Navigate to="/" /> }],
    errorElement: <ErrorBoundary />,
  },
]

export default appRoutes
