import { fetchUserAttributes } from 'aws-amplify/auth'
import { Hub, I18n } from 'aws-amplify/utils'
import { createContext, useEffect, useState } from 'react'
import vocabularies from '../../generated/vocabularies.json'
import cognitoNlTranslations from '../../services/cognito-nl.json'

I18n.putVocabularies({
  en: vocabularies.en,
  nl: { ...cognitoNlTranslations, ...vocabularies.nl },
})

const browserLanguage = navigator.language.split(/[-_]/)[0] === 'en' ? 'en' : 'nl'
I18n.setLanguage(browserLanguage)

const LanguageContext = createContext<string>(browserLanguage)

export const LanguageProvider = ({ children }) => {
  const [lang, setLang] = useState(browserLanguage)
  useEffect(() => {
    fetchUserAttributes()
      .then((attributes) => {
        const userLanguage = attributes.locale === 'en' ? 'en' : 'nl'
        if (userLanguage !== lang) {
          I18n.setLanguage(userLanguage)
          setLang(userLanguage)
        }
      })
      .catch(() => {})
  }, [lang])

  Hub.listen('auth', async (data) => {
    if (data.payload.event === 'signedIn') {
      const attributes = await fetchUserAttributes()
      const userLanguage = attributes?.locale === 'en' ? 'en' : 'nl'
      if (userLanguage !== lang) {
        I18n.setLanguage(userLanguage)
        setLang(userLanguage)
      }
    }
  })

  return <LanguageContext.Provider value={lang}>{children}</LanguageContext.Provider>
}
