import { redirect, RouteObject } from 'react-router-dom'
import { Dossiers } from './dossiers'
import { Dossier } from './dossier'
import { Report } from './report'
import { Returns } from './returns'
import { RiskProfileTab } from './risk-profile-tab'
import { Liquidity } from './liquidity'
import { Evolution } from './evolution'
import { Info } from './info'
import { WalletTab } from './wallet-tab'
import { Print } from './print'
import { DossierEdit } from './dossier-edit'
import { routes as assetRoutes } from '../asset'
import { WalletEdit } from './wallet-edit'
import { WalletMoveAssets } from './wallet-move-assets'
import { WalletSortAssets } from './wallet-sort-assets'
import { DeleteDialog } from '../../components/form/delete-dialog'
import { api } from '../../services/api'
import { queryClient } from '../../queries/react-query-client'
import { dossierQueryKeys } from '../../queries/dossier-queries'

export const routes: RouteObject[] = [
  {
    path: 'dossiers',
    children: [
      { index: true, element: <Dossiers /> },
      { path: 'new', element: <DossierEdit /> },
      {
        path: ':id',
        element: <Dossier />,
        children: [
          {
            index: true,
            element: <Report />,
          },
          { path: 'report', element: <Report /> },
          { path: 'risk-profile', element: <RiskProfileTab /> },
          { path: 'liquidity', element: <Liquidity /> },
          { path: 'evolution', element: <Evolution /> },
          { path: 'returns', element: <Returns /> },
          { path: 'info', element: <Info /> },
          { path: 'edit', element: <DossierEdit /> },
          {
            path: 'delete',
            element: (
              <>
                <Info />
                <DeleteDialog action="archive" entity="dossier" initialShow={true} />
              </>
            ),
            action: async ({ params }) => {
              await api.dossierInfosDelete(params.id!)
              await queryClient.invalidateQueries(dossierQueryKeys.list)
              return redirect('/dossiers')
            },
          },
          {
            path: 'wallets',
            children: [
              {
                path: ':walletKey',
                children: [
                  {
                    index: true,
                    element: <WalletTab />,
                  },
                  { path: 'edit', element: <WalletEdit /> },
                  { path: 'move-assets', element: <WalletMoveAssets /> },
                  { path: 'sort-assets', element: <WalletSortAssets /> },
                ],
              },
            ],
          },
          { path: 'print', element: <Print /> },
          ...assetRoutes,
        ],
      },
      {
        path: 'my-dossier',
        loader: async ({ request, params }) => {
          const response = await api.dossiersList()
          const dossierId = response.data?.length === 1 ? response.data[0].id : ''
          return redirect(`/dossiers/${dossierId}`)
        },
      },
    ],
  },
]
