import { Amplify } from 'aws-amplify'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { QueryClientProvider } from 'react-query'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import './app.scss'
import { AuthenticatorProvider } from './components/security/authenticator-context'
import { LanguageProvider } from './components/translation/language-provider'
import { AMPLIFY_CONFIG } from './config'
import { queryClient } from './queries/react-query-client'
import routes from './routes'
import { api } from './services/api'

Amplify.configure(AMPLIFY_CONFIG)

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
const router = createBrowserRouter(routes)

api.info().catch(console.error)
setInterval(() => api.info().catch(console.error), 60_000)

root.render(
  <React.StrictMode>
    <ToastContainer autoClose={10_000} />
    <QueryClientProvider client={queryClient}>
      <LanguageProvider>
        <AuthenticatorProvider>
          <RouterProvider router={router} />
        </AuthenticatorProvider>
      </LanguageProvider>
    </QueryClientProvider>
  </React.StrictMode>
)
