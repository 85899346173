import { Asset, AssetTypeEnum } from '../../generated'
import { useDossierQuery } from '../../queries/dossier-queries'
import { useParams } from 'react-router-dom'
import { translate as t } from '../../services/translation'
import { calculateAnnualInterestRate } from '../../business-logic/interest-rate'
import { diff, MILLIS_IN_YEAR, todayIso } from '../../util/date-util'
import { dateFmt, moneyFmt, percentageFmt } from '../../util/formatters'
import { getAssetValue, getNearestValidFrom } from '../../business-logic/asset'
import { Form, Nav, Table } from 'react-bootstrap'
import { AssetLink } from '../../components/asset/asset-modal'
import { ProductLink } from '../../components/links'
import { useState } from 'react'
import { BsEye, BsEyeSlash } from 'react-icons/bs'
import { api } from '../../services/api'
import { debounce } from 'lodash'

function getWalletReturns(wallets) {
  return wallets.map((wallet) => {
    const assetsWithReturns = (wallet.assets || []).filter(
      (a) =>
        a.type !== AssetTypeEnum.Vastgoed &&
        a.type !== AssetTypeEnum.Krediet &&
        (a.product?.riskProfile ?? 0) > 0 &&
        a.values &&
        a.values.length > 1 &&
        diff(a.values[0].validFrom, a.values.at(-1)!.validFrom) >= MILLIS_IN_YEAR
    )
    return {
      wallet,
      assetReturns: getReturns(assetsWithReturns, todayIso()),
    }
  })
}

function getReturns(assets: Asset[], toDate: string) {
  if (!assets) {
    return undefined
  }
  return assets.map((asset) => {
    const currentValue = getAssetValue(asset, toDate)
    const endDate = currentValue?.validFrom ?? toDate
    const min3Y = getNearestValidFrom(asset, -3)
    const min5Y = getNearestValidFrom(asset, -5)
    const min10Y = getNearestValidFrom(asset, -10)
    return {
      asset,
      productDescription: `${asset.product.name} ${asset.product.reference ? '-' : ''}  ${
        asset.product.reference || ''
      }`,
      value: currentValue,
      min3Y,
      y3: min3Y ? calculateAnnualInterestRate(asset, endDate, min3Y) : undefined,
      min5Y,
      y5: min5Y ? calculateAnnualInterestRate(asset, endDate, min5Y) : undefined,
      min10Y,
      y10: min10Y ? calculateAnnualInterestRate(asset, endDate, min10Y) : undefined,
      life: calculateAnnualInterestRate(asset, endDate),
    }
  })
}

export function Returns() {
  const { id } = useParams()
  const { data } = useDossierQuery(id, { refetchOnMount: false })
  const [showAll, setShowAll] = useState(false)
  const [walletReturns, setWalletReturns] = useState(getWalletReturns(data!.wallets))

  async function toggleHidden(asset: Asset) {
    asset.hideReturns = !asset.hideReturns
    const response = await api.assetsUpdate(asset.id!, asset.key!, asset)
    Object.assign(asset, response.data)
    setWalletReturns(getWalletReturns(data!.wallets))
  }

  return (
    <>
      <h2>{t('dossier.returns')}</h2>
      <Table responsive>
        <thead>
          <tr>
            <th>
              {t('asset.reference')} / {t('asset.type')} / {t('product')}
            </th>
            <th>
              {t('from')} - {t('to')}
            </th>
            <th className="text-end">{t('amount')}</th>
            <th className="text-end ">{t('min3Y')}</th>
            <th className="text-end">{t('min5Y')}</th>
            <th className="text-end">{t('min10Y')}</th>
            <th className="text-end">{t('sinceStart')}</th>
          </tr>
        </thead>
        {walletReturns
          .filter((wr) => wr.assetReturns?.length)
          .map((walletReturn) => (
            <tbody className="group" key={walletReturn.wallet.key}>
              <tr className="info">
                <th colSpan={7}>{walletReturn.wallet.name}</th>
              </tr>
              {walletReturn.assetReturns!.map(
                (returns, index) =>
                  ((!returns.asset.hideReturns && returns.value.value) || showAll) && (
                    <tr className="asset" key={index}>
                      <td>
                        {showAll && (
                          <span
                            role="button"
                            onClick={debounce(() => {
                              toggleHidden(returns.asset).catch(console.error)
                            }, 750)}
                          >
                            {returns.asset.hideReturns ? <BsEyeSlash /> : <BsEye />}
                          </span>
                        )}
                        <AssetLink wallet={walletReturn.wallet} asset={returns.asset} /> /{' '}
                        <ProductLink entity={returns.asset.product} />
                      </td>
                      <td>
                        {dateFmt(returns.asset.values?.at(-1)!.validFrom)} -{' '}
                        {dateFmt(returns.value?.validFrom)}
                      </td>
                      <td className="text-end">{moneyFmt(returns.value)}</td>
                      <td className="text-end">{returns.y3 ? percentageFmt(returns.y3) : '-'}</td>
                      <td className="text-end">{returns.y5 ? percentageFmt(returns.y5) : '-'}</td>
                      <td className="text-end">{returns.y5 ? percentageFmt(returns.y10) : '-'}</td>
                      <td className="text-end">{percentageFmt(returns.life)}</td>
                    </tr>
                  )
              )}
            </tbody>
          ))}
      </Table>
      <Nav>
        <Nav.Item>
          <Form.Check
            className="pt-2 text-primary"
            type="switch"
            onChange={(e) => setShowAll(e.target.checked)}
            label={t('show.all')}
          />
        </Nav.Item>
      </Nav>
    </>
  )
}
