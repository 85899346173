import clsx from 'clsx'
import { ButtonGroup } from 'react-bootstrap'
import { BsEye, BsPen, BsTrash } from 'react-icons/bs'
import { Link } from 'react-router-dom'

export type CrudTableRowActionsProps = {
  row: {
    id?: string
    archived?: boolean
  }
}

export const CrudTableRowActions = ({ row }: CrudTableRowActionsProps) => {
  if (!row.id) {
    return null
  }
  return (
    <div className="d-flex">
      <ButtonGroup className="ms-auto">
        <Link className="btn btn-info icon-wrapper" to={`${row.id}`}>
          <BsEye />
        </Link>
        <Link className="btn btn-primary icon-wrapper" to={`${row.id}/edit`}>
          <BsPen />
        </Link>
        <Link
          className={clsx('btn', 'btn-danger', 'icon-wrapper', {
            disabled: !!row.archived,
          })}
          to={`${row.id}/delete`}
        >
          <BsTrash />
        </Link>
      </ButtonGroup>
    </div>
  )
}
