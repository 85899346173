import { FormField } from '../form'
import { translate as t } from '../../services/translation'
import { Card } from 'react-bootstrap'
import { Periodicity } from '../../generated'

export const BCPSContractForm = (_) => {
  return (
    <>
      <FormField
        name="contractState"
        inputProps={{
          type: 'select',
          options: ['', 'actief', 'premievrij'],
        }}
      />
      <Card>
        <Card.Body>
          <Card.Title>{t('asset.pensionStructure')}</Card.Title>
          <FormField
            name="pensionAnnualPremium"
            inputProps={{ type: 'number', min: 0, step: 0.01 }}
            registerOptions={{ min: 0, valueAsNumber: true }}
          />
          <FormField
            name="pensionPeriodicity"
            inputProps={{
              type: 'select',
              options: ['', ...Object.values(Periodicity)].map((p) => ({ label: t(p), value: p })),
            }}
          />
          <FormField
            name="pensionEntryCost"
            inputProps={{ type: 'number', min: 0, step: 0.01 }}
            registerOptions={{ min: 0, valueAsNumber: true }}
          />
          <FormField
            name="pensionIndexation"
            inputProps={{ maxLength: 100 }}
            registerOptions={{ maxLength: 100 }}
          />
        </Card.Body>
      </Card>
      <FormField name="remarks" inputProps={{ as: 'textarea', rows: 5, maxLength: 512 }} />
    </>
  )
}
