import { Col, Form, Row } from 'react-bootstrap'
import { BsArchive, BsArrowLeft, BsPen, BsTrash } from 'react-icons/bs'
import { Link, useParams } from 'react-router-dom'
import { DetailField, Dl } from '../../../components/detail/detail-list'
import { CenteredContentLayout } from '../../../components/layouts/centered-content-layout'
import { useAssetManagerQuery } from '../../../queries/asset-manager-queries'
import { translate as t } from '../../../services/translation'
import { IfStaff } from '../../../components/security/authorisation'

export const AssetManagerDetail = () => {
  const { id } = useParams()
  // Queries
  const { data } = useAssetManagerQuery(id)

  if (id && !data) {
    return null
  }

  return (
    <CenteredContentLayout>
      <h1>{t('show', t('assetManager'))}</h1>
      <Dl translationPath="assetManager">
        <DetailField name="name">{data?.name}</DetailField>
        <DetailField name="url">{data?.url}</DetailField>
        <DetailField name="archived">
          {data?.archived && <BsArchive className="text-danger" />}
        </DetailField>
      </Dl>
      <Form.Group as={Row} className="mb-3">
        <Col>
          <IfStaff>
            <Link className="btn btn-primary me-2 icon-wrapper" to={`../${id}/edit`}>
              <BsPen />
              <span>{t('button.edit')}</span>
            </Link>
            <Link className="btn btn-danger me-2 icon-wrapper" to={`../${id}/delete`}>
              <BsTrash />
              <span>{t('button.delete')}</span>
            </Link>
            <Link className="btn btn-secondary icon-wrapper" to="..">
              <BsArrowLeft />
              <span>{t('button.back')}</span>
            </Link>
          </IfStaff>
        </Col>
      </Form.Group>
    </CenteredContentLayout>
  )
}
