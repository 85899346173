import { Link } from 'react-router-dom'
import { AssetAllOfProduct, Entity, Product } from '../generated'

interface EntityLinkProps {
  entity?: Entity
}

interface ProductLinkProps {
  entity?: Product | AssetAllOfProduct
}

export const AssetManagerLink = ({ entity }: EntityLinkProps) =>
  entity?.id ? <Link to={`/master-data/asset-managers/${entity?.id}`}>{entity?.name}</Link> : <></>

export const InstitutionLink = ({ entity }: EntityLinkProps) =>
  entity?.id ? <Link to={`/master-data/institutions/${entity?.id}`}>{entity?.name}</Link> : <></>

export const ProductLink = ({ entity }: EntityLinkProps) =>
  entity?.id ? <Link to={`/master-data/products/${entity?.id}`}>{entity?.name}</Link> : <></>

export const FullProductLink = ({ entity }: ProductLinkProps) =>
  entity?.id ? (
    <Link to={`/master-data/products/${entity?.id}`}>
      {entity.name} {entity.reference ? '-' : ''} {entity.reference}
    </Link>
  ) : (
    <></>
  )
