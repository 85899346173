import { useAuthenticator } from '@aws-amplify/ui-react'
import '@aws-amplify/ui-react/styles.css'
import { Navigate, useLocation } from 'react-router-dom'
import { get } from '../../util/object-utils'
import { resetAutoSignOutTimer } from './auto-sign-out'
import { useAuthTokens } from './authenticator-context'

/**
 * Example inspired from https://ui.docs.amplify.aws/react/guides/auth-protected#try-it-out
 */
export const RequireAuth = ({ children }) => {
  const location = useLocation()
  const { route } = useAuthenticator((context) => [context.route])
  if (route !== 'authenticated') {
    return <Navigate to="/login" state={{ from: location }} replace />
  }
  resetAutoSignOutTimer()
  return children
}

function useUserGroups(): string[] | null {
  const tokens = useAuthTokens()
  if (!tokens) {
    return null
  }
  return get(tokens, 'idToken.payload.cognito:groups') ?? []
}

export function useIsAdmin() {
  return useUserGroups()?.includes('admin')
}

export function useIsStaff() {
  const groups = useUserGroups()
  return groups?.includes('staff') || groups?.includes('admin')
}

export const IfAdmin = ({ children }) => {
  const admin = useIsAdmin()
  if (admin) return children
  return null
}
export const IfStaff = ({ children }) => {
  const staff = useIsStaff()
  if (staff) return children
  return null
}

export const IfNotStaff = ({ children }) => {
  const groups = useUserGroups()
  if (groups && groups.length === 0) return children
  return null
}
